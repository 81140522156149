import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./CustomerComponentCSS/RoomListUI.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const RoomListUI = () => {
    const { t } = useTranslation();
    const [roomTypes, setRoomTypes] = useState([]); // Danh sách loại phòng từ API
    const [rooms, setRooms] = useState([]); // Danh sách phòng từ API
    const [filteredRooms, setFilteredRooms] = useState([]); // Danh sách phòng sau khi lọc
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [selectedRoomType, setSelectedRoomType] = useState(null); // Loại phòng được chọn

    const fetchRoomTypes = async () => {
        try {
            const response = await axios.get("https://spring-api.azurewebsites.net/api/roomType");
            if (response.status === 200 && response.data.status === 0) {
                setRoomTypes(response.data.data); // Cập nhật danh sách loại phòng
            } else {
                setError(t("failedFetchRoomTypes"));
            }
        } catch (error) {
            setError(t("errorFetchRoomTypes"));
        }
    };

    const fetchRooms = async () => {
        try {
            const response = await axios.get("https://spring-api.azurewebsites.net/api/rooms");
            if (response.status === 200 && response.data.status === 0) {
                setRooms(response.data.data);
                setFilteredRooms(response.data.data); // Hiển thị toàn bộ phòng ban đầu
            } else {
                setError(t("failedFetchRooms"));
            }
        } catch (error) {
            setError(t("errorFetchRooms"));
        } finally {
            setLoading(false);
        }
    };

    const fetchRoomsByType = async (roomTypeId) => {
        try {
            const response = await axios.get(`https://spring-api.azurewebsites.net/api/rooms/byRoomType/${roomTypeId}`);
            if (response.status === 200 && response.data.status === 0) {
                setFilteredRooms(response.data.data); // Cập nhật danh sách phòng sau khi lọc
            } else {
                setError(t("failedFetchRoomsByType"));
            }
        } catch (error) {
            setError(t("errorFetchRoomsByType"));
        }
    };

    const handleRoomTypeChange = (roomTypeId) => {
        setSelectedRoomType(roomTypeId); // Cập nhật loại phòng được chọn
        if (roomTypeId) {
            fetchRoomsByType(roomTypeId); // Lấy danh sách phòng theo loại phòng
        } else {
            setFilteredRooms(rooms); // Hiển thị toàn bộ phòng nếu không chọn loại phòng
        }
    };

    const navigate = useNavigate();

    const handleViewDetail = (roomID) => {
        navigate(`/room-detail/${roomID}`);
    };

    useEffect(() => {
        fetchRoomTypes(); // Lấy danh sách loại phòng
        fetchRooms(); // Lấy danh sách phòng
    }, []);

    if (loading) {
        return <p className="text-center mt-4">{t("loadingRooms")}</p>;
    }

    if (error) {
        return <p className="text-center text-danger mt-4">{error}</p>;
    }

    return (
        <div className="container my-4">
            <div className="row">
                {/* Sidebar */}
                <div className="col-md-3">
                    <div className={`p-3 border rounded ${styles.sidebar}`}>
                        <div className="mb-4">
                            <p className={styles["form-label"]}>{t("roomTypes")}</p>
                            <select
                                className="form-select"
                                onChange={(e) => handleRoomTypeChange(e.target.value)}
                                value={selectedRoomType || ""}
                            >
                                <option value="">{t("allRooms")}</option>
                                {roomTypes.map((type) => (
                                    <option key={type.roomTypeId} value={type.roomTypeId}>
                                        {type.typeName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                {/* Main content */}
                <div className="col-md-9">
                    <div className="row g-3">
                        {filteredRooms.map((room) => (
                            <div className="col-12" key={room.roomNumber}>
                                <div
                                    onClick={() => handleViewDetail(room.roomID)}
                                    className={`card p-3 shadow-sm position-relative ${styles.card}`}
                                >
                                    <div className="row">
                                        <div className="col-md-4">
                                            <img
                                                src="/Images/r1.jpg"
                                                className={`img-fluid ${styles["hotel-image"]}`}
                                                alt={`Room ${room.roomNumber}`}
                                            />
                                        </div>
                                        <div className="col-md-8">
                                            <h5 className={`card-title ${styles["card-title"]}`}>
                                                {t("room")}: {room.roomNumber}
                                            </h5>
                                            <p>{t("roomViews")}: {room.roomView}</p>
                                            <p>{t("descriptions")}: {room.description}</p>
                                            <span className="badge bg-secondary me-1">
                                                {t("floor")}: {room.floor}
                                            </span>
                                            <span
                                                className={`badge ${
                                                    room.roomStatusID === 3
                                                        ? "bg-danger"
                                                        : room.roomStatusID === 1
                                                            ? "bg-success"
                                                            : "bg-secondary"
                                                } text-white`}
                                            >
                                                {t("status")}:{" "}
                                                {room.roomStatusID === 3
                                                    ? t("booked")
                                                    : room.roomStatusID === 1
                                                        ? t("available")
                                                        : t("unknown")}
                                            </span>
                                            <div className={styles["card-price"]}>
                                                {room.originalPrice && (
                                                    <del>{room.originalPrice.toLocaleString()} VND</del>
                                                )}
                                                <br />
                                                <span className="text-danger fs-5">
                                                    {room.price.toLocaleString()} VND
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RoomListUI;
