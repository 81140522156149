import React, { useState } from "react";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";

const CreateRoomUI = () => {
    const [roomData, setRoomData] = useState({
        roomTypeID: 3,
        roomStatusID: 3,
        roomNumber: 912,
        price: 120.5,
        capacity: 2,
        description: "Room with a great view",
        floor: 1,
        roomView: "Sea view",
    });
    const [images, setImages] = useState([]);
    const [response, setResponse] = useState("");

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setRoomData({ ...roomData, [name]: value });
    };

    const handleFileChange = (e) => {
        setImages([...e.target.files]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        const roomJSON = JSON.stringify({
            roomID: null,
            ...roomData,
        });

        formData.append("room", new Blob([roomJSON], { type: "application/json" }));

        images.forEach((file) => {
            formData.append("images", file);
        });

        try {
            const apiResponse = await fetch(
                "https://spring-api.azurewebsites.net/api/rooms/createMultiple",
                {
                    method: "POST",
                    body: formData,
                    headers: {
                        Cookie:
                            "ARRAffinity=79e06db539acb57119e709978d2cf1da299e8341753d6f6345007fcab3f69bc5; ARRAffinitySameSite=79e06db539acb57119e709978d2cf1da299e8341753d6f6345007fcab3f69bc5",
                    },
                }
            );

            const result = await apiResponse.json();
            setResponse(JSON.stringify(result, null, 2));

            Swal.fire({
                title: "Room Created Successfully!",
                text: "Would you like to add another room?",
                icon: "success",
                showCancelButton: true,
                confirmButtonText: "Yes, add another",
                cancelButtonText: "No, go to room list",
            }).then((result) => {
                if (result.isConfirmed) {
                    setRoomData({
                        roomTypeID: 3,
                        roomStatusID: 3,
                        roomNumber: 0,
                        price: 0,
                        capacity: 0,
                        description: "",
                        floor: 1,
                        roomView: "",
                    });
                    setImages([]);
                } else {
                    window.location.href = "/room-list-offline";
                }
            });
        } catch (error) {
            Swal.fire("Error", "Something went wrong: " + error.message, "error");
        }
    };

    return (
        <div className="container mt-4">
            <br/><br/><br/><br/>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="roomTypeID" className="form-label">
                            Room Type (ID)
                        </label>
                        <input
                            type="number"
                            id="roomTypeID"
                            name="roomTypeID"
                            value={roomData.roomTypeID}
                            onChange={handleInputChange}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="roomStatusID" className="form-label">
                            Room Status (ID)
                        </label>
                        <input
                            type="number"
                            id="roomStatusID"
                            name="roomStatusID"
                            value={roomData.roomStatusID}
                            onChange={handleInputChange}
                            className="form-control"
                            required
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="roomNumber" className="form-label">
                            Room Number
                        </label>
                        <input
                            type="number"
                            id="roomNumber"
                            name="roomNumber"
                            value={roomData.roomNumber}
                            onChange={handleInputChange}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="price" className="form-label">
                            Price
                        </label>
                        <input
                            type="number"
                            step="0.01"
                            id="price"
                            name="price"
                            value={roomData.price}
                            onChange={handleInputChange}
                            className="form-control"
                            required
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="capacity" className="form-label">
                            Capacity
                        </label>
                        <input
                            type="number"
                            id="capacity"
                            name="capacity"
                            value={roomData.capacity}
                            onChange={handleInputChange}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="floor" className="form-label">
                            Floor
                        </label>
                        <input
                            type="number"
                            id="floor"
                            name="floor"
                            value={roomData.floor}
                            onChange={handleInputChange}
                            className="form-control"
                            required
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="roomView" className="form-label">
                            Room View
                        </label>
                        <input
                            type="text"
                            id="roomView"
                            name="roomView"
                            value={roomData.roomView}
                            onChange={handleInputChange}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="description" className="form-label">
                            Description
                        </label>
                        <input
                            type="text"
                            id="description"
                            name="description"
                            value={roomData.description}
                            onChange={handleInputChange}
                            className="form-control"
                            required
                        />
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="images" className="form-label">
                        Upload Images
                    </label>
                    <input
                        type="file"
                        id="images"
                        multiple
                        accept="image/*"
                        onChange={handleFileChange}
                        className="form-control"
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary w-100">
                    Submit
                </button>
            </form>

        </div>
    );
};

export default CreateRoomUI;
