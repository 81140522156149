import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, InputNumber, message } from 'antd';
import axios from 'axios';
import './AdminCSS/AddAmenityForm.module.css'; 

const AddAmenityForm = () => {
    const [isFree, setIsFree] = useState(false); // Trạng thái theo dõi checkbox Is Free
    const [form] = Form.useForm(); // Khởi tạo form từ Ant Design

    // Xử lý sự kiện gửi form
    const onFinish = async (values) => {
        try {
            const payload = {
                amenityName: values.amenityName,
                description: values.description,
                free: isFree,
                price: isFree ? 0 : values.price, 
                category: values.category || null, 
            };

            await axios.post('https://spring-api.azurewebsites.net/api/amenities', payload);
            message.success('Amenity added successfully!');

            form.resetFields(); // Reset form sau khi thành công
            setIsFree(false); // Đặt lại checkbox
        } catch (error) {
            message.error('Failed to add amenity.');
        }
    };

    // Xử lý khi thay đổi trạng thái Is Free
    const handleIsFreeChange = (e) => {
        const checked = e.target.checked;
        setIsFree(checked);
        if (checked) {
            form.setFieldsValue({ price: 0 });
        }
    };

    return (
        <div className="amenity-form-container">
            <h2 className="amenity-form-title">Add New Amenity</h2>
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Form.Item
                    label="Amenity Name"
                    name="amenityName"
                    rules={[{ required: true, message: 'Please input the amenity name!' }]}
                >
                    <Input placeholder="Enter amenity name" />
                </Form.Item>

                <Form.Item label="Description" name="description">
                    <Input.TextArea placeholder="Enter description" rows={4} />
                </Form.Item>

                <Form.Item name="isFree" valuePropName="checked">
                    <Checkbox onChange={handleIsFreeChange}>Is Free</Checkbox>
                </Form.Item>

                <Form.Item
                    label="Price"
                    name="price"
                    dependencies={['isFree']}
                    rules={[{ required: !isFree, message: 'Please input the price!' }]}
                >
                    <InputNumber
                        min={0}
                        disabled={isFree}
                        style={{ width: '100%' }}
                        placeholder="Enter price"
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="amenity-form-button">
                        Add Amenity
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default AddAmenityForm;
