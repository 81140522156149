import React, { useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { FaUserCircle } from 'react-icons/fa';
import './CustomerComponentCSS/HeaderUI.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import styles from "./CustomerComponentCSS/HeaderUI.module.css";
import { useTranslation } from 'react-i18next';

const Header = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const [hoveredLink, setHoveredLink] = useState(null);
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);

    // Lấy ngôn ngữ đã lưu từ localStorage
    const savedLanguage = localStorage.getItem('language') || 'en';
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setShowNavbar(!(currentScrollY > lastScrollY && currentScrollY > 100));
      setIsScrolled(currentScrollY > 50);
      lastScrollY = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLogout = () => {
    // Xóa tất cả các thông tin liên quan đến người dùng trong localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('customerId');
    localStorage.removeItem('language');
    localStorage.removeItem('fullName');
    localStorage.removeItem('email');
    localStorage.removeItem('role');
    localStorage.removeItem('avatarUrl');

    // Reset trạng thái đăng nhập
    setIsLoggedIn(false);

    // Điều hướng về trang chính
    navigate('/');
  };


  return (
    <header
      className={`${styles.headerUi} ${isScrolled ? styles.scrolled : ""} ${showNavbar ? styles.visible : styles.hidden}`}
    >
      {/* Logo Section */}
      <div className={styles.logo}>
        <Link to="/">
          <img src="/Logoo.png" alt="Logo" width="120%" />
        </Link>
      </div>

      {/* Navbar Section */}
      <Navbar expand="lg" className={styles.navbarCustom}>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className={styles.navbarNav}>
            {[
              { name: t("aboutUs"), path: "/aboutUs" },
              { name: t("accommodation"), path: "/accommodation" },
              { name: t("services"), path: "/services" },
              { name: t("blog"), path: "/blog" },
              { name: t("gallery"), path: "/gallery" },
              { name: t("contact_us"), path: "/contact" },
            ].map((link, index) => (
              <Nav.Link
                as={Link}
                to={link.path}
                key={index}
                className={`${styles.navLink} ${hoveredLink === index ? styles.hovered : ""}`}
                onMouseEnter={() => setHoveredLink(index)}
                onMouseLeave={() => setHoveredLink(null)}
                style={{ color: "white" }}
              >
                {link.name}
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>

        {/* Right Section */}
        <div className={styles.headerRight}>
          <Dropdown>
            <Dropdown.Toggle variant="light" id="dropdown-basic">
              {/* Hiển thị icon tương ứng với ngôn ngữ hiện tại */}
              {i18n.language === 'en' ? (
                <img
                  src="https://cdn-icons-png.flaticon.com/512/197/197484.png"
                  alt="English Flag"
                  width="25"
                  className="me-2"
                />
              ) : (
                <img
                  src="https://cdn-icons-png.flaticon.com/512/197/197473.png"
                  alt="Vietnamese Flag"
                  width="25"
                  className="me-2"
                />
              )}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => changeLanguage('en')}>
                <img
                  src="https://cdn-icons-png.flaticon.com/512/197/197484.png"
                  alt="English Flag"
                  width="25"
                  className="me-2"
                />
                English
              </Dropdown.Item>
              <Dropdown.Item onClick={() => changeLanguage('vi')}>
                <img
                  src="https://cdn-icons-png.flaticon.com/512/197/197473.png"
                  alt="Vietnamese Flag"
                  width="25"
                  className="me-2"
                />
                Tiếng Việt
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {!isLoggedIn ? (
            <button className={styles.loginBtn} onClick={() => navigate('/login')}>
              <FaUserCircle /> {t("login")}
            </button>
          ) : (
            <Dropdown>
              <Dropdown.Toggle variant="light">
                <FaUserCircle size={20} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/myProfile">{t("myProfile")}</Dropdown.Item>
                <Dropdown.Item as={Link} to="/history">{t("history")}</Dropdown.Item>
                <Dropdown.Item as={Link} to="/changePass">{t("Change Pass")}</Dropdown.Item>

                <Dropdown.Item onClick={handleLogout}>{t("logOut")}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </Navbar>
    </header>
  );
};

export default Header;
