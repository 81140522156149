import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ForgotPassword from './ForgotPassword_Insertemail';
import styles from './CustomerComponentCSS/login.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import {useTranslation} from "react-i18next";


function Login() {
    const [showPassword, setShowPassword] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [loginError, setLoginError] = useState('');
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };
    const { t, i18n } = useTranslation();


    const handleGoogleLogin = () => {
        window.location.href = 'https://spring-api.azurewebsites.net/oauth2/authorization/google';
    };

// Hàm xử lý callback sau khi đăng nhập Google thành công
    const handleGoogleCallback = async () => {
        try {
            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get("token"); // Lấy token từ URL callback

            if (token) {
                // Giải mã token để lấy thông tin
                const decodedToken = jwtDecode(token);
                const userId = decodedToken?.userId; // Sửa lại từ sub thành userId
                const userRole = decodedToken?.role; // Đúng với token
                const fullName = decodedToken?.fullName; // Đúng với token
                const email = decodedToken?.sub; // Email nằm trong trường sub
                const avatarUrl = urlParams.get("avatarUrl"); // Avatar từ URL, không phải token

                // Kiểm tra tất cả thông tin cần thiết trước khi lưu
                if (userId && fullName && email && userRole) {
                    // Lưu token và thông tin người dùng vào localStorage
                    localStorage.setItem("token", token);
                    localStorage.setItem("userId", userId);
                    localStorage.setItem("fullName", fullName);
                    localStorage.setItem("email", email);
                    localStorage.setItem("role", userRole);
                    localStorage.setItem("avatarUrl", avatarUrl || "default-avatar.png");

                    console.log("Storing in localStorage:", {
                        token,
                        userId,
                        fullName,
                        email,
                        userRole,
                        avatarUrl,
                    });

                    // Thông báo thành công
                    toast.success(`Welcome, ${fullName}!`);

                    // Điều hướng dựa trên vai trò
                    switch (userRole) {
                        case "admin":
                            navigate("/admin");
                            break;
                        case "employee":
                            navigate("/employee");
                            break;
                        case "customer":
                            navigate("/customer");
                            break;
                        default:
                            navigate("/unauthorized");
                            break;
                    }
                } else {
                    console.error("Missing required fields to store in localStorage");
                    toast.error("Invalid token data, cannot login.");
                }
            } else {
                toast.error("No token found in URL");
            }
        } catch (error) {
            console.error("Error handling Google callback:", error);
            toast.error("Google Login Failed. Please try again.");
        }
    };

// Sử dụng useEffect để xử lý callback khi component mount
    useEffect(() => {
        if (window.location.search.includes("token")) {
            handleGoogleCallback();
        }
    }, []);



    const validateForm = () => {
        let newErrors = {};
        if (username.trim() === '') {
            newErrors.username = 'Username is required.';
        } else if (username.length > 30) {
            newErrors.username = 'Username must not exceed 30 characters.';
        }
        if (password.trim() === '') {
            newErrors.password = 'Password is required.';
        } else if (password.length > 30) {
            newErrors.password = 'Password must not exceed 30 characters.';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoginError(''); // Reset lỗi đăng nhập trước đó

        if (validateForm()) {
            try {
                const response = await axios.post('https://spring-api.azurewebsites.net/auth/login', {
                    username,
                    password,
                });

                if (response.status === 200 && response.data.accessToken) {
                    const accessToken = response.data.accessToken;
                    const decodedToken = jwtDecode(accessToken);

                    const userId = decodedToken.sub; // Lấy userId từ token
                    const userRole = decodedToken?.au?.[0]; // Vai trò của người dùng

                    // Lưu token và vai trò vào localStorage
                    localStorage.setItem('token', accessToken);
                    localStorage.setItem('role', userRole);

                    // Gọi API để lấy customerId từ userId
                    try {
                        const customerResponse = await axios.get(`https://spring-api.azurewebsites.net/api/customers/${userId}`, {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        });

                        const customerId = customerResponse.data?.data?.id; // Lấy customerId từ phản hồi API
                        if (customerId) {
                            localStorage.setItem('customerId', customerId);
                            toast.success('Login successful!');
                            setTimeout(() => {
                                switch (userRole) {
                                    case 'admin':
                                    case 'employee':
                                    case 'customer'|| 'Customer':
                                        navigate('/'); // Điều hướng đến trang chính
                                        break;
                                    default:
                                        navigate('/unauthorized');
                                        break;
                                }
                            }, 1000);
                        } else {
                            toast.error("Customer ID not found. Please contact support.");
                            navigate('/login');
                        }
                    } catch (customerError) {
                        console.error("Error fetching customer ID:", customerError);
                        toast.error("Unable to retrieve customer information. Please try again.");
                    }
                } else {
                    toast.error('Invalid login credentials.');
                }
            } catch (error) {
                console.error('Error during login:', error.message);
                toast.error(error.message || 'An error occurred.');
            }
        }
    };



    useEffect(() => {
        const token = localStorage.getItem('token');
        const role = localStorage.getItem('role');

        if (token) {
            navigate('/');
        }
    }, [navigate]);


    return (
        <div className={styles.containerf}>
            <ToastContainer />
            <div className={`${styles['row']} justify-content-center ${styles['login-box']}`}>
                <div className={`${styles['col-lg-7']} ${styles['left-section']}`}>
                    <h2 className={styles.hotelTitle}>W E L C O M E&nbsp;&nbsp;T O <br /> R I V I E R A&nbsp;&nbsp;H O T E L</h2>
                    <div className={styles['login-form']}>
                        <h3>LOGIN FORM</h3>
                        {loginError && <p className={styles['error-message']} aria-live="polite">{loginError}</p>}
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3 position-relative">
                                <label htmlFor="username" className="form-label">Username</label>
                                <input
                                    type="text"
                                    className={`${styles['form-control']}`}
                                    id="username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    autoComplete="username"
                                />
                                {errors.username && <small className={styles['error-message']}>{errors.username}</small>}
                            </div>
                            <div className="mb-3 position-relative">
                                <label htmlFor="password" className="form-label">Password</label>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    className={`${styles['form-control']}`}
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    autoComplete="current-password"
                                />
                                {errors.password && <small className={styles['error-message']}>{errors.password}</small>}
                                <i
                                    className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} ${styles['toggle-password']}`}
                                    onClick={togglePasswordVisibility}
                                    style={{cursor: 'pointer', position: 'absolute', right: '10px', top: '40%'}}
                                    title={showPassword ? 'Hide password' : 'Show password'}
                                ></i>

                            </div>
                            <div className={`${styles['form-check']} mb-3`}>
                                <input type="checkbox" className="form-check-input" id="rememberMe"/>
                                <label style={{marginLeft: 10, marginTop: 3 }} className="form-check-label" htmlFor="rememberMe">Remember</label>
                            </div>
                            <button
                                type="submit"
                                className={styles.loginBtn}
                                style={{ backgroundColor: '#b29a89', color: 'white' }}
                                onMouseEnter={e => e.target.style.backgroundColor = 'tomato'}
                                onMouseLeave={e => e.target.style.backgroundColor = '#b29a89'}>
                                Login
                            </button>
                            <div className={styles['forgot-password']}>
                                <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                    Forgot password
                                </a>
                            </div>
                        </form>
                    </div>
                    <div className={styles['social-login']}>
                        <p>or</p>
                        < button onClick={handleGoogleLogin}
                                 className={`${styles['btn']} ${styles['btn-google']}`}>
                            <img src="/Images/google.png" alt="Google" />
                        </button>
                        <button className={`${styles['btn']} ${styles['btn-facebook']}`}>
                            <img src="/Images/facebook.png" alt="Facebook" />
                        </button>
                        <p style={{color: 'black'}}>Don't have an account?
                            <a style={{ textDecoration: 'none', color: 'inherit' }}
                               href="#"
                               onMouseEnter={e => e.target.style.color = 'red'}
                               onMouseLeave={e => e.target.style.color = 'inherit'}
                               onClick={() => window.location.href = '/signup'}>
                                Sign Up
                            </a>
                        </p>
                    </div>
                </div>
                <div className={`${styles['col-lg-5']} ${styles['right-section']}`}>
                    <img src="/Images/hotel.png" alt="Riviera Hotel" className={styles['hotel-image']} />
                    <div className={styles['hotel-info']}>
                        <h2>RIVIERA</h2>
                        <p>C  O  A  S  T  A  L&nbsp;&nbsp;  E  L  E  G  A  N  C  E</p>
                    </div>
                </div>
            </div>
            <ForgotPassword />
        </div>
    );
}

export default Login;
