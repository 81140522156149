import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';

const RoomImageManager = () => {
    const [rooms, setRooms] = useState([]);
    const [roomImages, setRoomImages] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [newImages, setNewImages] = useState([]);

    // Fetch danh sách phòng
    const fetchRooms = async () => {
        try {
            const response = await axios.get('https://spring-api.azurewebsites.net/api/rooms');
            if (response.data.status === 0) {
                setRooms(response.data.data);
            } else {
                toast.error('Không thể tải danh sách phòng.');
            }
        } catch (error) {
            toast.error('Lỗi khi tải danh sách phòng.');
        }
    };

    // Fetch danh sách hình ảnh của một phòng
    const fetchRoomImages = async (roomId) => {
        try {
            const response = await axios.get(`https://spring-api.azurewebsites.net/api/roomImage/byRoom/${roomId}`);
            if (response.data.status === 0) {
                setRoomImages(response.data.data);
            } else {
                toast.error('Không có hình ảnh cho phòng này.');
            }
        } catch (error) {
            toast.error('Lỗi khi tải hình ảnh phòng.');
        }
    };

    // Xử lý mở Modal hiển thị hình ảnh
    const handleOpenModal = (roomId) => {
        setSelectedRoom(roomId);
        fetchRoomImages(roomId);
        setIsModalOpen(true);
    };

    // Đóng Modal
    const handleCloseModal = () => {
        setIsModalOpen(false);
        setRoomImages([]);
        setSelectedRoom(null);
        setNewImages([]);
    };

    // Xóa hình ảnh sau khi xác nhận
    const handleDeleteImage = async (imageId) => {
        try {
            const response = await axios.delete(`https://spring-api.azurewebsites.net/api/roomImage/${imageId}`);
            toast.success('Hình ảnh đã được xóa.');
            fetchRoomImages(selectedRoom); // Cập nhật danh sách hình ảnh sau khi xóa
        } catch (error) {
            toast.error('Lỗi khi xóa hình ảnh.');
        }
    };

    // Hiển thị hộp thoại xác nhận trước khi xóa
    const confirmDeleteImage = (imageId) => {
        Swal.fire({
            title: 'Bạn có chắc chắn muốn xóa hình ảnh này?',
            text: 'Hành động này không thể hoàn tác!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Có, xóa!',
            cancelButtonText: 'Hủy',
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteImage(imageId);
            }
        });
    };

    // Xử lý upload hình ảnh
    const handleImageUpload = async () => {
        if (newImages.length === 0) {
            toast.error('Vui lòng chọn ít nhất một hình ảnh.');
            return;
        }

        const formDataImages = new FormData();

        Array.from(newImages).forEach((file) => {
            const newFileName = `${uuidv4()}_${file.name.replace(/\s+/g, '_').toLowerCase()}`;
            const renamedFile = new File([file], newFileName, { type: file.type });
            formDataImages.append('files', renamedFile);
        });

        try {
            const uploadResponse = await axios.post('https://spring-api.azurewebsites.net/api/upload/images', formDataImages, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            const uploadedFiles = uploadResponse.data.uploadedFiles || [];
            const errors = uploadResponse.data.errors || [];

            if (uploadedFiles.length > 0) {
                await Promise.all(
                    uploadedFiles.map((imageUrl) =>
                        axios.post('https://spring-api.azurewebsites.net/api/roomImage', { roomId: selectedRoom, imageUrl })
                    )
                );
                toast.success('Hình ảnh đã được thêm.');
                fetchRoomImages(selectedRoom);
            }

            if (errors.length > 0) {
                toast.warning(`Một số file không thể tải lên: ${errors.join(', ')}`);
            }
        } catch (error) {
            toast.error('Lỗi khi thêm hình ảnh.');
        }
    };

    useEffect(() => {
        fetchRooms();
    }, []);

    return (
        <div className="container mt-5">
            <h2>Room Image Manager</h2>
            {rooms.length === 0 ? (
                <p>Không có dữ liệu phòng để hiển thị.</p>
            ) : (
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Room ID</th>
                        <th>Room Number</th>
                        <th>Price</th>
                        <th>Capacity</th>
                        <th>Description</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {rooms.map((room) => (
                        <tr key={room.roomID}>
                            <td>{room.roomID}</td>
                            <td>{room.roomNumber}</td>
                            <td>{room.price.toLocaleString()} VND</td>
                            <td>{room.capacity}</td>
                            <td>{room.description}</td>
                            <td>
                                <Button variant="primary" onClick={() => handleOpenModal(room.roomID)}>
                                    Edit
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            )}

            <Modal show={isModalOpen} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Room Image Manager</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <Form.Control
                            type="file"
                            multiple
                            onChange={(e) => setNewImages(Array.from(e.target.files))}
                        />
                        <Button className="mt-2" variant="success" onClick={handleImageUpload}>
                            Upload Images
                        </Button>
                    </div>
                    {roomImages.length === 0 ? (
                        <p>Không có hình ảnh nào cho phòng này.</p>
                    ) : (
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                            {roomImages.map((image) => (
                                <div key={image.imageId} style={{ position: 'relative' }}>
                                    <img
                                        src={`${image.imageUrl}`}
                                        alt="Room"
                                        style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                                    />
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        style={{ position: 'absolute', top: '5px', right: '5px' }}
                                        onClick={() => confirmDeleteImage(image.imageId)}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            ))}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default RoomImageManager;
