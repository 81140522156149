import React from "react";
import { FaSteam, FaFacebookF, FaTwitter, FaYoutube, FaTwitch } from "react-icons/fa";
import styles from "./CustomerComponentCSS/BlogUI.module.css";
import { useTranslation } from "react-i18next";

const BlogUI = () => {
    const { t } = useTranslation();

    const articles = [
        {
            img: "/Images/b5.jpg",
            alt: t("blogUI.experienceCuisine"),
            badge: t("blogUI.foodBadge"),
            title: t("blogUI.experienceCuisine"),
            description: t("blogUI.experienceCuisineDescription"),
            author: "Culinary Delight",
            date: "October 10, 2024",
        },
        {
            img: "/Images/b3.png",
            alt: t("blogUI.luxuryRedefined"),
            badge: t("blogUI.luxuryBadge"),
            title: t("blogUI.luxuryRedefined"),
            description: t("blogUI.luxuryRedefinedDescription"),
            author: "Riviera Blog Team",
            date: "September 5, 2024",
        },
        {
            img: "/Images/b.jpg",
            alt: t("blogUI.hiddenBeaches"),
            badge: t("blogUI.beachBadge"),
            title: t("blogUI.hiddenBeaches"),
            description: t("blogUI.hiddenBeachesDescription"),
            author: "Riviera Explorers",
            date: "August 20, 2024",
        },
        {
            img: "/Images/bs.jpg",
            alt: t("blogUI.spaExperience"),
            badge: t("blogUI.spaBadge"),
            title: t("blogUI.spaExperience"),
            description: t("blogUI.spaExperienceDescription"),
            author: "Spa Enthusiast",
            date: "July 15, 2024",
        },
        {
            img: "/Images/nl.jpeg",
            alt: t("blogUI.nightlifeEntertainment"),
            badge: t("blogUI.nightlifeBadge"),
            title: t("blogUI.nightlifeEntertainment"),
            description: t("blogUI.nightlifeEntertainmentDescription"),
            author: "Riviera Entertainment",
            date: "June 25, 2024",
        },
        {
            img: "/Images/av.jpg",
            alt: t("blogUI.adventureSports"),
            badge: t("blogUI.adventureBadge"),
            title: t("blogUI.adventureSports"),
            description: t("blogUI.adventureSportsDescription"),
            author: "Adventure Team",
            date: "May 30, 2024",
        },
        {
            img: "/Images/cl.jpg",
            alt: t("blogUI.culturalHeritage"),
            badge: t("blogUI.cultureBadge"),
            title: t("blogUI.culturalHeritage"),
            description: t("blogUI.culturalHeritageDescription"),
            author: "Culture Enthusiast",
            date: "April 12, 2024",
        },
        {
            img: "/Images/sc.jpg",
            alt: t("blogUI.sustainableTourism"),
            badge: t("blogUI.sustainabilityBadge"),
            title: t("blogUI.sustainableTourism"),
            description: t("blogUI.sustainableTourismDescription"),
            author: "Eco Advocate",
            date: "March 18, 2024",
        },
    ];

    return (
        <div className="container mt-4">
            <div className="row">
                {/* Left Column: Latest Articles */}
                <div className="col-md-9">
                    <h3>{t("blogUI.latestArticles")}</h3>
                    {articles.map((article, index) => (
                        <div className={styles.article} key={index}>
                            <div className="row">
                                <div className="col-4 position-relative">
                                    <img
                                        src={article.img}
                                        alt={article.alt}
                                        className={`img-fluid ${styles.imgFluid}`}
                                    />
                                    <span className={styles.badge}>{article.badge}</span>
                                </div>
                                <div className="col-8">
                                    <h5>{article.title}</h5>
                                    <p className="text-muted">{article.description}</p>
                                    <p>
                                        <small className="text-secondary">
                                            by{" "}
                                            <a href="#!" className="text-decoration-none text-primary">
                                                {article.author}
                                            </a>{" "}
                                            - {article.date}
                                        </small>
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Right Column: Popular Posts and Follow Us */}
                <div className="col-md-3">
                    <h4>{t("blogUI.popularPosts")}</h4>
                    <ul>
                        {articles.slice(0, 5).map((article, index) => (
                            <li key={index}>
                                <a href="#!" className="text-decoration-none text-primary">
                                    {article.title}
                                </a>
                            </li>
                        ))}
                    </ul>
                    <h4>{t("blogUI.followUs")}</h4>
                    <div className="d-flex justify-content-between">
                        <FaFacebookF className={styles.icon} />
                        <FaTwitter className={styles.icon} />
                        <FaYoutube className={styles.icon} />
                        <FaTwitch className={styles.icon} />
                        <FaSteam className={styles.icon} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogUI;
