import React, { useState, useEffect } from 'react';
import { Table, message, Space, Button, Modal, Form, Input, Checkbox, InputNumber } from 'antd';
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import axios from 'axios';

const AmenityManage = () => {
  const [amenities, setAmenities] = useState([]); // Dữ liệu tiện ích
  const [filteredAmenities, setFilteredAmenities] = useState([]); // Dữ liệu đã lọc theo tìm kiếm
  const [isModalVisible, setIsModalVisible] = useState(false); // Trạng thái hiển thị modal
  const [selectedAmenity, setSelectedAmenity] = useState(null); // Dữ liệu tiện ích được chọn
  const [isFree, setIsFree] = useState(false); // Trạng thái của checkbox "Is Free"
  const [searchText, setSearchText] = useState(''); // Từ khóa tìm kiếm
  const [form] = Form.useForm(); // Form trong Modal

  // Lấy danh sách tiện ích khi component được load
  useEffect(() => {
    axios.get('https://spring-api.azurewebsites.net/api/amenities')
      .then(response => {
        if (response.data && Array.isArray(response.data.data)) {
          setAmenities(response.data.data); // Lưu danh sách tiện ích
          setFilteredAmenities(response.data.data); // Ban đầu danh sách tiện ích hiển thị là toàn bộ
        } else {
          message.error('Failed to load amenities: Invalid data format.');
        }
      })
      .catch(error => {
        message.error('Failed to load amenities.');
        console.error('Error fetching amenities:', error);
      });
  }, []);

  // Xử lý tìm kiếm tiện ích theo tên
  const handleSearch = (value) => {
    setSearchText(value);
    const filteredData = amenities.filter((amenity) =>
      amenity.amenityName.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredAmenities(filteredData); // Cập nhật danh sách tiện ích đã lọc
  };

  // Hàm xử lý khi nhấn vào Edit
  const handleEdit = (record) => {
    axios.get(`https://spring-api.azurewebsites.net/api/amenities/${record.amenityId}`)
      .then(response => {
        setSelectedAmenity(response.data.data);
        setIsFree(response.data.data.free); // Cập nhật trạng thái free
        form.setFieldsValue({
          amenityName: response.data.data.amenityName,
          description: response.data.data.description,
          price: response.data.data.free ? 0 : response.data.data.price, // Đặt giá là 0 nếu miễn phí
          isFree: response.data.data.free,
        });
        setIsModalVisible(true); // Hiển thị modal
      })
      .catch(error => {
        message.error('Failed to fetch amenity data.');
        console.error('Error fetching amenity:', error);
      });
  };

  // Hàm xử lý khi nhấn nút Submit trong Modal
  const handleModalSubmit = () => {
    form.validateFields()
      .then(values => {
        const updatedValues = {
          ...values,
          price: isFree ? 0 : values.price, // Đảm bảo giá trị price là 0 nếu "Is Free"
          free: isFree
        };

        axios.put(`https://spring-api.azurewebsites.net/api/amenities/${selectedAmenity.amenityId}`, updatedValues)
          .then(response => {
            message.success('Amenity updated successfully!');
            setIsModalVisible(false); // Đóng modal
            setAmenities(prevAmenities => 
              prevAmenities.map(amenity => 
                amenity.amenityId === selectedAmenity.amenityId ? { ...amenity, ...updatedValues } : amenity
              )
            );
            handleSearch(searchText); // Cập nhật lại danh sách tiện ích đã lọc
          })
          .catch(error => {
            message.error('Failed to update amenity.');
            console.error('Error updating amenity:', error);
          });
      })
      .catch(error => {
        message.error('Please fill out all required fields.');
        console.error('Validation failed:', error);
      });
  };

  // Hàm xử lý khi nhấn Delete
  const handleDelete = (amenityId) => {
    axios.delete(`https://spring-api.azurewebsites.net/api/amenities/${amenityId}`)
      .then(() => {
        message.success('Amenity deleted successfully!');
        setAmenities(prevAmenities => prevAmenities.filter(amenity => amenity.amenityId !== amenityId));
        handleSearch(searchText); // Cập nhật lại danh sách tiện ích đã lọc
      })
      .catch(error => {
        message.error('Failed to delete amenity.');
        console.error('Error deleting amenity:', error);
      });
  };

  // Xử lý sự thay đổi của checkbox "Is Free"
  const handleIsFreeChange = (e) => {
    setIsFree(e.target.checked);
    if (e.target.checked) {
      form.setFieldsValue({ price: 0 }); // Đặt giá trị là 0 nếu "Is Free" được chọn
    }
  };

  // Cấu hình các cột trong bảng
  const columns = [
    {
      title: 'Amenity Name',
      dataIndex: 'amenityName',
      key: 'amenityName',
      sorter: (a, b) => a.amenityName.localeCompare(b.amenityName), // Sắp xếp theo tên
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      sorter: (a, b) => a.price - b.price, // Sắp xếp theo giá
      render: (price, record) => (record.free ? `$0` : price !== null ? `$${price}` : 'No Price'), // Hiển thị $0 nếu miễn phí
    },
    {
      title: 'Is Free',
      dataIndex: 'free',
      key: 'free',
      render: (isFree) => (isFree ? 'Yes' : 'No'), // Hiển thị Yes nếu miễn phí
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button 
            icon={<EditOutlined />} 
            type="primary" 
            onClick={() => handleEdit(record)}
          />
          <Button 
            icon={<DeleteOutlined />} 
            type="danger" 
            onClick={() => handleDelete(record.amenityId)}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <Space style={{ marginBottom: 16 }}>
        <Input
          placeholder="Search by Amenity Name"
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
          prefix={<SearchOutlined />}
        />
      </Space>

      <Table 
        dataSource={filteredAmenities} 
        columns={columns} 
        rowKey="amenityId" 
        pagination={{ pageSize: 5 }} // Giới hạn 5 bản ghi mỗi trang
      />

      {/* Modal chỉnh sửa Amenity */}
      <Modal
        title="Edit Amenity"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleModalSubmit}
        okText="Update"
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Amenity Name"
            name="amenityName"
            rules={[{ required: true, message: 'Please input the amenity name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
          >
            <Input.TextArea rows={4} />
          </Form.Item>

          <Form.Item
            name="isFree"
            valuePropName="checked"
          >
            <Checkbox onChange={handleIsFreeChange}>Is Free</Checkbox>
          </Form.Item>

          <Form.Item
            label="Price"
            name="price"
            rules={[{ required: !isFree, message: 'Please input the price!' }]}
          >
            <InputNumber min={0} style={{ width: '100%' }} disabled={isFree} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AmenityManage;
