import { useTranslation } from 'react-i18next';
import React from "react";
import Carousel from "react-bootstrap/Carousel";
import styles from './CustomerComponentCSS/HomeUI.module.css';  // Use CSS module
import CarouselUI from "./CarouselUI";
import { useNavigate } from 'react-router-dom';

const HomeUI = () => {
     const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const handleBookNow = () => {
        navigate('/accommodation');
    };

    return (
        <div className={styles.containerfull}>

            <video
                className={styles.videoFullScreen}
                src="/Images/Intro_Hotel.mp4"
                autoPlay
                loop
                muted
                playsInline>
                Your browser does not support the video tag.
            </video>
            <div className={styles.containerHeading}>
                <div className={`text-center py-5`}>
                    <h3 className={styles.heading}>{t('welcome')}</h3>
                    <p className={styles.paragraph}>
                        {t('description1')}
                    </p>
                    <p className={styles.paragraph}>
                        {t('description2')}
                    </p>
                    <button className={`${styles.btnPrimary} btn`}>{t('learnMore')}</button>
                </div>
            </div>
            <br /> <br /><br />

            <CarouselUI />

            <br /><br /><br /><br /><br />
            <div className="container">
                <div className="text-center mb-5">
                    <h3 className={styles.cap}>{t('ourInspiration')}</h3>
                </div>
                <div className="row">
                    <div className="col-md-3 mb-4">
                        <div className={`card ${styles.card}`}>
                            <img src="/Images/c1.jpg" className={`${styles.cardImgTop} card-img-top`} alt="Royal Palaces" />
                            <div className="card-body">
                                <h5 className={`${styles.cardTitle} card-title`}>{t('royalPalaces')}</h5>
                                <p className={`${styles.cardText} card-text`}>{t('royalPalacesDesc')}</p>
                                <a href="#" className={`${styles.btnSuccess} btn btn-success`}>{t('learnMore')}</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 mb-4">
                        <div className={`card ${styles.card}`}>
                            <img src="/Images/c2.webp" className={`${styles.cardImgTop} card-img-top`} alt="Ancient Temples" />
                            <div className="card-body">
                                <h5 className={`${styles.cardTitle} card-title`}>{t('ancientTemples')}</h5>
                                <p className={`${styles.cardText} card-text`}>{t('ancientTemplesDesc')}</p>
                                <a href="#" className={`${styles.btnSuccess} btn btn-success`}>{t('learnMore')}</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 mb-4">
                        <div className={`card ${styles.card}`}>
                            <img src="/Images/c3.jpg" className={`${styles.cardImgTop} card-img-top`} alt="Traditional Markets" />
                            <div className="card-body">
                                <h5 className={`${styles.cardTitle} card-title`}>{t('traditionalMarkets')}</h5>
                                <p className={`${styles.cardText} card-text`}>{t('traditionalMarketsDesc')}</p>
                                <a href="#" className={`${styles.btnSuccess} btn btn-success`}>{t('learnMore')}</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 mb-4">
                        <div className={`card ${styles.card}`}>
                            <img src="/Images/c4.jpg" className={`${styles.cardImgTop} card-img-top`} alt="Cultural Heritage" />
                            <div className="card-body">
                                <h5 className={`${styles.cardTitle} card-title`}>{t('culturalHeritage')}</h5>
                                <p className={`${styles.cardText} card-text`}>{t('culturalHeritageDesc')}</p>
                                <a href="#" className={`${styles.btnSuccess} btn btn-success`}>{t('learnMore')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br /><br /><br /><br /><br />
            <div className="text-center mb-5">
                <h3 className={styles.cap}>{t('introduction')}</h3>
            </div>

            <section className={styles.section}>
                    <div className="row">
                        <div className="col-md-7">
                            <Carousel>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="/Images/cr1.jpg"
                                        alt="First slide"
                                    />
                                    <Carousel.Caption>
                                        <h3>{t('gardenHouses')}</h3>
                                        <p className={styles.pCap}>{t('authenticRemnants')}</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="/Images/cr2.jpg"
                                        alt="Second slide"
                                    />
                                    <Carousel.Caption>
                                        <h3>{t('frenchVillas')}</h3>
                                        <p className={styles.pCap}>{t('reinventingLuxury')}</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            </Carousel>
                        </div>

                        <div className={`col-md-5 d-flex align-items-center justify-content-center ${styles.textBox}`}>
                            <div className={styles.contentWrapper}>
                                <h3>{t('rivieraPool')}</h3>
                                <p>{t('rivieraPoolDescription')}</p>
                                <button className={`${styles.btnLearn} btn`}>{t('learnMore')}</button>
                            </div>
                        </div>
                    </div>

                    <br />
                    <div className="row">
                        <div className={`col-md-5 d-flex align-items-center justify-content-center ${styles.textBox}`}>
                            <div className={styles.contentWrapper}>
                                <h3>{t('rivieraGarden')}</h3>
                                <p>{t('rivieraGardenDescription')}</p>
                                <button className={`${styles.btnLearn} btn`}>{t('learnMore')}</button>
                            </div>
                        </div>

                        <div className="col-md-7">
                            <Carousel>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="/Images/cr1.jpg"
                                        alt="Third slide"
                                    />
                                    <Carousel.Caption>
                                        <h3>{t('ourInspiration')}</h3>
                                        <p className={styles.pCap}>{t('inspiredByNature')}</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="/Images/cr2.jpg"
                                        alt="Fourth slide"
                                    />
                                    <Carousel.Caption>
                                        <h3>{t('modernArchitecture')}</h3>
                                        <p className={styles.pCap}>{t('blendingClassic')}</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </section>

            <br /><br /><br /><br />
            <div className="container text-center mb-5">
                <h3 className={styles.cap}>{t('ecosystem')}</h3>
                <p>{t('ecosystemDesc1')}</p>
                <br />
                <p>{t('ecosystemDesc2')}</p>
                <button className={`${styles.btnPrimary} btn`} onClick={handleBookNow}>{t('bookYourRoom')}</button>
            </div>

        </div>
    );
};

export default HomeUI;
