import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Select, message, DatePicker, Row, Col } from 'antd';
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';

const { Option } = Select;

const ManageEmployee = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [form] = Form.useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
  });

  // Fetch employee data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://spring-api.azurewebsites.net/api/staff');
        const result = await response.json();
        const formattedData = result.data.map((staff, index) => ({
          no: index + 1,
          id: staff.id,
          ...staff,
          position: staff.position || 'N/A',
        }));
        setData(formattedData);
        setFilteredData(formattedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleEdit = (record) => {
    setSelectedStaff(record);
    form.setFieldsValue({
      ...record,
      dateOfHire: record.dateOfHire ? moment(record.dateOfHire, 'YYYY-MM-DD') : null,
      password: '********', 
    });
    setIsModalVisible(true);
};


  const handleSave = async () => {
    try {
      const values = await form.validateFields();

      // Kiểm tra nếu mật khẩu có được nhập hay không
      let updatedStaff = { ...values, dateOfHire: values.dateOfHire.format('YYYY-MM-DD') };

      if (!values.password) {
        // Nếu không nhập mật khẩu mới, xóa trường password
        delete updatedStaff.password;
      }

      await axios.put(`https://spring-api.azurewebsites.net/api/staff/${selectedStaff.id}`, updatedStaff);
      const updatedList = data.map((item) =>
        item.id === selectedStaff.id ? { ...item, ...updatedStaff } : item
      );
      setData(updatedList);
      setFilteredData(updatedList);

      message.success('Updated successfully!');
      setIsModalVisible(false);
    } catch (error) {
      message.error('An error occurred during the update!');
    }
  };

  const handleDelete = async (record) => {
    try {
      await fetch(`https://spring-api.azurewebsites.net/api/staff/${record.id}`, { method: 'DELETE' });
      const updatedList = data.filter((item) => item.id !== record.id);
      setData(updatedList);
      setFilteredData(updatedList);
      message.success('Deleted successfully!');
    } catch (error) {
      message.error('Failed to delete!');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedStaff(null);
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = data.filter(
      (item) =>
        item.userName.toLowerCase().includes(value) ||
        item.firstName.toLowerCase().includes(value) ||
        item.lastName.toLowerCase().includes(value) ||
        item.position.toLowerCase().includes(value)
    );
    setFilteredData(filtered);
    setPagination({ ...pagination, current: 1 }); // Reset pagination to the first page
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  // Define the table columns with sorting functionality
  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      key: 'no',
      render: (text) => <a style={{ color: 'blue', fontWeight: 'bold' }}>{text}</a>,
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    },
    {
      title: 'Username',
      dataIndex: 'userName',
      key: 'userName',
      sorter: (a, b) => a.userName.localeCompare(b.userName),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
      sorter: (a, b) => a.position.localeCompare(b.position),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <span>
          <Button icon={<EditOutlined />} type="primary" onClick={() => handleEdit(record)} style={{ marginRight: 8 }} />
          <Button icon={<DeleteOutlined />} type="danger" onClick={() => handleDelete(record)} />
        </span>
      ),
    },
  ];

  return (
    <div style={{ padding: 20, backgroundColor: '#f5f5f5' }}>
      <h2 style={{ textAlign: 'center' }}>Manage Employees</h2>

      {/* Search bar */}
      <Input
        placeholder="Search by username, first name, last name, or position"
        prefix={<SearchOutlined />}
        value={searchTerm}
        onChange={handleSearch}
        style={{ marginBottom: 20, width: '50%' }}
      />

      {/* Display total number of records and pages */}
      <div style={{ marginBottom: 10 }}>
        <strong>Total Records: </strong> {filteredData.length}
        <br />
        <strong>Total Pages: </strong> {Math.ceil(filteredData.length / pagination.pageSize)}
      </div>

      {/* Table */}
      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: filteredData.length,
          showSizeChanger: false, // Only show 5 records per page
        }}
        onChange={handleTableChange}
        rowKey="id"
      />

      <Modal
        title="Edit Employee Information"
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleSave}
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Username" name="userName">
            <Input disabled />
          </Form.Item>

          <Form.Item label="Password" name="password">
            <Input.Password disabled />  {/* Make this field non-editable */}
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="First Name" name="firstName" rules={[{ required: true, message: 'Please input first name' }]}>
                <Input placeholder="Enter first name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: 'Please input last name' }]}>
                <Input placeholder="Enter last name" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Email" name="email" rules={[{ required: true, type: 'email', message: 'Please input a valid email' }]}>
            <Input placeholder="Enter email" />
          </Form.Item>

          <Form.Item label="Phone" name="phone" rules={[{ required: true, message: 'Please input phone number' }]}>
            <Input placeholder="Enter phone number" />
          </Form.Item>

          <Form.Item label="Position" name="position" rules={[{ required: true, message: 'Please select a position' }]}>
            <Select placeholder="Select position">
              <Option value="Front Desk Staff">Front Desk Staff</Option>
              <Option value="Reservation Agent">Reservation Agent</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Date of Hire" name="dateOfHire" rules={[{ required: true, message: 'Please select a start date' }]}>
            <DatePicker format="YYYY-MM-DD" placeholder="Select date of hire" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ManageEmployee;
