import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './EmployeeCSS/RoomBooking.module.css';
import { Pagination } from 'react-bootstrap';
import Swal from 'sweetalert2';

const RoomCard = () => {
    const [rooms, setRooms] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [roomsPerPage] = useState(8);
    const [totalPages, setTotalPages] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        fetchRooms();
    }, []);

    const fetchRooms = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://spring-api.azurewebsites.net/api/rooms/list-with-type-name');
            if (response.data) {
                const roomsData = response.data;
                setRooms(roomsData);
                setTotalPages(Math.ceil(roomsData.length / roomsPerPage));
            }
        } catch (error) {
            console.error('Error fetching rooms:', error);
        } finally {
            setLoading(false);
        }
    };

    const formatCurrency = (amount) =>
        new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
        }).format(amount);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const indexOfLastRoom = currentPage * roomsPerPage;
    const indexOfFirstRoom = indexOfLastRoom - roomsPerPage;
    const currentRooms = rooms.slice(indexOfFirstRoom, indexOfLastRoom);

    const handleRoomBooking = (room) => {
        if (room.roomStatusID === 1) {
            navigate('/bookingRoom', {
                state: {
                    roomId: room.roomID,
                    roomName: room.roomTypeName,
                    roomPrice: room.price,
                },
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Unavailable',
                text: 'This room is not available for booking!',
            });
        }
    };

    const handleCreateRoom = () => {
        navigate('/createRoom');
    };

    return (
        <div className={`${styles.container}`}>
            <div className="d-flex justify-content-end align-items-center mb-3">
                <button className="btn btn-primary" onClick={handleCreateRoom}>
                    Create Room
                </button>
            </div>

            {loading ? (
                <p className="text-center">Loading rooms...</p>
            ) : (
                <div className="row g-4">
                    {currentRooms.map((room) => (
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3" key={room.roomID}>
                            <div className={`${styles['room-card']} shadow`}>
                                <div className={`${styles['card-img']}`}>
                                    {room.imageList?.images?.length > 0 ? (
                                        <img
                                            src={room.imageList.images[0].imageUrl}
                                            alt={room.roomTypeName}
                                            className="img-fluid rounded-top"
                                        />
                                    ) : (
                                        <img
                                            src="https://via.placeholder.com/300x140"
                                            alt="Room not available"
                                            className="img-fluid rounded-top"
                                        />
                                    )}
                                </div>
                                <div className={`${styles['card-body']}`}>
                                    <h5 className="mb-2 text-truncate">{room.roomTypeName}</h5>
                                    <p className="text-muted mb-1">Price: {formatCurrency(room.price)}</p>
                                    <p className="text-muted mb-1">Capacity: {room.capacity} people</p>
                                    <p className="text-muted">Floor: {room.floor}</p>
                                    <button
                                        className={`btn ${
                                            room.roomStatusID === 1 ? 'btn-success' : 'btn-secondary'
                                        } w-100 mt-2`}
                                        onClick={() => handleRoomBooking(room)}
                                        disabled={room.roomStatusID !== 1}
                                    >
                                        {room.roomStatusID === 1 ? 'Book Now' : 'Unavailable'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            <footer className="d-flex justify-content-center mt-4">
                <Pagination>
                    <Pagination.First onClick={() => paginate(1)} />
                    <Pagination.Prev onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)} />
                    {[...Array(totalPages)].map((_, index) => (
                        <Pagination.Item
                            key={index + 1}
                            active={index + 1 === currentPage}
                            onClick={() => paginate(index + 1)}
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={() => paginate(currentPage < totalPages ? currentPage + 1 : totalPages)} />
                    <Pagination.Last onClick={() => paginate(totalPages)} />
                </Pagination>
            </footer>
        </div>
    );
};

export default RoomCard;
