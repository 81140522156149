// src/components/RoomHistory/RoomHistory.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './CustomerComponentCSS/RoomHistory.module.css';
import { useTranslation } from "react-i18next";
import i18n from './i18n'; // Đảm bảo import cấu hình i18n

const RoomHistory = () => {
    const [customerData, setCustomerData] = useState(null);
    const [bookings, setBookings] = useState([]);
    const [filteredBookings, setFilteredBookings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchDate, setSearchDate] = useState('');

    const getToken = () => localStorage.getItem('token');
    const { t } = useTranslation(); // Sử dụng hook t từ react-i18next

    const fetchUserDataAndBookingHistory = async () => {
        const token = getToken();
        if (!token) {
            setError(t('history.error.invalidToken')); // Sử dụng t() để dịch lỗi
            setLoading(false);
            return;
        }

        try {
            setLoading(true);
            setError(null);

            const userResponse = await fetch('https://spring-api.azurewebsites.net/api/customers/myProfile', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!userResponse.ok) throw new Error(t('history.error.failedToFetchUserData'));

            const userData = await userResponse.json();
            if (userData && userData.data) {
                const { id, fullName, phone } = userData.data;
                setCustomerData({
                    id: id || '',
                    fullName: fullName || '',
                    phone: phone || '',
                });

                const bookingResponse = await fetch(`https://spring-api.azurewebsites.net/api/bookings/customer/${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!bookingResponse.ok) throw new Error(t('history.error.failedToFetchBookingHistory'));

                const bookingData = await bookingResponse.json();
                if (bookingData && bookingData.data) {
                    const sortedBookings = bookingData.data.sort((a, b) => {
                        const dateA = new Date(a.bookingDate);
                        const dateB = new Date(b.bookingDate);
                        return dateB - dateA;
                    });

                    setBookings(sortedBookings);
                    setFilteredBookings(sortedBookings);
                } else {
                    throw new Error(t('history.error.invalidBookingResponseData'));
                }
            } else {
                throw new Error(t('history.error.invalidUserResponseData'));
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUserDataAndBookingHistory();
    }, []);

    const handleDateChange = (event) => {
        setSearchDate(event.target.value);
    };

    const handleSearch = () => {
        if (!searchDate) {
            setFilteredBookings(bookings);
        } else {
            const filtered = bookings.filter(booking => {
                const bookingDate = new Date(booking.bookingDate).toLocaleDateString();
                return bookingDate === new Date(searchDate).toLocaleDateString();
            });
            setFilteredBookings(filtered);
        }
    };

    // Hàm để lấy class màu dựa trên status
    const getStatusClass = (status) => {
        let translatedStatus;
        let className;

        switch (status.toLowerCase()) {
            case 'pending':
                translatedStatus = t('historys.status.pending');
                className = styles.statusPending;
                break;
            case 'cancelled':
                translatedStatus = t('historys.status.cancelled');
                className = styles.statusCancelled;
                break;
            case 'booked':
                translatedStatus = t('historys.status.booked');
                className = styles.statusBooked; // Tạo class riêng cho "Booked"
                break;
            case 'checked-in':
                translatedStatus = t('historys.status.checkedIn');
                className = styles.statusCheckedIn; // Tạo class riêng cho "Checked-In"
                break;
            case 'checked-out':
                translatedStatus = t('historys.status.checkedOut');
                className = styles.statusCheckedOut; // Tạo class riêng cho "Checked-Out"
                break;
            default:
                translatedStatus = t('historys.status.pending');
                className = styles.statusDefault;
                break;
        }

        return { className, translatedStatus };
    };


    return (
        <div className={`container ${styles.orderContainer}`}>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <Link to="/" className="navbar-brand">
                    <img className={styles.logoImg} src="/logoo.png" alt={t('historys.logo')} />
                </Link>
                <div className="d-flex align-items-center">
                    <input style={{ width: 200 }}
                           type="date"
                           id="searchDate"
                           className="form-control ms-2"
                           value={searchDate}
                           onChange={handleDateChange}
                    />
                    <button
                        style={{ marginTop: 0 }}
                        className="btn btn-primary ms-3"
                        onClick={handleSearch}
                    >
                        {t('historys.searchPlaceholder')}
                    </button>
                </div>
            </div>

            {loading ? (
                <p className="text-center">{t('historys.loading')}</p>
            ) : error ? (
                <p className="text-center text-danger">{t('historys.error')}: {error}</p>
            ) : filteredBookings.length > 0 ? (
                <div className="card p-4">
                    <div className="row">
                        {filteredBookings.map((booking, index) => (
                            <div className="col-md-4 mb-4" key={`${booking.roomId}-${index}`}>
                                <div className={styles.orderCard}>
                                    <div className={styles.orderCardHeader}>
                                        <h5>{booking.roomId}</h5>
                                        <span className={`${styles.status} ${getStatusClass(booking.status).className}`}>
                                            {getStatusClass(booking.status).translatedStatus}
                                        </span>
                                    </div>
                                    <div className={styles.orderCardBody}>
                                        <p><strong>{t('historys.fullName')}:</strong> {customerData?.fullName}</p>
                                        <p><strong>{t('historys.phone')}:</strong> {customerData?.phone}</p>
                                        <p><strong>{t('historys.bookingDate')}:</strong> {new Date(booking.bookingDate).toLocaleDateString()}</p>
                                        <p><strong>{t('historys.checkIn')}:</strong> {new Date(booking.checkInDate).toLocaleDateString()}</p>
                                        <p><strong>{t('historys.checkOut')}:</strong> {new Date(booking.checkOutDate).toLocaleDateString()}</p>
                                        <p><strong>{t('historys.totalAmount')}:</strong> {booking.totalAmount}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <p className="text-center">{t('historys.noBookingHistory')}</p>
            )}
        </div>
    );
};

export default RoomHistory;
