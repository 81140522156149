import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './CustomerComponentCSS/SignUp.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import {useTranslation} from "react-i18next";

function Register() {
    const [showPassword, setShowPassword] = useState(false);
    const [userName, setUsername] = useState('');  // Tên biến đúng
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [errors, setErrors] = useState({});
    const [registrationError, setRegistrationError] = useState('');
    const { t, i18n } = useTranslation();


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const validateForm = () => {
        let newErrors = {};
        if (userName.trim() === '') {
            newErrors.userName = 'Username is required.';  // Sửa thành userName
        } else if (userName.length > 30) {
            newErrors.userName = 'Username must not exceed 30 characters.';  // Sửa thành userName
        }
        if (password.trim() === '') {
            newErrors.password = 'Password is required.';
        } else if (password.length > 30) {
            newErrors.password = 'Password must not exceed 30 characters.';
        }
        if (firstName.trim() === '') {
            newErrors.firstName = 'First Name is required.';
        }
        if (lastName.trim() === '') {
            newErrors.lastName = 'Last Name is required.';
        }
        if (email.trim() === '') {
            newErrors.email = 'Email is required.';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Email is invalid.';
        }
        if (phone.trim() === '') {
            newErrors.phone = 'Phone number is required.';
        }
        if (address.trim() === '') {
            newErrors.address = 'Address is required.';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            try {
                const response = await axios.post('https://spring-api.azurewebsites.net/api/customers', {
                    userName,
                    password,
                    firstName,
                    lastName,
                    email,
                    phone,
                    address,
                });

                if (response.status === 201) {
                    toast.success('Registration successful!');
                    // Chuyển hướng đến trang đăng nhập
                    window.location.href = '/login';
                }
            } catch (error) {
                if (error.response) {
                    setRegistrationError('Registration failed. Please try again.');
                    toast.error('Registration failed. Please try again.');
                } else {
                    toast.error('Unable to connect to the server.');
                }
            }
        }
    };


    return (
        <div className={styles.containerf}>
            <ToastContainer />

            <div className={`${styles['row']} justify-content-center ${styles['login-box']}`}>
                <div className={`${styles['col-lg-7']} ${styles['left-section']}`}>
                    <div className={styles['login-form']}>
                        <h3>REGISTER FORM</h3>
                        {registrationError && <p className={styles['error-message']}>{registrationError}</p>}
                        <form onSubmit={handleSubmit}>
                            {/* Cặp Username và Password */}
                            <div className="row">
                                <div className="col-md-6 mb-3 position-relative">
                                    <label htmlFor="userName" className="form-label">Username</label>
                                    <input
                                        type="text"
                                        className={`${styles['form-control']}`}
                                        id="userName"
                                        value={userName}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                    {errors.userName && <small className={styles['error-message']}>{errors.userName}</small>}  {/* Sửa thành userName */}
                                </div>
                                <div className="col-md-6 mb-3 position-relative">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        className={`${styles['form-control']}`}
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    {errors.password && <small className={styles['error-message']}>{errors.password}</small>}
                                    <i
                                        className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} ${styles['toggle-password']}`}
                                        onClick={togglePasswordVisibility}
                                        style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '40%' }}
                                        title={showPassword ? 'Hide password' : 'Show password'}
                                    ></i>
                                </div>
                            </div>

                            {/* Cặp First Name và Last Name */}
                            <div className="row">
                                <div className="col-md-6 mb-3 position-relative">
                                    <label htmlFor="firstName" className="form-label">First Name</label>
                                    <input
                                        type="text"
                                        className={`${styles['form-control']}`}
                                        id="firstName"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                    {errors.firstName && <small className={styles['error-message']}>{errors.firstName}</small>}
                                </div>
                                <div className="col-md-6 mb-3 position-relative">
                                    <label htmlFor="lastName" className="form-label">Last Name</label>
                                    <input
                                        type="text"
                                        className={`${styles['form-control']}`}
                                        id="lastName"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                    {errors.lastName && <small className={styles['error-message']}>{errors.lastName}</small>}
                                </div>
                            </div>

                            {/* Cặp Email và Phone trên cùng 1 hàng */}
                            <div className="row">
                                <div className="col-md-6 mb-3 position-relative">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input
                                        type="email"
                                        className={`${styles['form-control']}`}
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    {errors.email && <small className={styles['error-message']}>{errors.email}</small>}
                                </div>
                                <div className="col-md-6 mb-3 position-relative">
                                    <label htmlFor="phone" className="form-label">Phone</label>
                                    <input
                                        type="text"
                                        className={`${styles['form-control']}`}
                                        id="phone"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                    {errors.phone && <small className={styles['error-message']}>{errors.phone}</small>}
                                </div>
                            </div>

                            <div className="mb-3 position-relative">
                                <label htmlFor="address" className="form-label">Address</label>
                                <input
                                    type="text"
                                    className={`${styles['form-control']}`}
                                    id="address"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                                {errors.address && <small className={styles['error-message']}>{errors.address}</small>}
                            </div>

                            <button
                                type="submit"
                                className={styles.loginBtn}
                                style={{ backgroundColor: '#b29a89', color: 'white' }}
                                onMouseEnter={e => e.target.style.backgroundColor = 'tomato'}
                                onMouseLeave={e => e.target.style.backgroundColor = '#b29a89'}>
                                Register
                            </button>

                        </form>
                    </div>
                    <div className={styles['social-login']}>
                        <p>or</p>
                        <button className={`${styles['btn']} ${styles['btn-google']}`}>
                            <img src="/Images/google.png" alt="Google" />
                        </button>
                        <button className={`${styles['btn']} ${styles['btn-facebook']}`}>
                            <img src="/Images/facebook.png" alt="Facebook" />
                        </button>
                        <p style={{color: 'black'}}>Already have an account? <a style={{ textDecoration: 'none', color: 'black' }}
                                                       href="/login"
                                                       onMouseEnter={e => e.target.style.color = 'red'}
                                                       onMouseLeave={e => e.target.style.color = 'inherit'}>
                            Login
                        </a></p>
                    </div>
                </div>
                <div className={`${styles['col-lg-5']} ${styles['right-section']}`}>
                    <img src="/Images/hotel.png" alt="Riviera Hotel" className={styles['hotel-image']} />
                    <div className={styles['hotel-info']}>
                        <h2>RIVIERA</h2>
                        <p>C  O  A  S  T  A  L&nbsp;&nbsp;  E  L  E  G  A  N  C  E</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;
