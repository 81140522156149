import React, { useEffect, useState } from 'react';
import { Form, Spinner, Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Import jwtDecode để giải mã token
import styles from './AdminCSS/MyProfile.module.css';

const AdminProfile = () => {
    const [adminData, setAdminData] = useState(null); // Dữ liệu admin
    const [loading, setLoading] = useState(true); // Trạng thái loading
    const [showModal, setShowModal] = useState(false); // Trạng thái hiển thị modal
    const [editData, setEditData] = useState({}); // Dữ liệu chỉnh sửa
    const token = localStorage.getItem('token'); // Lấy token từ localStorage

    useEffect(() => {
        const fetchAdminData = async () => {
            if (!token) {
                toast.error('User not authenticated. Please log in again.');
                window.location.href = '/login'; // Điều hướng về trang đăng nhập nếu token không có
                return;
            }

            try {
                // Decode token để lấy userId
                const decodedToken = jwtDecode(token);
                const userId = decodedToken.sub;

                // Gọi API để lấy thông tin admin
                const response = await axios.get(`https://spring-api.azurewebsites.net/api/users/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.data && response.data.data) {
                    setAdminData(response.data.data); // Lưu dữ liệu admin vào state
                } else {
                    toast.error('Failed to fetch admin data.');
                }
            } catch (error) {
                console.error('Error fetching admin data:', error);
                toast.error('Error fetching admin information. Please try again.');
            } finally {
                setLoading(false); // Dừng loading
            }
        };

        fetchAdminData(); // Gọi hàm lấy dữ liệu admin
    }, [token]);

    // Hiển thị spinner khi đang tải dữ liệu
    if (loading) {
        return <Spinner animation="border" />;
    }

    // Xử lý hiển thị modal
    const handleShowModal = () => {
        setEditData({ ...adminData }); // Sao chép dữ liệu hiện tại
        setShowModal(true); // Hiển thị modal
    };

    const handleCloseModal = () => {
        setShowModal(false); // Đóng modal
    };

    // Xử lý thay đổi trong form chỉnh sửa
    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditData({ ...editData, [name]: value });
    };

    // Xử lý cập nhật thông tin admin
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(`https://spring-api.azurewebsites.net/api/users/${adminData.id}`, editData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.data && response.data.status === 0) {
                setAdminData(editData); // Cập nhật dữ liệu hiển thị
                toast.success('Profile updated successfully.');
                handleCloseModal();
            } else {
                toast.error('Failed to update profile.');
            }
        } catch (error) {
            console.error('Error updating admin data:', error);
            toast.error('Error updating profile. Please try again.');
        }
    };

    return (
        <div className={styles.container}>
            <h2 className={styles.profileTitle}>Admin Profile</h2>
            <Form className={styles.profileForm}>
                <Form.Group controlId="firstName" className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type="text" value={adminData?.firstName || ''} readOnly />
                </Form.Group>
                <Form.Group controlId="lastName" className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text" value={adminData?.lastName || ''} readOnly />
                </Form.Group>
                <Form.Group controlId="email" className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" value={adminData?.email || ''} readOnly />
                </Form.Group>
                <Form.Group controlId="phone" className="mb-3">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control type="text" value={adminData?.phone || ''} readOnly />
                </Form.Group>
                <Form.Group controlId="role" className="mb-3">
                    <Form.Label>Role</Form.Label>
                    <Form.Control type="text" value={adminData?.role || ''} readOnly />
                </Form.Group>
                <Form.Group controlId="status" className="mb-3">
                    <Form.Label>Status</Form.Label>
                    <Form.Control type="text" value={adminData?.status ? 'Active' : 'Inactive'} readOnly />
                </Form.Group>
                <Button variant="primary" onClick={handleShowModal}>
                    Update
                </Button>
            </Form>

            {/* Modal chỉnh sửa thông tin */}
            <Modal show={showModal} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Update Admin Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="editFirstName" className="mb-3">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="firstName"
                                value={editData.firstName || ''}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="editLastName" className="mb-3">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="lastName"
                                value={editData.lastName || ''}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="editEmail" className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={editData.email || ''}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="editPhone" className="mb-3">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                type="text"
                                name="phone"
                                value={editData.phone || ''}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Button variant="success" type="submit">
                            Save Changes
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AdminProfile;
