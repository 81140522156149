import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'; // Import SweetAlert
import styles from './CustomerComponentCSS/Registration.module.css';

const Registration = () => {
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            setUserId(storedUserId);
        }
    }, []);

    useEffect(() => {
        const fetchUserIdByCustomerId = async () => {
            try {
                const customerId = localStorage.getItem('customerId');
                const token = localStorage.getItem('token');

                if (!customerId || !token) {
                    throw new Error('Missing customerId or token');
                }

                const response = await axios.get(
                    `https://spring-api.azurewebsites.net/api/customers/${customerId}`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );

                console.log('API Response:', response.data);

                if (response.data && response.data.data && response.data.data.userId) {
                    const fetchedUserId = response.data.data.userId;
                    setUserId(fetchedUserId);
                    localStorage.setItem('userId', fetchedUserId);
                } else {
                    throw new Error('Could not retrieve userId');
                }
            } catch (error) {
                console.error('Error fetching userId:', error);
                alert('Error fetching userId: ' + error.message);
            }
        };

        fetchUserIdByCustomerId();
    }, []);

    const validateForm = () => {
        const newErrors = {};

        // Kiểm tra mật khẩu cũ
        if (!password.trim()) {
            newErrors.password = "Old password is required.";
        } else if (password.length < 8 || password.length > 20) {
            newErrors.password = "Old password must be between 8 and 20 characters.";
        }

        // Kiểm tra mật khẩu mới
        if (!newPassword.trim()) {
            newErrors.newPassword = "New password is required.";
        } else if (newPassword.length < 8 || newPassword.length > 20) {
            newErrors.newPassword = "New password must be between 8 and 20 characters.";
        } else if (newPassword === password) {
            newErrors.newPassword = "New password must be different from the old password.";
        }

        // Kiểm tra xác nhận mật khẩu
        if (!confirmPassword.trim()) {
            newErrors.confirmPassword = "Confirm password is required.";
        } else if (confirmPassword !== newPassword) {
            newErrors.confirmPassword = "Passwords do not match.";
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (validateForm()) {
            try {
                if (!userId) {
                    alert('User ID not found. Please try again.');
                    return;
                }

                const response = await axios.post(
                    'https://spring-api.azurewebsites.net/api/users/change-password',
                    {
                        userId: userId,
                        oldPassword: password,
                        newPassword: newPassword,
                        confirmNewPassword: confirmPassword,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        },
                    }
                );

                if (response.status === 200) {
                    // Sử dụng SweetAlert cho thông báo thành công
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Password successfully updated!',
                    });
                    setPassword('');
                    setNewPassword('');
                    setConfirmPassword('');
                } else {
                    alert('Failed to change password!');
                }
            } catch (error) {
                console.error('Password change error:', error);
                alert('Password change failed: ' + (error.response?.data?.message || error.message));
            }
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <div className={styles.appContainer}>
            <div className={styles.passwordCard}>
                <h3>Change Password</h3>
                <form onSubmit={handleSubmit}>
                    <div className={`${styles.formGroup} position-relative`}>
                        <label htmlFor="password">Old Password</label>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            className={styles.formControl}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <i
                            className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} ${styles['toggle-password']}`}
                            onClick={togglePasswordVisibility}
                            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '40%' }}
                            title={showPassword ? 'Hide password' : 'Show password'}
                        ></i>
                        {errors.password && <small className={styles.textDanger}>{errors.password}</small>}
                    </div>

                    <div className={`${styles.formGroup} position-relative`}>
                        <label htmlFor="newPassword">New Password</label>
                        <input
                            type={showNewPassword ? 'text' : 'password'}
                            id="newPassword"
                            className={styles.formControl}
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <i
                            className={`fas ${showNewPassword ? 'fa-eye-slash' : 'fa-eye'} ${styles['toggle-password']}`}
                            onClick={toggleNewPasswordVisibility}
                            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '40%' }}
                            title={showNewPassword ? 'Hide password' : 'Show password'}
                        ></i>
                        {errors.newPassword && <small className={styles.textDanger}>{errors.newPassword}</small>}
                    </div>

                    <div className={`${styles.formGroup} position-relative`}>
                        <label htmlFor="confirmPassword">Confirm Password</label>
                        <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            id="confirmPassword"
                            className={styles.formControl}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <i
                            className={`fas ${showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'} ${styles['toggle-password']}`}
                            onClick={toggleConfirmPasswordVisibility}
                            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '40%' }}
                            title={showConfirmPassword ? 'Hide password' : 'Show password'}
                        ></i>
                        {errors.confirmPassword && <small className={styles.textDanger}>{errors.confirmPassword}</small>}
                    </div>

                    <button
                        style={{ backgroundColor: '#b29a89', color: 'white' }}
                        type="submit"
                        className={styles.btnLogin}
                    >
                        Change Password
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Registration;
