import React from 'react';
import { Form, Input, Button, InputNumber, Select, message } from 'antd';
import axios from 'axios';

const { Option } = Select;

const AddService = () => {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      const newService = {
        serviceName: values.serviceName,
        description: values.description,
        price: values.price,
        status: values.status, // Status from the select dropdown
        serviceDuration: values.serviceDuration,
      };

      // Call the API to add a new service
      const response = await axios.post('https://spring-api.azurewebsites.net/api/services', newService);
      message.success('Service added successfully!');
      form.resetFields(); // Reset the form fields after successful submission
    } catch (error) {
      console.error('Failed to add service:', error);
      message.error('Failed to add service.');
    }
  };

  return (
    <div>
      <h2>Add Service</h2>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Service Name"
          name="serviceName"
          rules={[{ required: true, message: 'Please input the service name!' }]}
        >
          <Input placeholder="Enter service name" />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: 'Please input the description!' }]}
        >
          <Input.TextArea placeholder="Enter service description" rows={4} />
        </Form.Item>

        <Form.Item
          label="Price"
          name="price"
          rules={[{ required: true, message: 'Please input the service price!' }]}
        >
          <InputNumber
            min={0}
            step={0.01}
            style={{ width: '100%' }}
            placeholder="Enter service price"
          />
        </Form.Item>

        <Form.Item
          label="Service Duration (Minutes)"
          name="serviceDuration"
          rules={[{ required: true, message: 'Please input the service duration!' }]}
        >
          <InputNumber
            min={1}
            style={{ width: '100%' }}
            placeholder="Enter service duration (in minutes)"
          />
        </Form.Item>

        {/* Status field as a Select with hardcoded options */}
        <Form.Item
          label="Status"
          name="status"
          rules={[{ required: true, message: 'Please select the service status!' }]}
        >
          <Select placeholder="Select service status">
            <Option value="Available">Available</Option>
            <Option value="Unavailable">Unavailable</Option>
            <Option value="Under Maintenance">Under Maintenance</Option>
            <Option value="Discontinued">Discontinued</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add Service
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddService;
