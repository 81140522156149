import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import CustomerList from './CustomerList';
import GuestList from './GuestList';
import AddStaffForm from './AddStaffForm';
import ManageEmployee from './ManageEmployee';
import AddAmenityForm from './AddAmenityForm';
import AmenityManage from './AmenityManage';
import DefaultContent from './DefaultContent';
import AddRoomStatusForm from './AddRoomStatusForm';
import AddRoomType from './AddRoomType';
import RoomTypeManage from './RoomTypeManage';
import AddRoomForm from './AddRoomForm';
import RoomManage from './RoomManage';
import AddService from './AddService';
import ServiceManage from './ServiceManage';
import PaymentMethodManage from './PaymentMethodManage';
import RoomAmenityManager from './RoomAmenityManager';
import RoomImageManager from './RoomImageManager';
import AdminProfile from './AdminProfile';
import ChangePassUI from './ChangePassUI';
const Admin = () => {
    return (
        <div style={{ display: 'flex' }}>
            {/* Sidebar */}
            <div style={{ width: '256px', height: 'auto', backgroundColor: '#001529' }}>
                <Sidebar />
            </div>

            {/* Main Content */}
            <div style={{ flexGrow: 1 }}>
                <Header />
                <div style={{ padding: '20px' }}>
                    <Routes>
                        {/* Default route */}
                        <Route path="/" element={<DefaultContent />} />

                        {/* Admin Routes */}
                        <Route path="/customer-list" element={<CustomerList />} />
                        <Route path="/guest-list" element={<GuestList />} />
                        <Route path="/add-employee" element={<AddStaffForm />} />
                        <Route path="/manage-employee" element={<ManageEmployee />} />
                        <Route path="/add-amenity" element={<AddAmenityForm />} />
                        <Route path="/manage-amenity" element={<AmenityManage />} />
                        <Route path="/add-room-status" element={<AddRoomStatusForm />} />
                        <Route path="/add-room-type" element={<AddRoomType />} />
                        <Route path="/room-type-manage" element={<RoomTypeManage />} />
                        <Route path="/add-room" element={<AddRoomForm />} />
                        <Route path="/manage-room" element={<RoomManage />} />
                        <Route path="/add-service" element={<AddService />} />
                        <Route path="/service-manage" element={<ServiceManage />} />
                        <Route path="/payment-setting" element={<PaymentMethodManage />} />
                        <Route path="/manage-room-amenity" element={<RoomAmenityManager />} />
                        <Route path="/room-image" element={<RoomImageManager />} />
                        <Route path="/profile" element={<AdminProfile />} />
                        <Route path="/change-password" element={<ChangePassUI />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
};

export default Admin;
