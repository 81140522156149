import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import HomeUI from './HomeUI';
import MyProfileCustomer from './MyProfileCustomer';
import Booking from './Booking';
import RoomDetail from './RoomDetail';
import Service from "./serviceComponents";
import Special_offersComponents from "./special_offersComponents";
import Contact from "./contactComponents";
import RoomHistory from "./RoomHistorycomponent";
import GalleryComponents from "./GalleryComponents";
import FooterUI from "./FooterUI";
import HeaderUI from "./HeaderUI";
import RoomListUI from "./RoomListUI";
import AboutUsUI from "./AboutUsUI";
import BlogUI from "./BlogUI";
import Registration from './RegistrationComponent';

const HomePage = () => {
    const location = useLocation();
    const hideHeaderFooter = ['/login', '/signup', '/newpassword'].some(path =>
        location.pathname.startsWith(path)
    );

    return (
        <>
            {!hideHeaderFooter && <HeaderUI />}
             <Routes>
                <Route path="/" element={<HomeUI />} />
                 <Route path="/aboutUs" element={<AboutUsUI />} />
                 <Route path="/accommodation" element={<RoomListUI />} />
                <Route path="/room-detail/:roomID" element={<RoomDetail />} />
                <Route path="/booking/:roomID" element={<Booking />} />
                <Route path="/services" element={<Service />} />
                <Route path="/special-offers" element={<Special_offersComponents />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/myProfile" element={<MyProfileCustomer />} />
                <Route path="/history" element={<RoomHistory />} />
                <Route path="/gallery" element={<GalleryComponents />} />
                 <Route path="/blog" element={<BlogUI />} />
                 <Route path="/changePass" element={<Registration />} />


             </Routes>
            {!hideHeaderFooter && <FooterUI />}
        </>
    );
};

export default HomePage;
