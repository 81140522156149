import React from 'react';
import { Table, Button } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const columns = [
  { title: 'No.', dataIndex: 'no', key: 'no' },
  { title: 'Booking ID', dataIndex: 'bookingId', key: 'bookingId' },
  { title: 'Fullname', dataIndex: 'fullname', key: 'fullname' },
  { title: 'Email', dataIndex: 'email', key: 'email' },
  { title: 'Phone number', dataIndex: 'phone', key: 'phone' },
  { title: 'Address', dataIndex: 'address', key: 'address' },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <span>
        <Button icon={<EditOutlined />} type="primary" style={{ marginRight: '8px' }} />
        <Button icon={<DeleteOutlined />} type="danger" />
      </span>
    ),
  },
];

const data = [
  { no: 1, bookingId: 'BKG123', fullname: 'John Doe', email: 'john@example.com', phone: '123456789', address: '123 Street' },
  { no: 2, bookingId: 'BKG124', fullname: 'Jane Doe', email: 'jane@example.com', phone: '987654321', address: '456 Avenue' },
  // Thêm dữ liệu khác nếu cần
];

const GuestList = () => (
  <Table columns={columns} dataSource={data} />
);

export default GuestList;
