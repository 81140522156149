import React from 'react';
import { Form, Input, Button, Select, message, DatePicker, Row, Col } from 'antd';
import axios from 'axios';
import moment from 'moment';

const { Option } = Select;

const AddStaffForm = () => {
  const [form] = Form.useForm();

  // Xử lý khi gửi form
  const onFinish = async (values) => {
    try {
      const newStaff = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        password: values.password,
        userName: values.userName,
        position: values.position,
        dateOfHire: values.dateOfHire.format('YYYY-MM-DD'), // Định dạng ngày
      };

      // Gửi yêu cầu API để tạo mới nhân viên
      const response = await axios.post('https://spring-api.azurewebsites.net/api/staff', newStaff);

      message.success('Staff created successfully!');
      form.resetFields();  // Xóa trắng form sau khi thành công
      console.log('Response from server:', response.data);
    } catch (error) {
      console.error('Failed to create staff', error.response ? error.response.data : error.message);
      message.error(error.response?.data?.message || 'Failed to create staff.');
    }
  };

  return (
    <Form
      form={form}
      name="add-staff"
      onFinish={onFinish}
      layout="vertical"
    >
      {/* First Name và Last Name trên cùng một hàng */}
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="First Name" name="firstName" rules={[{ required: true, message: 'Please input first name!' }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: 'Please input last name!' }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      {/* Username và Password trên cùng một hàng */}
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Username" name="userName" rules={[{ required: true, message: 'Please input username!' }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input password!' }]}>
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>

      {/* Email và Phone */}
      <Form.Item label="Email" name="email" rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}>
        <Input />
      </Form.Item>

      <Form.Item label="Phone" name="phone" rules={[{ required: true, message: 'Please input phone number!' }]}>
        <Input />
      </Form.Item>

      {/* Dropdown Position */}
      <Form.Item label="Position" name="position" rules={[{ required: true, message: 'Please select a position!' }]}>
        <Select placeholder="Select position">
          <Option value="Front Desk Staff">Front Desk Staff</Option>
          <Option value="Reservation Agent">Reservation Agent</Option>
        </Select>
      </Form.Item>

      {/* Date of Hire */}
      <Form.Item label="Date of Hire" name="dateOfHire" rules={[{ required: true, message: 'Please select date of hire!' }]}>
        <DatePicker format="YYYY-MM-DD" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Add Staff
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddStaffForm;
