import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, Image, Button, Modal } from 'antd';
import { FaWifi, FaTv, FaConciergeBell, FaSnowflake, FaCocktail, FaMapMarkerAlt, FaLock } from 'react-icons/fa';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './CustomerComponentCSS/RoomDetail.module.css';
import { useTranslation } from "react-i18next";

const RoomDetail = () => {
    const { t } = useTranslation(); // Sử dụng hàm t() để dịch chuỗi
    const { roomID } = useParams();
    const navigate = useNavigate();
    const [room, setRoom] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [images, setImages] = useState([]); // Trạng thái cho danh sách hình ảnh thực tế
    const [mainImage, setMainImage] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);

    // Fetch chi tiết phòng
    useEffect(() => {
        const fetchRoomDetails = async () => {
            try {
                const response = await axios.get(`https://spring-api.azurewebsites.net/api/rooms/${roomID}`);
                if (response.status === 200 && response.data.status === 0) {
                    setRoom(response.data.data);
                } else {
                    setError(t("roomDetail.error"));
                }
            } catch (error) {
                setError(t("roomDetail.error"));
            }
        };

        const fetchRoomImages = async () => {
            try {
                const response = await axios.get(`https://spring-api.azurewebsites.net/api/roomImage/byRoom/${roomID}`);
                if (response.status === 200 && response.data.status === 0) {
                    const fetchedImages = response.data.data.map(img => img.imageUrl); // Lấy danh sách URL hình ảnh
                    setImages(fetchedImages);
                    setMainImage(fetchedImages[0] || ''); // Đặt hình ảnh chính là hình đầu tiên
                } else {
                    toast.error(t("roomDetail.imageError"));
                }
            } catch (error) {
                toast.error(t("roomDetail.imageError"));
            } finally {
                setLoading(false);
            }
        };

        fetchRoomDetails();
        fetchRoomImages();
    }, [roomID, t]);

    const handleBookRoom = () => {
        if (room) {
            navigate(`/booking/${room.roomID}`);
        }
    };

    const renderGallery = () => (
        <Row className={styles.galleryContainer}>
            <Col span={16} className={styles.mainImageContainer}>
                <Image src={mainImage} alt={t("roomDetail.gallery.mainImageAlt")} className={styles.mainImage} />
            </Col>
            <Col span={8} className={styles.thumbnailContainer}>
                <Row gutter={[8, 8]}>
                    {images.map((img, index) => (
                        <Col span={12} key={index}>
                            <Image
                                src={img}
                                alt={t("roomDetail.gallery.thumbnailAlt", { index: index + 1 })}
                                className={styles.thumbnail}
                                onClick={() => setMainImage(img)}
                                preview={false}
                            />
                        </Col>
                    ))}
                </Row>
            </Col>
        </Row>
    );

    const renderRoomAmenities = () => (
        <div className={styles.amenities}>
            <h3>{t("roomDetail.amenities.title")}</h3>
            <ul>
                <li><FaWifi /> {t("roomDetail.amenities.wifi")}</li>
                <li><FaSnowflake /> {t("roomDetail.amenities.airConditioning")}</li>
                <li><FaTv /> {t("roomDetail.amenities.tv")}</li>
                <li><FaConciergeBell /> {t("roomDetail.amenities.roomService")}</li>
                <li><FaCocktail /> {t("roomDetail.amenities.miniBar")}</li>
                <li><FaLock /> {t("roomDetail.amenities.safe")}</li>
            </ul>
        </div>
    );

    const renderNearbyPlaces = () => (
        <div className={styles.nearbyPlaces}>
            <h3>{t("roomDetail.nearbyPlaces.title")}</h3>
            <ul>
                <li><FaMapMarkerAlt /> {t("roomDetail.nearbyPlaces.location1")}</li>
                <li><FaMapMarkerAlt /> {t("roomDetail.nearbyPlaces.location2")}</li>
                <li><FaMapMarkerAlt /> {t("roomDetail.nearbyPlaces.location3")}</li>
            </ul>
        </div>
    );

    if (loading) return <p>{t("roomDetail.loading")}</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className={`container ${styles.roomDetailWrapper}`}>
            {renderGallery()}
            <Row justify="center" gutter={[16, 16]} style={{ marginTop: '20px' }}>
                <Col span={8}>{renderRoomAmenities()}</Col>
                <Col span={8}>{renderNearbyPlaces()}</Col>
                <Col span={8}>
                    {room && (
                        <div className={styles.roomDetailContainer}>
                            <h2>{t("roomDetail.details.roomNumber")} {room.roomNumber}</h2>
                            <p><strong>{t("roomDetail.details.price")}:</strong> {room.price.toLocaleString()} VND</p>
                            <p><strong>{t("roomDetail.details.capacity")}:</strong> {room.capacity} guests</p>
                            <p><strong>{t("roomDetail.details.description")}:</strong> {room.description}</p>
                            <Button
                                type="primary"
                                style={{ marginTop: '15px' }}
                                onClick={handleBookRoom}
                            >
                                {t("roomDetail.details.bookNow")}
                            </Button>
                        </div>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default RoomDetail;
