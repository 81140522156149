import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import AdminHomepage from './AdminComponent/AdminHomepage';
import EmployeeHome from './EmployeeComponent/EmployeeHome';
import HomePage from './CustomerComponent/HomePage';

const RoleBasedHome = () => {
    const role = localStorage.getItem('role'); // Lấy vai trò từ localStorage

    if (!role) {
        return <Navigate to="/login" replace />;
    }

    return (
        <Routes>
            {role === 'admin' && <Route path="/*" element={<AdminHomepage />} />}
            {role === 'employee' && <Route path="/*" element={<EmployeeHome />} />}
            {role === 'customer'&& <Route path="/*" element={<HomePage />} />}
            {role === 'Customer'&& <Route path="/*" element={<HomePage />}/>}
            <Route path="*" element={<Navigate to="/unauthorized" replace />} />
        </Routes>
    );
};

export default RoleBasedHome;
