import React from "react";
import styles from "./CustomerComponentCSS/CarouselUI.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";

const CarouselUI = () => {
    const { t, i18n } = useTranslation();

     const slides = [
        {
            image: "/Images/s1.jpg",
            title: t("slides.0.title"),
            description: t("slides.0.description"),
        },
        {
            image: "/Images/s2.jpg",
            title: t("slides.1.title"),
            description: t("slides.1.description"),
        },
        {
            image: "/Images/s3.webp",
            title: t("slides.2.title"),
            description: t("slides.2.description"),
        },
        {
            image: "/Images/s4.jpg",
            title: t("slides.3.title"),
            description: t("slides.3.description"),
        },
    ];

    return (
        <div id="carouselExample" className={`carousel slide ${styles.carousel}`} data-bs-ride="carousel">
            <div className="carousel-indicators">
                {slides.map((_, index) => (
                    <button
                        key={index}
                        type="button"
                        data-bs-target="#carouselExample"
                        data-bs-slide-to={index}
                        className={index === 0 ? "active" : ""}
                        aria-current={index === 0 ? "true" : undefined}
                        aria-label={`Slide ${index + 1}`}
                    ></button>
                ))}
            </div>
            <div className="carousel-inner">
                {slides.map((slide, index) => (
                    <div
                        key={index}
                        className={`carousel-item ${index === 0 ? "active" : ""} ${styles.carouselItem}`}
                    >
                        <img src={slide.image} className={`d-block w-100 ${styles.image}`} alt={slide.title} />
                        <div className={`carousel-caption ${styles.caption}`}>
                            <h2 className={styles.title}>{slide.title}</h2>
                            <p className={styles.description}>{slide.description}</p>
                            <button className={styles.exploreButton}>{t("exploreNow")}</button>
                        </div>
                    </div>
                ))}
            </div>
            <button
                className={`carousel-control-prev ${styles.control}`}
                type="button"
                data-bs-target="#carouselExample"
                data-bs-slide="prev"
            >
                <span className={`carousel-control-prev-icon ${styles.icon}`} aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button
                className={`carousel-control-next ${styles.control}`}
                type="button"
                data-bs-target="#carouselExample"
                data-bs-slide="next"
            >
                <span className={`carousel-control-next-icon ${styles.icon}`} aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
};

export default CarouselUI;
