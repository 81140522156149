import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import { AiFillPhone, AiFillMail } from 'react-icons/ai';
import styles from './CustomerComponentCSS/FooterUI.module.css';
import { useTranslation } from "react-i18next";  // Import useTranslation hook

const FooterUI = () => {
    const { t, i18n } = useTranslation();

    return (
         <footer className={`${styles.footer} bg-dark text-white py-5`}>
            <div className="container">
                <div className="row">
                    {/* Hotel Info */}
                    <div className="col-md-4 mb-4">
                        <h5 className={styles.footerTitle}>{t('footer.hotelInfo.title')}</h5>
                        <p className={styles.footerText}>{t('footer.hotelInfo.description')}</p>
                    </div>

                    {/* Links */}
                    <div className="col-md-4 mb-4">
                        <h5 className={styles.footerTitle}>{t('footer.quickLinks.title')}</h5>
                        <ul className="list-unstyled">
                            <li><a href="#" className={styles.footerLink}>{t('footer.quickLinks.home')}</a></li>
                            <li><a href="#" className={styles.footerLink}>{t('footer.quickLinks.aboutUs')}</a></li>
                            <li><a href="#" className={styles.footerLink}>{t('footer.quickLinks.services')}</a></li>
                            <li><a href="#" className={styles.footerLink}>{t('footer.quickLinks.contact')}</a></li>
                        </ul>
                    </div>

                    {/* Contact Information */}
                    <div className="col-md-4 mb-4">
                        <h5 className={styles.footerTitle}>{t('footer.contactUs.title')}</h5>
                        <p className={styles.footerText}><AiFillPhone /> {t('footer.contactUs.phone')}</p>
                        <p className={styles.footerText}><AiFillMail /> {t('footer.contactUs.email')}</p>
                    </div>
                </div>

                {/* Social Media Icons */}
                <div className="text-center">
                    <h5 className={styles.footerTitle}>{t('footer.connectWithUs.title')}</h5>
                    <div className="d-flex justify-content-center">
                        <a href="#" className={styles.socialIcon}><FaFacebookF /></a>
                        <a href="#" className={styles.socialIcon}><FaTwitter /></a>
                        <a href="#" className={styles.socialIcon}><FaInstagram /></a>
                        <a href="#" className={styles.socialIcon}><FaLinkedinIn /></a>
                        <a href="#" className={styles.socialIcon}><FaYoutube /></a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default FooterUI;
