import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, Modal} from 'react-bootstrap';
import {jwtDecode} from 'jwt-decode';
import styles from './CustomerComponentCSS/Booking.module.css';
import {toast} from 'react-toastify';
import dayjs from 'dayjs';

const Booking = () => {
    const {roomID} = useParams();
    const navigate = useNavigate();
    const [room, setRoom] = useState(null);
    const [userInfo, setUserInfo] = useState({fullName: '', email: '', phone: ''});
    const [services, setServices] = useState([]);
    const [amenities, setAmenities] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [selectedAmenities, setSelectedAmenities] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
    const [checkInDate, setCheckInDate] = useState('');
    const [checkOutDate, setCheckOutDate] = useState('');
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [showServiceModal, setShowServiceModal] = useState(false);
    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showInvoice, setShowInvoice] = useState(false);
    const [invoiceData, setInvoiceData] = useState(null);
    const today = new Date().toISOString().split('T')[0];
    const [totalRoomPrice, setTotalRoomPrice] = useState(0);
    const [totalServicePrice, setTotalServicePrice] = useState(0);
    const [totalAmenityPrice, setTotalAmenityPrice] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');

    const getUserFromToken = () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                const userId = decodedToken.sub;
                return userId || null;
            } catch (error) {
                console.error("Token decoding failed:", error);
                return null;
            }
        }
        return null;
    };

    const updateTotalAmount = () => {
        setTotalAmount(totalRoomPrice + totalServicePrice + totalAmenityPrice);
    };

    useEffect(() => {
        const fetchRoomDetails = async () => {
            try {
                const roomResponse = await axios.get(`https://spring-api.azurewebsites.net/api/rooms`);
                const roomData = roomResponse.data.data.find(r => r.roomID === parseInt(roomID));
                setRoom(roomData);
                setTotalRoomPrice(roomData.price * calculateDays(checkInDate, checkOutDate));

                const amenitiesResponse = await axios.get(`https://spring-api.azurewebsites.net/api/amenities`);
                setAmenities(amenitiesResponse.data.data);

                const servicesResponse = await axios.get('https://spring-api.azurewebsites.net/api/services');
                setServices(servicesResponse.data.data);
            } catch (error) {
                console.error("Unable to fetch room details.");
            }
        };

        const fetchCustomerDetails = async () => {
            try {
                const userId = getUserFromToken();
                if (userId) {
                    const customerResponse = await axios.get(`https://spring-api.azurewebsites.net/api/customers?userId=${userId}`);
                    const customerData = customerResponse.data.data[0];
                    if (customerData) {
                        setUserInfo({
                            fullName: customerData.fullName,
                            email: customerData.email,
                            phone: customerData.phone,
                        });
                        if (customerData.id) {
                            localStorage.setItem('customerId', customerData.id.toString());
                        } else {
                            console.error("Customer ID not found.");
                        }
                    } else {
                        console.error("Customer data not found for this userId.");
                    }
                } else {
                    toast.error("Please log in to book a room.");
                    navigate('/login');
                }
            } catch (error) {
                console.error("Unable to fetch customer details.", error);
            }
        };

        const fetchPaymentMethods = async () => {
            try {
                const paymentResponse = await axios.get('https://spring-api.azurewebsites.net/api/paymentMethod');
                setPaymentMethods(paymentResponse.data.data);
            } catch (error) {
                console.error("Unable to fetch payment methods.");
            }
        };

        fetchRoomDetails();
        fetchCustomerDetails();
        fetchPaymentMethods();
    }, [roomID, checkInDate, checkOutDate, navigate]);

    useEffect(() => {
        updateTotalAmount();
    }, [totalRoomPrice, totalServicePrice, totalAmenityPrice]);

    const handleServiceSelection = (serviceId, price) => {
        if (selectedServices.includes(serviceId)) {
            setSelectedServices(selectedServices.filter(id => id !== serviceId));
            setTotalServicePrice(totalServicePrice - price);
        } else {
            setSelectedServices([...selectedServices, serviceId]);
            setTotalServicePrice(totalServicePrice + price);
        }
    };

    const handleAmenitySelection = (amenityId, price) => {
        if (selectedAmenities.includes(amenityId)) {
            setSelectedAmenities(selectedAmenities.filter(id => id !== amenityId));
            setTotalAmenityPrice(totalAmenityPrice - price);
        } else {
            setSelectedAmenities([...selectedAmenities, amenityId]);
            setTotalAmenityPrice(totalAmenityPrice + price);
        }
    };

    const calculateDays = (checkIn, checkOut) => {
        if (checkIn && checkOut) {
            const date1 = new Date(checkIn);
            const date2 = new Date(checkOut);
            const timeDifference = Math.abs(date2 - date1);
            return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
        }
        return 0;
    };

    const handleCheckDates = async () => {
        try {
            if (!checkInDate || !checkOutDate) {
                setPopupMessage("Please select check-in and check-out dates.");
                setShowPopup(true);
                return;
            }

            const checkIn = new Date(checkInDate);
            const checkOut = new Date(checkOutDate);
            if (isNaN(checkIn.getTime()) || isNaN(checkOut.getTime()) || checkIn < new Date(today) || checkOut <= checkIn) {
                setPopupMessage("Invalid check-in or check-out date. Please select valid dates.");
                setShowPopup(true);
                return;
            }

            const response = await axios.get(`https://spring-api.azurewebsites.net/api/bookings/check-room-availability`, {
                params: {
                    roomId: room.roomID,
                    checkInDate: checkInDate,
                    checkOutDate: checkOutDate,
                },
            });

            const data = response.data;

            if (data.status === 0) {

                setShowServiceModal(true);
            } else {
                setPopupMessage(data.message || "The selected dates are already booked. Please choose different dates.");
                setShowPopup(true);
            }
        } catch (error) {
            console.error("Error checking room availability:", error);
            setPopupMessage("An error occurred while checking room availability. Please try again.");
            setShowPopup(true);
        }
    };

    const handleServiceSubmit = () => {
        setShowServiceModal(false);
        setShowInvoiceModal(true);
    };

    const handleInvoiceSubmit = () => {
        setShowInvoiceModal(false);
        setShowPaymentModal(true);
    };

    const handleBookingSubmit = async () => {
        try {
            const userId = getUserFromToken();
            const customerId = localStorage.getItem('customerId');

            if (userId && customerId) {
                const bookingResponse = await axios.post('https://spring-api.azurewebsites.net/api/bookings', {
                    customerId: parseInt(customerId),
                    roomId: room.roomID,
                    bookingDate: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
                    checkInDate,
                    checkOutDate,
                    status: selectedPaymentMethod === 1 ? "Pending" : "Pending",
                    numberOfGuests: 2,
                    discount: 10,
                    totalRoomPrice,
                    totalAmenityPrice,
                    totalAmount,
                });

                const bookingData = bookingResponse.data?.data;
                if (!bookingData || !bookingData.bookingId) {
                    toast.error("Booking failed. Please try again.");
                    return;
                }

                const bookingId = bookingData.bookingId;

                if (selectedAmenities.length > 0) {
                    await Promise.all(
                        selectedAmenities.map(async (amenityId) => {
                            await axios.post('https://spring-api.azurewebsites.net/api/bookingAmenities', {
                                bookingId,
                                amenityId,
                            });
                        })
                    );
                }

                let paymentStatus = "Pending";

                if (selectedPaymentMethod === 1) {
                    const paymentResponse = await axios.post('https://spring-api.azurewebsites.net/api/payments', {
                        bookingId,
                        paymentDate: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
                        amount: totalAmount,
                        paymentMethodId: selectedPaymentMethod,
                        paymentStatus,
                        paymentReference: String(bookingId),
                    });

                    const paymentData = paymentResponse.data?.data;
                    if (!paymentData || !paymentData.paymentId) {
                        toast.error("Payment creation failed. Please try again.");
                        return;
                    }

                    toast.success("Booking created successfully. Please complete payment at the front desk.");
                    navigate('/history');
                    return;
                }

                if (selectedPaymentMethod === 2) {
                    try {
                        const orderInfo = "thanh toán hóa đơn phòng";
                        const momoResponse = await axios.post(
                            `https://spring-api.azurewebsites.net/api/momo/create`,
                            null,
                            {
                                params: {
                                    bookingId,
                                    amount: totalAmount,
                                    orderInfo,
                                },
                            }
                        );

                        if (momoResponse.data.status === "success" && momoResponse.data.payUrl) {
                            window.location.href = momoResponse.data.payUrl;
                        } else {
                            toast.error("Unable to create MoMo payment. Missing payment URL.");
                        }
                    } catch (error) {
                        console.error("Error occurred during MoMo payment request:", error);
                        toast.error("Error occurred during MoMo payment request. Please try again.");
                    }
                    return;
                }

                if (selectedPaymentMethod === 3) {
                    try {
                        const vnpayResponse = await axios.get(`https://spring-api.azurewebsites.net/api/payment/vnpay`, {
                            params: {
                                bookingId,
                                amount: totalAmount,
                            },
                        });

                        const responseData = vnpayResponse.data;
                        if (!responseData) {
                            toast.error("VNPay response is invalid. Please try again.");
                            return;
                        }

                        if (responseData.status === "OK" && responseData.url) {
                            window.location.href = responseData.url;
                            return;
                        } else if (responseData.status === "success" && responseData.qrUrl) {
                            const qrUrl = responseData.qrUrl;
                            setShowInvoice(true);
                            setInvoiceData({qrUrl});

                            setTimeout(async () => {
                                await axios.put(`https://spring-api.azurewebsites.net/api/bookings/${bookingId}`, {
                                    status: "Paid",
                                });

                                await axios.post('https://spring-api.azurewebsites.net/api/payments', {
                                    bookingId,
                                    paymentDate: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
                                    amount: totalAmount,
                                    paymentMethodId: selectedPaymentMethod,
                                    paymentStatus: "Paid",
                                    paymentReference: String(bookingId),
                                });

                                setShowInvoice(false);
                                navigate('/history');
                            }, 5000);
                        } else {
                            toast.error("Unable to process VNPay payment.");
                        }
                    } catch (error) {
                        console.error("Error occurred during VNPay payment request:", error);
                        toast.error("Error occurred during VNPay payment request. Please try again.");
                    }
                    return;
                }
            } else {
                toast.error("Cannot proceed with booking. Please make sure you are logged in and your customer ID is available.");
                navigate('/login');
            }
        } catch (error) {
            console.error("Error during booking, payment, or invoice creation:", error);
            toast.error("Unable to complete the booking process. Please try again.");
        }
    };


    return (
        <div className={`container ${styles.container}`}>
            <h1 className={styles.headerText}>Book Your Stay</h1>
            {room ? (
                <div className={`row ${styles.roomDetails}`}>
                    <div className="col-md-6">
                        <h2 className={styles.sectionTitle}>Room Information</h2>
                        <h2>{`Room ${room.roomNumber} - ${room.roomView}`}</h2>
                        <img
                            src={`/Images/room${1}.jpg`}
                            alt={room.roomName}
                            className={styles.roomImage}
                        />
                        <p><strong>Price:</strong> {room.price ? room.price.toLocaleString() : "N/A"} VND per night</p>
                    </div>
                    <div className="col-md-6">
                        <h2 className={styles.sectionTitle}>Guest Information</h2>
                        <form>
                            <div className="form-group">
                                <label htmlFor="fullName">Full Name</label>
                                <input type="text" className="form-control" id="fullName" value={userInfo.fullName}
                                       readOnly/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input type="email" className="form-control" id="email" value={userInfo.email}
                                       readOnly/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone">Phone</label>
                                <input type="text" className="form-control" id="phone" value={userInfo.phone} readOnly/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="checkInDate">Check-in Date</label>
                                <input
                                    type="date"
                                    id="checkInDate"
                                    className="form-control"
                                    value={checkInDate}
                                    onChange={(e) => setCheckInDate(e.target.value)}
                                    required
                                    min={today}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="checkOutDate">Check-out Date</label>
                                <input
                                    type="date"
                                    id="checkOutDate"
                                    className="form-control"
                                    value={checkOutDate}
                                    onChange={(e) => setCheckOutDate(e.target.value)}
                                    required
                                    min={checkInDate || today}
                                />
                            </div>
                            <button
                                type="button"
                                onClick={handleCheckDates}
                                className={`btn ${styles.checkDatesBtn}`}
                                style={{backgroundColor: '#ff6600', color: 'white'}}
                            >
                                Next
                            </button>
                        </form>
                    </div>
                </div>
            ) : (
                <p>Loading room details...</p>
            )}

            {/* Modal for notifications */}
            <Modal show={showPopup} onHide={() => setShowPopup(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Notification</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{popupMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowPopup(false)}>OK</Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showServiceModal} onHide={() => setShowServiceModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Select Services and Room Amenities</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <h3>Available Services</h3>
                            {services.map(service => (
                                <div key={service.serviceId} className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`service-${service.serviceId}`}
                                        value={service.serviceId}
                                        onChange={() => handleServiceSelection(service.serviceId, service.price)}
                                    />
                                    <label className="form-check-label" htmlFor={`service-${service.serviceId}`}>
                                        {service.serviceName} - {service.price.toLocaleString()} VND
                                    </label>
                                </div>
                            ))}
                        </div>

                        <div className="col-md-6">
                            <h3>Room Amenities</h3>
                            {amenities.map(amenity => (
                                <div key={amenity.amenityId} className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`amenity-${amenity.amenityId}`}
                                        value={amenity.amenityId}
                                        onChange={() => handleAmenitySelection(amenity.amenityId, amenity.price)}
                                        checked={selectedAmenities.includes(amenity.amenityId)}
                                    />
                                    <label className="form-check-label" htmlFor={`amenity-${amenity.amenity_Id}`}>
                                        {amenity.amenityName} - {amenity.price.toLocaleString()} VND
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowServiceModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleServiceSubmit}>
                        Next: Invoice
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showInvoiceModal} onHide={() => setShowInvoiceModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className={styles.text}><strong>Total Room Price:</strong> {totalRoomPrice.toLocaleString()} VND
                    </p>
                    <p className={styles.text}><strong>Total Service
                        Price:</strong> {totalServicePrice.toLocaleString()} VND</p>
                    <p className={styles.text}><strong>Total Amenity
                        Price:</strong> {totalAmenityPrice.toLocaleString()} VND</p>
                    <p className={styles.text}><strong>Total Amount:</strong> {totalAmount.toLocaleString()} VND</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowInvoiceModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleInvoiceSubmit}>
                        Proceed to Payment
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showPaymentModal} onHide={() => setShowPaymentModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Select Payment Method</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {paymentMethods.map(method => (
                        <div key={method.paymentMethodId} className="form-check">
                            <input
                                type="radio"
                                className="form-check-input"
                                id={`payment-${method.paymentMethodId}`}
                                name="paymentMethod"
                                value={method.paymentMethodId}
                                onChange={() => setSelectedPaymentMethod(method.paymentMethodId)}
                            />
                            <label className="form-check-label" htmlFor={`payment-${method.paymentMethodId}`}>
                                {method.paymentType}
                            </label>
                        </div>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowPaymentModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleBookingSubmit}>
                        Confirm Booking
                    </Button>
                </Modal.Footer>
            </Modal>

            {showInvoice && invoiceData && invoiceData.qrUrl && (
                <div className={`container ${styles.invoiceContainer}`}>
                    <h3>Payment Successful</h3>
                    <p>Scan the QR code below to view your invoice:</p>
                    <img src={invoiceData.qrUrl} alt="QR Code" style={{width: "300px", height: "300px"}}/>
                    <p>Redirecting to your booking history in 5 seconds...</p>
                </div>
            )}
        </div>
    );
};

export default Booking;
