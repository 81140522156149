import React, { useEffect, useState } from 'react';
import styles from './CustomerComponentCSS/AboutUsUI.module.css';
import { useTranslation } from "react-i18next";

const AboutUsUI = () => {
    const { t, i18n } = useTranslation();

    // Dữ liệu gốc với `labelKey`
    const rawStats = [
        { labelKey: 'rooms', value: 120 },
        { labelKey: 'happyGuests', value: 200 },
        { labelKey: 'awards', value: 15 },
        { labelKey: 'yearsInService', value: 100 },
    ];

    // State chứa stats với nhãn được dịch
    const [translatedStats, setTranslatedStats] = useState(
        rawStats.map(stat => ({
            ...stat,
            label: t(stat.labelKey), // Dịch ban đầu
        }))
    );

    const [currentStats, setCurrentStats] = useState(
        rawStats.map((stat) => ({ ...stat, currentValue: 0 }))
    );

    // Theo dõi thay đổi ngôn ngữ và cập nhật `translatedStats`
    useEffect(() => {
        setTranslatedStats(
            rawStats.map(stat => ({
                ...stat,
                label: t(stat.labelKey), // Dịch lại khi ngôn ngữ thay đổi
            }))
        );
    }, [i18n.language]); // Theo dõi `i18n.language` để cập nhật khi ngôn ngữ thay đổi

    // Hiệu ứng để tăng giá trị số liệu
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentStats((prevStats) =>
                prevStats.map((stat) => {
                    if (stat.currentValue < stat.value) {
                        return { ...stat, currentValue: stat.currentValue + 1 };
                    }
                    return stat;
                })
            );
        }, 20);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className={`${styles.aboutUs} ${styles.fadeIn}`}>
            {/* Section 1: Welcome */}
            <div className="container my-5">
                <div className="row">
                    <div className="col-md-6">
                        <h2 className={`${styles.typingEffect} mb-4`}>{t('welcome_title')}</h2>
                        <p className="text-muted">{t('welcome_text')}</p>
                        <p className="text-muted">{t('mission_text')}</p>
                    </div>
                    <div className="col-md-6">
                        <img
                            src="/Images/s7.jpg"
                            alt="Riviera Hotel"
                            className={`${styles.img} ${styles.imgHover}`}
                        />
                    </div>
                </div>
            </div>

            {/* Section 2: Stats */}
            <div className={`container text-center py-5 ${styles.stats}`}>
                <h3 className="mb-4">{t('our_achievements')}</h3>
                <div className="row">
                    {currentStats.map((stat, index) => (
                        <div className="col-6 col-md-3" key={index}>
                            <h3 className={styles.statValue}>{stat.currentValue}</h3>
                            <p className="text-uppercase text-muted">
                                {translatedStats[index]?.label || stat.labelKey}
                            </p>
                        </div>
                    ))}
                </div>
            </div>

            {/* Section 3: Our Story */}
            <div className={`container my-5 ${styles.storySection}`}>
                <div className="row">
                    <div className="col-md-6">
                        <img
                            src="/Images/s5.png"
                            alt="Our Story"
                            className={`${styles.img} ${styles.imgHover}`}
                        />
                    </div>
                    <div className="col-md-6">
                        <h3>{t('our_story_title')}</h3>
                        <p className={styles.caps}>{t('our_story_text_1')}</p>
                        <p className={styles.caps}>{t('our_story_text_2')}</p>
                    </div>
                </div>
            </div>

            {/* Section 4: Testimonials */}
            <div className={`container text-center py-5 ${styles.testimonials}`}>
                <h3 className="mb-4">{t('guest_testimonials')}</h3>
                <div className="row">
                    <div className="col-md-4">
                        <div className={styles.testimonialCard}>
                            <p className={styles.caps}>{t('testimonial_1')}</p>
                            <strong>- John Doe</strong>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className={styles.testimonialCard}>
                            <p className={styles.caps}>{t('testimonial_2')}</p>
                            <strong>- Jane Smith</strong>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className={styles.testimonialCard}>
                            <p className={styles.caps}>{t('testimonial_3')}</p>
                            <strong>- Alex Nguyen</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUsUI;
