import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from "./main/resources/templates/Component/App";
import CarouselUI from "./main/resources/templates/Component/CustomerComponent/CarouselUI";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
        {/*<CarouselUI/>*/}
    </React.StrictMode>
);
