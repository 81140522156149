import React from 'react';
import styles from './CustomerComponentCSS/service.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useTranslation } from "react-i18next";

function Service() {
     const { t, i18n } = useTranslation();

    return (
        <div className={styles.container}>
            <h1 className="text-center mt-4">
                {t('professionalService')}
            </h1>
            <p className="text-center mb-5">
                {t('serviceDescription')}
            </p>

            {/* Restaurant Section */}
            <div className="row mb-5">
                <div className="col-md-6">
                    <div className={styles['image-container']}>
                        <img src="serv1.jpg" alt="Restaurant" className="img-fluid" />
                        <img src="servh1.jpg" alt="Restaurant Hover" className={`img-fluid ${styles['hover-img']}`} />
                    </div>
                </div>
                <div className="col-md-6">
                    <h2>{t('restaurant')}</h2>
                    <p>{t('restaurantDescription')}</p>
                </div>
            </div>

            {/* Sky Lounge Section */}
            <div className="row mb-5">
                <div className="col-md-6">
                    <h2>{t('skyLounge')}</h2>
                    <p>{t('skyLoungeDescription')}</p>
                </div>
                <div className="col-md-6">
                    <div className={styles['image-container']}>
                        <img src="serv2.jpg" alt="Sky Lounge" className="img-fluid" />
                        <img src="servh2.jpg" alt="Sky Lounge Hover" className={`img-fluid ${styles['hover-img']}`} />
                    </div>
                </div>
            </div>

            {/* BB Care Spa Section */}
            <div className="row mb-5">
                <div className="col-md-6">
                    <div className={styles['image-container']}>
                        <img src="serv3.jpg" alt="BB Care Spa" className="img-fluid" />
                        <img src="servh3.jpg" alt="BB Care Spa Hover" className={`img-fluid ${styles['hover-img']}`} />
                    </div>
                </div>
                <div className="col-md-6">
                    <h2>{t('bbCareSpa')}</h2>
                    <p>{t('bbCareSpaDescription')}</p>
                </div>
            </div>

            {/* Terrace Coffee Section */}
            <div className="row mb-5">
                <div className="col-md-6">
                    <h2>{t('terraceCoffee')}</h2>
                    <p>{t('terraceCoffeeDescription')}</p>
                </div>
                <div className="col-md-6">
                    <div className={styles['image-container']}>
                        <img src="serv4.jpg" alt="Terrace Coffee" className="img-fluid" />
                        <img src="servh4.jpg" alt="Terrace Coffee Hover" className={`img-fluid ${styles['hover-img']}`} />
                    </div>
                </div>
            </div>

            {/* BiBi Express Section */}
            <div className="row mb-5">
                <div className="col-md-6">
                    <div className={styles['image-container']}>
                        <img src="serv5.jpg" alt="BiBi Express" className="img-fluid" />
                        <img src="servh5.jpg" alt="BiBi Express Hover" className={`img-fluid ${styles['hover-img']}`} />
                    </div>
                </div>
                <div className="col-md-6">
                    <h2>{t('bibiExpress')}</h2>
                    <p>{t('bibiExpressDescription')}</p>
                </div>
            </div>

            {/* Gym & Fitness Section */}
            <div className="row mb-5">
                <div className="col-md-6">
                    <h2>{t('gymFitness')}</h2>
                    <p>{t('gymFitnessDescription')}</p>
                </div>
                <div className="col-md-6">
                    <div className={styles['image-container']}>
                        <img src="serv5.jpg" alt="Gym & Fitness" className="img-fluid" />
                        <img src="servh6.jpg" alt="Gym & Fitness Hover" className={`img-fluid ${styles['hover-img']}`} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;
