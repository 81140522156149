import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Table, Pagination } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import debounce from 'lodash.debounce';
import styles from './EmployeeCSS/CustomerList.module.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEdit, FaTrash } from 'react-icons/fa';



const CustomerList = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [totalPages, setTotalPages] = useState(1);
    const usersPerPage = 10;
    const [errors, setErrors] = useState({});

    // Lấy Data từ db lên table.
    const fetchUsers = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(`https://spring-api.azurewebsites.net/api/customers?page=${currentPage - 1}&keyword=${encodeURIComponent(searchTerm)}`);
            if (!response.ok) {
                throw new Error(`Error fetching data: ${response.status}`);
            }

            const data = await response.json();
            if (data && Array.isArray(data.data)) {
                setUsers(data.data);
                setTotalPages(data.totalPages);
            } else {
                setUsers([]);
            }
        } catch (error) {
            console.error("Error fetching users:", error);
        } finally {
            setLoading(false);
        }
    }, [currentPage, searchTerm]);


    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

// Click đóng form
    const handleClose = () => {
        setShowModal(false);
        setSelectedUser(null);
    };

// Show form update - create
    const handleShow = (user) => {
        setSelectedUser(user);
        setShowModal(true);
    };

    const validateForm = (formValues) => {
        const newErrors = {};
        if (!formValues.firstName) {
            newErrors.firstName = "First Name is required.";
        }
        if (!formValues.lastName) {
            newErrors.lastName = "Last Name is required.";
        }
        if (!formValues.userName) {
            newErrors.userName = "Username is required.";
        }
        if (!formValues.email) {
            newErrors.email = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
            newErrors.email = "Email is invalid.";
        }
        if (!formValues.phone) {
            newErrors.phone = "Phone Number is required.";
        } else if (!/^\d+$/.test(formValues.phone)) { // Kiểm tra nếu chứa ký tự không phải là số
            newErrors.phone = "Phone Number must contain only numbers.";
        } else if (formValues.phone.length !== 10) { // Kiểm tra nếu không phải 10 chữ số
            newErrors.phone = "Phone Number must be exactly 10 digits.";
        }

        if (!formValues.password) {
            newErrors.password = "Password is required.";
        } else if (formValues.password.length < 8 || formValues.password.length > 20) {
            newErrors.password = "Password must be between 8 and 20 characters.";
        }
        if (!formValues.address) {
            newErrors.address = "Address is required.";
        }
        return newErrors;
    };


// Yêu cầu thêm - cập nhật thông tin
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const formValues = Object.fromEntries(formData.entries());

        const formErrors = validateForm(formValues);
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return; // Prevent submission if there are errors
        }

        try {
            let response;
            if (selectedUser) {
                // Update user
                response = await fetch(`https://spring-api.azurewebsites.net/api/customers/${selectedUser.id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formValues),
                });

                if (!response.ok) {
                    throw new Error(`Error updating user: ${response.status}`);
                }

                const updatedUser = await response.json();
                toast.success("Update Successfully!");
            } else {
                // Create new user
                response = await fetch('https://spring-api.azurewebsites.net/api/customers', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formValues),
                });

                if (!response.ok) {
                    throw new Error(`Error creating user: ${response.status}`);
                }

                const newUser = await response.json();
                toast.success("Create Successfully!");
            }

        } catch (error) {
            console.error("Error processing user data:", error);
            toast.error(`Operation failed: ${error.message}`);
        } finally {
            handleClose(); // Đóng modal sau khi xử lý xong
            fetchUsers(); // Gọi lại hàm fetchUsers sau khi đóng modal
        }
    };






    const handleExportPDF = async () => {
        try {
            // Gọi API để xuất PDF
            const response = await fetch('https://spring-api.azurewebsites.net/api/customers/export/pdf', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`Error exporting PDF: ${response.status}`);
            }

            // Mở đường dẫn để tải xuống tệp PDF
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'DataUser.pdf'); // Tên tệp PDF
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link); // Xóa link sau khi tải xong

            // Thông báo xuất PDF thành công
            toast.success("Export PDF file successfully!");
        } catch (error) {
            console.error("Error exporting PDF:", error);
            toast.error(`Export PDF failed: ${error.message}`);
        }
    };


    const handleDelete = async (id) => {
        try {
            const response = await fetch(`https://spring-api.azurewebsites.net/api/customers/${id}`, {
                method: 'DELETE',
            });

            if (!response.ok) {
                throw new Error(`Error deleting user: ${response.status}`);
            }

            // Xóa người dùng khỏi danh sách
            const updatedUsers = users.filter((user) => user.id !== id);
            setUsers(updatedUsers);

            // Thông báo xóa thành công
            toast.success("Delete successfully!");
        } catch (error) {
            console.error("Error deleting user:", error);
            toast.error(`Delete failed!: ${error.message}`);
        }
    };

    // Pagination
    const handlePaginationClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleFirstPage = () => {
        setCurrentPage(1);
    };

    const handleLastPage = () => {
        setCurrentPage(totalPages);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to page 1 when searching
    };

    const debouncedSearch = useCallback(debounce(handleSearch, 0), []);

    const handleExportExcel = async () => {
        try {
            // Gọi API để xuất Excel
            const response = await fetch('https://spring-api.azurewebsites.net/api/users/export/excel', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`Error exporting Excel: ${response.status}`);
            }

            // Mở đường dẫn để tải xuống tệp Excel
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'DataUser.xlsx'); // Tên tệp Excel
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link); // Xóa link sau khi tải xong

            // Thông báo xuất Excel thành công
            toast.success("Export Excel file successfully!");
        } catch (error) {
            console.error("Error exporting Excel:", error);
            toast.error(`Export Excel file failed!: ${error.message}`);
        }
    };

    const handleImportExcel = async (e) => {
        const file = e.target.files[0];

        if (!file) return;

        const formData = new FormData();
        formData.append('file', file); // Gắn tệp vào FormData với key 'file'

        try {
            const response = await fetch('https://spring-api.azurewebsites.net/api/customers/import/excel', {
                method: 'POST',
                body: formData, // Gửi FormData thay vì JSON
            });

            if (!response.ok) {
                const errorData = await response.json(); // Lấy dữ liệu lỗi từ phản hồi
                throw new Error(errorData.message || 'Lỗi không xác định khi nhập Excel.'); // Ném lỗi với thông điệp cụ thể
            }

            await fetchUsers(); // Cập nhật danh sách người dùng
            toast.success('Import successfully!'); // Thông báo thành công
        } catch (error) {
            console.error('Error importing Excel:', error);
            toast.error(`Import failed: ${error.message}`); // Thông báo lỗi
        }
    };


    return (
        <div  className={styles.container}>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div  className="input-group w-50">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search customer..."
                        onChange={debouncedSearch}
                    />
                    <Button variant="primary" onClick={handleSearch}>Search</Button>
                </div>
                <Button className={styles.addUserButton} onClick={() => handleShow(null)}>
                    +Add New User
                </Button>
            </div>

            {loading ? (
                <p>Loading users...</p>
            ) : (
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>No.</th> {/* Thêm cột ID */}
                        <th>Last Name</th>
                        <th>First Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Address</th>
                        <th>Loyalty Points</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {users.map((user) => (
                        <tr key={user.id}>
                            <td>{user.id}</td> {/* Hiển thị ID */}
                            <td>{user.lastName}</td>
                            <td>{user.firstName}</td>
                            <td>{user.email}</td>
                            <td>{user.phone}</td>
                            <td>{user.address}</td>
                            <td>{user.loyaltyPoints}</td>
                            <td>
                                <Button variant="success" onClick={() => handleShow(user)} className={styles.iconButton}>
                                    <FaEdit />
                                </Button>
                                <Button variant="danger" onClick={() => handleDelete(user.id)} className={styles.iconButton}>
                                    <FaTrash />
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            )}

            <div className={`${styles.actionContainer} d-flex justify-content-between mb-3`}>
                <div>
                    <Button
                        variant="success"
                        className={styles.buttonSpacing}
                        onClick={handleExportExcel}
                    >
                        Excel
                    </Button>


                    <Button variant="warning" className={styles.buttonSpacing}>
                        <input
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleImportExcel}
                            style={{ display: 'none' }} // Ẩn nút input thực tế
                            id="importExcelInput"
                        />
                        <label htmlFor="importExcelInput" >
                            Import
                        </label>
                    </Button>
                    <Button variant="danger" onClick={handleExportPDF}>PDF</Button>
                </div>

                <div>
                    <Pagination>
                        <Pagination.First onClick={handleFirstPage} />
                        <Pagination.Prev onClick={handlePreviousPage} />
                        {[...Array(totalPages)].map((_, index) => (
                            <Pagination.Item
                                key={index + 1}
                                active={index + 1 === currentPage}
                                onClick={() => handlePaginationClick(index + 1)}
                            >
                                {index + 1}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next onClick={handleNextPage} />
                        <Pagination.Last onClick={handleLastPage} />
                    </Pagination>
                </div>
            </div>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedUser ? 'Edit User' : 'Add User'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleFormSubmit} className={styles.userForm}>
                        {selectedUser && (
                            <div className="mb-3">
                                <label htmlFor="id" className="form-label">ID:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="id"
                                    name="id"
                                    defaultValue={selectedUser.id}
                                    readOnly
                                />
                            </div>
                        )}

                        <div className="row mb-3">
                            <div className="col-md-6">
                                <label htmlFor="firstName" className="form-label">First Name:</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                                    id="firstName"
                                    name="firstName"
                                    defaultValue={selectedUser?.firstName || ''}
                                />
                                {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="lastName" className="form-label">Last Name:</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                                    id="lastName"
                                    name="lastName"
                                    defaultValue={selectedUser?.lastName || ''}
                                />
                                {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-6">
                                <label htmlFor="userName" className="form-label">Username:</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.userName ? 'is-invalid' : ''}`}
                                    id="userName"
                                    name="userName"
                                    defaultValue={selectedUser?.userName || ''}
                                />
                                {errors.userName && <div className="invalid-feedback">{errors.userName}</div>}
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="password" className="form-label">Password:</label>
                                <input
                                    type="password"
                                    className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                    id="password"
                                    name="password"
                                    defaultValue={selectedUser?.password || ''}
                                />
                                {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-6">
                                <label htmlFor="email" className="form-label">Email:</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                    id="email"
                                    name="email"
                                    defaultValue={selectedUser?.email || ''}
                                />
                                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="phone" className="form-label">Phone Number:</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                                    id="phone"
                                    name="phone"
                                    defaultValue={selectedUser?.phone || ''}
                                />
                                {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="address" className="form-label">Address:</label>
                            <input
                                type="text"
                                className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                                id="address"
                                name="address"
                                defaultValue={selectedUser?.address || ''}
                            />
                            {errors.address && <div className="invalid-feedback">{errors.address}</div>}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="loyaltyPoints" className="form-label">Loyalty Points:</label>
                            <input
                                type="number"
                                className="form-control"
                                id="loyaltyPoints"
                                name="loyaltyPoints"
                                defaultValue={selectedUser?.loyaltyPoints || 0}
                            />
                        </div>

                        <Button variant="primary" type="submit">
                            {selectedUser ? 'Update User' : 'Add User'}
                        </Button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CustomerList;
