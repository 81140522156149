import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Chart, registerables } from 'chart.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './AdminCSS/Dashboard.module.css'; // Import module CSS

Chart.register(...registerables);

const Dashboard = () => {
    const revenueChartRef = useRef(null);
    const cumulativeRevenueChartRef = useRef(null);
    const topServicesChartRef = useRef(null); // Reference for top services chart

    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [confirmedTotalAmount, setConfirmedTotalAmount] = useState(0); // Total confirmed revenue
    const [confirmedTotalAmountsData, setConfirmedTotalAmountsData] = useState([]); // Data for confirmed revenue chart
    const [totalAmount, setTotalAmount] = useState(0);
    const [revenueData, setRevenueData] = useState([]);
    const [totalGuests, setTotalGuests] = useState(0);
    const [customers, setCustomers] = useState([]);
    const [totalIdSum, setTotalIdSum] = useState(0);
    const [topServices, setTopServices] = useState([]); // State for top services

    // Sample data for top services
    const fetchTopServices = async () => {
        // Simulated data for the top 10 services
        const services = [
            { name: 'Room Booking', count: 120 },
            { name: 'Spa Services', count: 95 },
            { name: 'Restaurant Reservation', count: 85 },
            { name: 'Airport Transfer', count: 70 },
            { name: 'Tour Packages', count: 60 },
            { name: 'Gym Access', count: 50 },
            { name: 'Laundry Service', count: 45 },
            { name: 'Event Booking', count: 30 },
            { name: 'Car Rental', count: 25 },
            { name: 'Swimming Pool Access', count: 20 },
        ];
        setTopServices(services);
    };

    // Fetch customer data
    const fetchCustomers = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://spring-api.azurewebsites.net/api/customers');
            setCustomers(response.data.data);

            const totalIdSum = response.data.data.reduce((sum, customer) => sum + customer.id, 0);
            setTotalIdSum(totalIdSum);

            console.log('Customer data loaded successfully:', response.data.data);
        } catch (error) {
            console.error('Error fetching customers:', error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch bookings data
    const fetchBookings = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://spring-api.azurewebsites.net/api/bookings/check');
            const bookingsData = response.data.data;

            setBookings(bookingsData); // Keep all bookings

            // Calculate total amount and total guests from confirmed bookings
            const confirmedBookings = bookingsData.filter(booking => booking.status !== 'Pending' && booking.status !== 'cancelled');
            const total = confirmedBookings.reduce((sum, booking) => sum + booking.totalAmount, 0);
            const guests = confirmedBookings.reduce((sum, booking) => sum + booking.numberOfGuests, 0);
            setTotalAmount(total);
            setTotalGuests(guests);

            // Calculate confirmed total amount
            const confirmedTotal = confirmedBookings.reduce((sum, booking) => sum + booking.totalAmount, 0);
            setConfirmedTotalAmount(confirmedTotal);
            setConfirmedTotalAmountsData(confirmedBookings.map(booking => booking.totalAmount));

            console.log('Bookings data loaded successfully:', bookingsData);
        } catch (error) {
            console.error('Error fetching bookings:', error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch revenue data
    const fetchRevenueData = async () => {
        try {
            const [dailyRevenueResponse, monthlyRevenueResponse] = await Promise.all([
                axios.get('https://spring-api.azurewebsites.net/api/bookings/revenue/day'),
                axios.get('https://spring-api.azurewebsites.net/api/bookings/revenue/month')
            ]);
            const dailyRevenueData = dailyRevenueResponse.data.data.map(item => item.totalAmount);
            const monthlyRevenueData = monthlyRevenueResponse.data.data.map(item => item.totalAmount);

            setRevenueData([...dailyRevenueData, ...monthlyRevenueData]);
            console.log('Revenue data loaded successfully.');
        } catch (error) {
            console.error('Error fetching revenue data:', error);
        }
    };

    useEffect(() => {
        fetchBookings();
        fetchRevenueData();
        fetchCustomers();
        fetchTopServices(); // Fetch top services data
    }, []);

    useEffect(() => {
        // Initialize revenue chart for confirmed bookings
        if (revenueChartRef.current) {
            const revenueChart = new Chart(revenueChartRef.current, {
                type: 'line',
                data: {
                    labels: Array.from({ length: confirmedTotalAmountsData.length }, (_, i) => `Booked ${i + 1}`),
                    datasets: [{
                        label: 'Revenue Bookings',
                        data: confirmedTotalAmountsData,
                        fill: true,
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        tension: 0.4
                    }]
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true,
                            suggestedMax: Math.max(...confirmedTotalAmountsData, 1) * 1.1
                        }
                    }
                }
            });

            return () => revenueChart.destroy();
        }
    }, [confirmedTotalAmountsData]);

    useEffect(() => {
        // Calculate cumulative revenue amounts
        const cumulativeAmounts = confirmedTotalAmountsData.reduce((acc, value, index) => {
            const cumulativeTotal = (acc[index - 1] || 0) + value;
            acc.push(cumulativeTotal);
            return acc;
        }, []);

        // Initialize cumulative revenue chart
        if (cumulativeRevenueChartRef.current) {
            const cumulativeRevenueChart = new Chart(cumulativeRevenueChartRef.current, {
                type: 'line',
                data: {
                    labels: Array.from({ length: cumulativeAmounts.length }, (_, i) => `Total ${i + 1}`),
                    datasets: [{
                        label: 'Total Revenue Bookings',
                        data: cumulativeAmounts,
                        fill: true,
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        borderColor: 'rgba(255, 99, 132, 1)',
                        tension: 0.4
                    }]
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true,
                            suggestedMax: Math.max(...cumulativeAmounts, 1) * 1.1
                        }
                    }
                }
            });

            return () => cumulativeRevenueChart.destroy();
        }
    }, [confirmedTotalAmountsData]);

    useEffect(() => {
        // Initialize top services chart
        if (topServicesChartRef.current) {
            const topServicesChart = new Chart(topServicesChartRef.current, {
                type: 'bar', // Horizontal bar chart
                data: {
                    labels: topServices.map(service => service.name),
                    datasets: [{
                        label: 'Usage Count',
                        data: topServices.map(service => service.count),
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        borderColor: 'blue',
                        borderWidth: 2
                    }]
                },
                options: {
                    indexAxis: 'y', // This makes it a horizontal bar chart
                    scales: {
                        x: {
                            beginAtZero: true
                        }
                    }
                }
            });

            return () => topServicesChart.destroy();
        }
    }, [topServices]);

    return (
        <div className={styles.container}>
            <div className="row mb-4 text-center">
                <div className="col-md-3">
                    <div className={styles.card + " p-3"}>
                        <h5 className={styles.cardTitle}>Total Bookings</h5>
                        <p className="fs-4"><strong>{bookings.length} Bookings</strong></p>
                        <small className={styles.textDanger}>Pending: {bookings.filter(booking => booking.status === 'Pending').length}</small>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className={styles.card + " p-3"}>
                        <h5 className={styles.cardTitle}>Total Revenue</h5>
                        <p className="fs-4"><strong>{totalAmount.toLocaleString()} VNĐ</strong></p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className={styles.card + " p-3"}>
                        <h5 className={styles.cardTitle}>Total Guests</h5>
                        <p className="fs-4"><strong>{totalGuests} Guests</strong></p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className={styles.card + " p-3"}>
                        <h5 className={styles.cardTitle}>Total Customers</h5>
                        <p className="fs-4"><strong>{customers.length} Customers</strong></p>
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-6">
                    <canvas ref={revenueChartRef} />
                </div>
                <div className="col-md-6">
                    <canvas ref={cumulativeRevenueChartRef} />
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-12">
                    <h5 className={styles.cardTitle}>Top 10 Most Services</h5>
                    <canvas ref={topServicesChartRef} />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
