import React, { useEffect, useState } from 'react';
import { Table, Button, Input, Modal, Form, message, Pagination } from 'antd';
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import axios from 'axios';

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [editingCustomer, setEditingCustomer] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  // Fetch customers from API
  const fetchCustomers = async (page = 0, keyword = '') => {
    setLoading(true);
    try {
      const response = await axios.get('https://spring-api.azurewebsites.net/api/customers', {
        params: { page, keyword },
      });
      const { data, currentPage, totalPages } = response.data; // Adjusted to match API response
      setCustomers(data.map((customer) => ({ ...customer, key: customer.id }))); // Add `key` for table
      setCurrentPage(currentPage);
      setTotalPages(totalPages);
    } catch (error) {
      message.error('Error fetching customers: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Delete customer
  const deleteCustomer = async (id) => {
    try {
      await axios.delete(`https://spring-api.azurewebsites.net/api/customers/${id}`);
      message.success('Customer deleted successfully');
      fetchCustomers(currentPage, searchKeyword); // Refresh the list
    } catch (error) {
      message.error('Error deleting customer: ' + error.message);
    }
  };

  // Update customer
  const updateCustomer = async (values) => {
    try {
      await axios.put(`https://spring-api.azurewebsites.net/api/customers/${editingCustomer.id}`, values);
      message.success('Customer updated successfully');
      setIsModalVisible(false);
      fetchCustomers(currentPage, searchKeyword); // Refresh the list
    } catch (error) {
      message.error('Error updating customer: ' + error.message);
    }
  };

  // Handle Search
  const handleSearch = () => {
    fetchCustomers(0, searchKeyword);
  };

  // Handle Page Change
  const handlePageChange = (page) => {
    fetchCustomers(page - 1, searchKeyword);
  };

  // Table columns
  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Username', dataIndex: 'userName', key: 'userName' },
    { title: 'Full Name', dataIndex: 'fullName', key: 'fullName' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Phone', dataIndex: 'phone', key: 'phone' },
    { title: 'Address', dataIndex: 'address', key: 'address' },
    { title: 'Loyalty Points', dataIndex: 'loyaltyPoints', key: 'loyaltyPoints' },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
          <span>
          <Button
              icon={<EditOutlined />}
              type="primary"
              style={{ marginRight: '8px' }}
              onClick={() => {
                setEditingCustomer(record);
                setIsModalVisible(true);
              }}
          />
          <Button
              icon={<DeleteOutlined />}
              type="danger"
              onClick={() => {
                Modal.confirm({
                  title: 'Are you sure you want to delete this customer?',
                  okText: 'Yes',
                  okType: 'danger',
                  cancelText: 'No',
                  onOk: () => deleteCustomer(record.id),
                });
              }}
          />
        </span>
      ),
    },
  ];

  useEffect(() => {
    fetchCustomers();
  }, []);

  return (
      <div>
        <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'space-between' }}>
          <Input
              placeholder="Search customers..."
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
              style={{ width: '300px' }}
              prefix={<SearchOutlined />}
          />
          <Button type="primary" onClick={handleSearch}>
            Search
          </Button>
        </div>
        <Table
            columns={columns}
            dataSource={customers}
            loading={loading}
            pagination={false} // Using custom pagination
        />
        <Pagination
            current={currentPage + 1}
            total={totalPages * 10}
            onChange={handlePageChange}
            style={{ marginTop: '16px', textAlign: 'right' }}
        />

        {/* Modal for editing customer */}
        <Modal
            title="Edit Customer"
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            footer={null}
        >
          <Form
              initialValues={editingCustomer}
              onFinish={updateCustomer}
              layout="vertical"
          >
            <Form.Item label="First Name" name="firstName" rules={[{ required: true, message: 'Please enter the first name' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: 'Please enter the last name' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please enter a valid email' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Phone" name="phone" rules={[{ required: true, message: 'Please enter the phone number' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Address" name="address" rules={[{ required: true, message: 'Please enter the address' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Loyalty Points" name="loyaltyPoints">
              <Input type="number" />
            </Form.Item>
            <div style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </Form>
        </Modal>
      </div>
  );
};

export default CustomerList;
