import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Sidebar from './Slidebar_E';
import Dashboard from './DashboardComponents';
import Amenity from './AmnenityComponents';
import ServiceList from './ServiceComponents';
import RoomList from './RoomList';
import UserList from './CustomerListComponent';
import HeaderComponent_E from "./headerComponent";
import Invoice from "./InvoiceComponents";
import PaymentSuccess from "./PaymentSuccess";
import RoomListOffline from "./RoomListOffline";
import BookingOffline from "./BookingOffline";
import BookingSA from "./BookingSA";
import MyProfileStaff from "./MyProfileStaff";
import CreateRoomUI from "./CreateRoomUI";
import Registration from "./RegistrationStaff";

function EmployeeHome() {
    const location = useLocation();
    const isInvoicePage = location.pathname === '/invoice';
    const isBookingRoomPage = location.pathname === '/bookingRoom';
    const isMyProfile = location.pathname === '/myProfile';
    const isCreateRoom = location.pathname === '/createRoom';


    return (
        <div>
            {/* Hide Header and Sidebar on /invoice and /bookingRoom */}
            {!(isInvoicePage || isBookingRoomPage) && <HeaderComponent_E />}
            <div style={{ display: 'flex' }}>
                {!(isInvoicePage || isBookingRoomPage|| isMyProfile || isCreateRoom) && <Sidebar />}
                <div style={{ flexGrow: 1, padding: '20px' }}>
                    <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/customer-list" element={<UserList />} />
                        <Route path="/room-list-offline" element={<RoomListOffline />} />
                        <Route path="/room-list" element={<RoomList />} />
                        <Route path="/amenity-list" element={<Amenity />} />
                        <Route path="/service-list" element={<ServiceList />} />
                        <Route path="/myProfile" element={<MyProfileStaff />} />
                        <Route path="/invoice" element={<Invoice />} />
                        <Route path="/paymentsuccess" element={<PaymentSuccess />} />
                        <Route path="/bookingRoom" element={<BookingOffline />} />
                        <Route path="/bookingSA" element={<BookingSA />} />
                        <Route path="/createRoom" element={<CreateRoomUI />} />
                        <Route path="/changePass" element={<Registration />} />


                        <Route path="*" element={<div>404 - Page Not Found</div>} />
                    </Routes>
                </div>
            </div>
        </div>
    );
}

export default EmployeeHome;
