import React, { useState } from 'react';
import styles from './CustomerComponentCSS/ForgotPassword_InsertEmail.module.css'; // Import as styles
import axios from 'axios';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next"; // Import CSS for toast

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [showOtpModal, setShowOtpModal] = useState(false);
    const { t, i18n } = useTranslation();

    const handleConfirm = async () => {
        setEmailError(''); // Clear previous errors

        try {
            const response = await axios.post(
                'https://spring-api.azurewebsites.net/api/change-pass/forgot-password',
                new URLSearchParams({ email }), // Convert email to form data
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded', // Define data format
                    },
                }
            );

            // Check the response from the API
            if (response.status === 200) {
                const message = response.data.message; // Get the message from API
                console.log(message); // Log the message for debugging
                setShowOtpModal(true); // Show OTP modal on success
                toast.success('A reset email has been sent!'); // Show success toast
            } else {
                setEmailError('Failed to send reset email. Please try again.');
                toast.error('Failed to send reset email. Please try again.'); // Show error toast
            }
        } catch (error) {
            console.error('Error:', error);
            if (error.response) {
                setEmailError(`Error: ${error.response.data.message || 'An error occurred. Please try again.'}`);
                toast.error(`Error: ${error.response.data.message || 'An error occurred. Please try again.'}`); // Show error toast
            } else if (error.request) {
                setEmailError('No response received from the server. Please check your connection.');
                toast.error('No response received from the server. Please check your connection.'); // Show error toast
            } else {
                setEmailError('An error occurred while setting up the request.');
                toast.error('An error occurred while setting up the request.'); // Show error toast
            }
        }
    };

    return (
        <div>
            <ToastContainer /> {/* Add ToastContainer here */}
            <div
                className="modal fade"
                id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className={`row ${styles.modalHead}`}>
                            <div className="col-11">
                                <h4 className="modal-title" id="staticBackdropLabel">Forgot Password</h4>
                            </div>
                            <div className="col-1">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <hr />
                        </div>
                        <div className="row">
                            <form onSubmit={(e) => e.preventDefault()}> {/* Prevent page reload */}
                                <h6 className="text-center">Please enter your email to retrieve your password</h6>
                                <div className="d-flex justify-content-center">
                                    <input
                                        className={`w3-input ${styles.inputField}`}
                                        type="email"
                                        placeholder="Enter your email here"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                {emailError && <label className={`text-danger ${styles.emailError}`}>{emailError}</label>}
                                <div className={`row ${styles.footer}`}>
                                    <div className={`col-6 d-flex justify-content-center ${styles.footerButton}`}>
                                        <button type="button" className="btn" data-bs-dismiss="modal">Cancel</button>
                                    </div>
                                    <div className={`col-6 d-flex justify-content-center ${styles.footerButton}`}>
                                        <button type="button" className="btn" onClick={handleConfirm}>Confirm</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* NewPassword Modal */}
        </div>
    );
}

export default ForgotPassword;
