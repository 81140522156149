import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Thêm useNavigate
import styles from './EmployeeCSS/header.module.css';

// Header
const HeaderComponent_E = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(true); // Trạng thái đăng nhập
    const navigate = useNavigate(); // Dùng để chuyển hướng

    const handleLogout = () => {
        localStorage.removeItem('token'); // Xóa token khỏi localStorage
        setIsLoggedIn(false); // Cập nhật trạng thái đăng nhập
        navigate('/login'); // Chuyển hướng về trang chủ hoặc trang login
    };

    return (
        <header className={styles.header}>
            <span>Welcome back..</span>
            <div className={styles.headerButtons}>
                <button className={styles.btnLogout} onClick={handleLogout}>Log out</button>
            </div>
        </header>
    );
};

export default HeaderComponent_E;
