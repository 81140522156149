import React, { useEffect, useState } from 'react';
import { Table, Input, Button, message, Modal, Form, InputNumber, Select } from 'antd';
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Option } = Select;

const ServiceManage = () => {
  const [services, setServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [pageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm(); // Ant Design form instance
  const [selectedService, setSelectedService] = useState(null); // Service to edit

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://spring-api.azurewebsites.net/api/services');
      const { status, data } = response.data;
      if (status === 0) {
        setServices(data);
        setFilteredServices(data);
      } else {
        message.error('Failed to fetch services');
      }
    } catch (error) {
      message.error('Failed to fetch services');
    }
    setLoading(false);
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = services.filter(
      (service) =>
        service.serviceName.toLowerCase().includes(value) ||
        service.price.toString().includes(value) ||
        service.status.toLowerCase().includes(value)
    );
    setFilteredServices(filtered);
    setCurrentPage(1);
  };

  const handleDelete = async (serviceId) => {
    try {
      await axios.delete(`https://spring-api.azurewebsites.net/api/services/${serviceId}`);
      message.success('Service deleted successfully');
      fetchServices();
    } catch (error) {
      message.error('Failed to delete service');
    }
  };

  // Open edit modal and set form values
  const handleEdit = (record) => {
    setSelectedService(record);
    form.setFieldsValue({
      serviceName: record.serviceName,
      description: record.description,
      price: record.price,
      status: record.status,
      serviceDuration: record.serviceDuration,
    });
    setIsModalVisible(true);
  };

  // Handle modal submission to update the service
  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      const updatedService = { ...selectedService, ...values };
      
      await axios.put(`https://spring-api.azurewebsites.net/api/services/${selectedService.serviceId}`, updatedService);
      message.success('Service updated successfully!');
      fetchServices(); // Reload the service list after update
      setIsModalVisible(false);
    } catch (error) {
      message.error('Failed to update service');
    }
  };

  const columns = [
    {
      title: 'No.',
      key: 'no',
      render: (text, record, index) => index + 1 + (currentPage - 1) * pageSize,
    },
    {
      title: 'Service Name',
      dataIndex: 'serviceName',
      key: 'serviceName',
      sorter: (a, b) => a.serviceName.localeCompare(b.serviceName),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text) => (text.length > 100 ? `${text.substring(0, 100)}...` : text),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      sorter: (a, b) => a.price - b.price,
      render: (price) => `$${price.toFixed(2)}`,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Service Duration (min)',
      dataIndex: 'serviceDuration',
      key: 'serviceDuration',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <>
          <Button icon={<EditOutlined />} type="primary" onClick={() => handleEdit(record)} style={{ marginRight: 8 }} />
          <Button icon={<DeleteOutlined />} type="danger" onClick={() => handleDelete(record.serviceId)} />
        </>
      ),
    },
  ];

  return (
    <div>
      <h2>Service Manage</h2>

      {/* Search bar */}
      <Input
        placeholder="Search by service name, price, or status"
        prefix={<SearchOutlined />}
        value={searchTerm}
        onChange={handleSearch}
        style={{ marginBottom: 16, width: 300 }}
      />

      {/* Total records and total pages */}
      <p>Total Records: {filteredServices.length}</p>
      <p>Total Pages: {Math.ceil(filteredServices.length / pageSize)}</p>

      {/* Table */}
      <Table
        columns={columns}
        dataSource={filteredServices.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
        rowKey="serviceId"
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: filteredServices.length,
          onChange: (page) => setCurrentPage(page),
        }}
        loading={loading}
      />

      {/* Modal for editing service */}
      <Modal
        title="Edit Service"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSave}
        okText="Update"
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Service Name"
            name="serviceName"
            rules={[{ required: true, message: 'Please input the service name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
          >
            <Input.TextArea rows={4} />
          </Form.Item>

          <Form.Item
            label="Price"
            name="price"
            rules={[{ required: true, message: 'Please input the price!' }]}
          >
            <InputNumber min={0} step={0.01} style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            label="Status"
            name="status"
            rules={[{ required: true, message: 'Please select the status!' }]}
          >
            <Select placeholder="Select status">
              <Option value="Active">Active</Option>
              <Option value="Inactive">Inactive</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Service Duration (min)"
            name="serviceDuration"
            rules={[{ required: true, message: 'Please input the service duration!' }]}
          >
            <InputNumber min={1} style={{ width: '100%' }} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ServiceManage;
