import React from 'react';
import { Navigate } from 'react-router-dom';
import Admin from './AdminComponent/AdminHomepage';
import Employee from './EmployeeComponent/EmployeeHome';
import HomePage from './CustomerComponent/HomePage';

const RoleBasedHome = () => {
    const role = localStorage.getItem('role'); // Lấy role từ localStorage

    switch (role) {
        case 'admin':
            return <Admin />; // Giao diện Admin
        case 'employee':
            return <Employee />; // Giao diện Employee
        case 'customer':
            return <HomePage />; // Giao diện Khách hàng
        default:
            return <Navigate to="/unauthorized" replace />; // Chuyển hướng nếu không có quyền
    }
};

export default RoleBasedHome;
