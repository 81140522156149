import React, {useEffect, useState} from 'react';
import {Button, Form, Input, InputNumber, message, Progress, Select} from 'antd';
import {v4 as uuidv4} from 'uuid';
import axios from 'axios';
import Swal from "sweetalert2";

const {Option} = Select;

const AddRoomForm = () => {
    const [form] = Form.useForm();
    const [roomTypes, setRoomTypes] = useState([]);
    const [roomStatuses, setRoomStatuses] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);

    // Fetch room types and statuses
    useEffect(() => {
        const fetchRoomData = async () => {
            try {
                const [roomTypeRes, roomStatusRes] = await Promise.all([
                    axios.get('https://spring-api.azurewebsites.net/api/roomType'),
                    axios.get('https://spring-api.azurewebsites.net/api/roomStatus'),
                ]);
                setRoomTypes(roomTypeRes.data.data);
                setRoomStatuses(roomStatusRes.data.data);
            } catch (error) {
                message.error('Failed to load room data.');
            }
        };

        fetchRoomData();
    }, []);

    // Handle file selection
    const handleFileChange = (event) => {
        const files = event.target.files;
        const renamedFiles = Array.from(files).map((file) => {
            const newFileName = `${uuidv4()}_${file.name.replace(/[^a-zA-Z0-9.-]/g, '')}`;
            return new File([file], newFileName, {type: file.type});
        });
        setSelectedFiles(renamedFiles);
    };

    // Upload files and track progress
    const uploadFiles = async () => {
        if (selectedFiles.length === 0) {
            return {uploadedFiles: [], errors: []};
        }

        const formDataImages = new FormData();
        selectedFiles.forEach((file) => formDataImages.append('files', file));

        try {
            const response = await axios.post('https://spring-api.azurewebsites.net/api/upload/images', formDataImages, {
                headers: {'Content-Type': 'multipart/form-data'},
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                },
            });
            return {uploadedFiles: response.data.uploadedFiles || [], errors: response.data.errors || []};
        } catch (error) {
            console.error('Error uploading files:', error);
            return {uploadedFiles: [], errors: ['Failed to upload files']};
        }
    };


    const onFinish = async (values) => {
        try {
            // Chuẩn bị dữ liệu phòng
            const roomData = {
                roomID: null,
                roomTypeID: roomTypes.find((type) => type.typeName === values.roomTypeName)?.roomTypeId,
                roomStatusID: values.roomStatusID,
                roomNumber: values.roomNumber,
                price: values.price,
                capacity: values.capacity,
                description: values.description,
                floor: values.floor,
                roomView: values.roomView,
            };

            if (!roomData.roomTypeID) {
                message.error('Invalid room type selected.');
                return;
            }

            // Tạo FormData cho multipart/form-data
            const formData = new FormData();
            formData.append(
                'room',
                new Blob([JSON.stringify(roomData)], { type: 'application/json' }) // Đính kèm thông tin phòng dưới dạng Blob
            );

            selectedFiles.forEach((file) => {
                formData.append('images', file); // Đính kèm từng hình ảnh
            });

            // Gửi yêu cầu đến API
            const apiResponse = await fetch(
                'https://spring-api.azurewebsites.net/api/rooms/createMultiple',
                {
                    method: 'POST',
                    body: formData,
                }
            );

            if (!apiResponse.ok) {
                throw new Error('Failed to create room. Please try again.');
            }

            const result = await apiResponse.json();

            // Xóa trắng form nhập liệu
            form.resetFields();
            setSelectedFiles([]);

            // Hiển thị thông báo nhỏ gọn khi thành công (nếu muốn)
            message.success('Room created successfully!');

        } catch (error) {
            console.error('Error creating room with images:', error);
            Swal.fire('Error', 'Something went wrong: ' + error.message, 'error');
        }
    };





    return (
        <Form form={form} name="add-room" onFinish={onFinish} layout="vertical">
            <Form.Item label="Room Number" name="roomNumber"
                       rules={[{required: true, message: 'Please input room number!'}]}>
                <InputNumber min={1}/>
            </Form.Item>

            <Form.Item label="Room Type" name="roomTypeName"
                       rules={[{required: true, message: 'Please select a room type!'}]}>
                <Select placeholder="Select room type">
                    {roomTypes.map((roomType) => (
                        <Option key={roomType.roomTypeId} value={roomType.typeName}>
                            {roomType.typeName}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item label="Room Status" name="roomStatusID"
                       rules={[{required: true, message: 'Please select room status!'}]}>
                <Select placeholder="Select room status">
                    {roomStatuses.map((roomStatus) => (
                        <Option key={roomStatus.roomStatusId} value={roomStatus.roomStatusId}>
                            {roomStatus.statusName}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item label="Price" name="price" rules={[{required: true, message: 'Please input room price!'}]}>
                <InputNumber min={0} step={0.01}/>
            </Form.Item>

            <Form.Item label="Capacity" name="capacity"
                       rules={[{required: true, message: 'Please input room capacity!'}]}>
                <InputNumber min={1}/>
            </Form.Item>

            <Form.Item label="Floor" name="floor" rules={[{required: true, message: 'Please input floor number!'}]}>
                <InputNumber min={1}/>
            </Form.Item>

            <Form.Item label="Room View" name="roomView" rules={[{required: true, message: 'Please input room view!'}]}>
                <Input/>
            </Form.Item>

            <Form.Item label="Description" name="description"
                       rules={[{required: true, message: 'Please input description!'}]}>
                <Input.TextArea rows={4}/>
            </Form.Item>

            <Form.Item label="Room Images">
                <input type="file" multiple onChange={handleFileChange}/>
                {uploadProgress > 0 && <Progress percent={uploadProgress}/>}
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Add Room
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AddRoomForm;
