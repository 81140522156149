import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Chart, registerables } from 'chart.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './EmployeeCSS/Dashboard.module.css'; // Điều chỉnh theo vị trí của CSS module
import { FaClipboardList, FaMoneyBillWave, FaUsers, FaUserFriends } from "react-icons/fa"; // Import các icon từ React Icons
import { Spinner } from 'react-bootstrap'; // Import Spinner

// Register các module của Chart.js để sử dụng
Chart.register(...registerables);

const Dashboard = () => {
    const revenueChartRef = useRef(null);
    const cumulativeRevenueChartRef = useRef(null);
    const topRoomsChartRef = useRef(null);
    const customerBookingChartRef = useRef(null);

    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [confirmedTotalAmount, setConfirmedTotalAmount] = useState(0);
    const [confirmedTotalAmountsData, setConfirmedTotalAmountsData] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [revenueData, setRevenueData] = useState([]);
    const [totalGuests, setTotalGuests] = useState(0);
    const [customers, setCustomers] = useState([]);
    const [totalIdSum, setTotalIdSum] = useState(0);
    const [topRooms, setTopRooms] = useState([]);
    const [animatedBookings, setAnimatedBookings] = useState(0);
    const [animatedRevenue, setAnimatedRevenue] = useState(0);
    const [animatedGuests, setAnimatedGuests] = useState(0);
    const [animatedCustomers, setAnimatedCustomers] = useState(0);
    const [customerBookingCounts, setCustomerBookingCounts] = useState([]);

    // Helper function to animate numbers
    const animateValue = (start, end, duration, callback) => {
        const range = end - start;
        const startTime = Date.now();
        const endTime = startTime + duration;

        const step = () => {
            const now = Date.now();
            const progress = Math.min((now - startTime) / duration, 1);
            const value = Math.round(start + range * progress);

            callback(value);

            if (now < endTime) {
                requestAnimationFrame(step);
            }
        };

        requestAnimationFrame(step);
    };

    useEffect(() => {
        animateValue(animatedBookings, bookings.length, 4000, setAnimatedBookings);
        animateValue(animatedRevenue, totalAmount, 4000, setAnimatedRevenue);
        animateValue(animatedGuests, totalGuests, 4000, setAnimatedGuests);
        animateValue(animatedCustomers, customers.length, 1000, setAnimatedCustomers);
    }, [bookings.length, totalAmount, totalGuests, customers.length]);

    // Fetch customer data
    const fetchCustomers = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://spring-api.azurewebsites.net/api/customers');
            setCustomers(response.data.data);
            const totalIdSum = response.data.data.reduce((sum, customer) => sum + customer.id, 0);
            setTotalIdSum(totalIdSum);
        } catch (error) {
            console.error('Error fetching customers:', error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch bookings data
    const fetchBookings = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://spring-api.azurewebsites.net/api/bookings/check');
            const bookingsData = response.data.data;

            setBookings(bookingsData);

            // Filter out bookings that are not 'Pending' or 'Cancelled'
            const confirmedBookings = bookingsData.filter(booking => booking.status !== 'Pending' && booking.status !== 'Cancelled');

            const total = confirmedBookings.reduce((sum, booking) => sum + booking.totalAmount, 0);
            const guests = confirmedBookings.reduce((sum, booking) => sum + booking.numberOfGuests, 0);
            setTotalAmount(total);
            setTotalGuests(guests);

            const confirmedTotal = confirmedBookings.reduce((sum, booking) => sum + booking.totalAmount, 0);
            setConfirmedTotalAmount(confirmedTotal);

            setConfirmedTotalAmountsData(confirmedBookings.map(booking => booking.totalAmount));

            // Tính toán số lần đặt chỗ cho từng khách hàng (cho biểu đồ tròn)
            const customerBookingCount = {};
            bookingsData.forEach(booking => {
                const customerId = booking.customerId;
                customerBookingCount[customerId] = (customerBookingCount[customerId] || 0) + 1;
            });

            setCustomerBookingCounts(
                Object.entries(customerBookingCount).map(([customerId, count]) => ({
                    customerId,
                    count,
                }))
            );
        } catch (error) {
            console.error('Error fetching bookings:', error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch revenue data
    const fetchRevenueData = async () => {
        setLoading(true);
        try {
            const [dailyRevenueResponse, monthlyRevenueResponse] = await Promise.all([
                axios.get('https://spring-api.azurewebsites.net/api/bookings/revenue/day'),
                axios.get('https://spring-api.azurewebsites.net/api/bookings/revenue/month')
            ]);
            const dailyRevenueData = dailyRevenueResponse.data.data.map(item => item.totalAmount);
            const monthlyRevenueData = monthlyRevenueResponse.data.data.map(item => item.totalAmount);
            setRevenueData([...dailyRevenueData, ...monthlyRevenueData]);
        } catch (error) {
            console.error('Error fetching revenue data:', error);
        } finally {
            setLoading(false);
        }
    };

    // Tính số lần booking cho mỗi roomId
    const calculateBookingCounts = (bookings) => {
        const roomCount = {};
        bookings.forEach(booking => {
            const { roomId } = booking;
            roomCount[roomId] = (roomCount[roomId] || 0) + 1;
        });
        return roomCount;
    };

    // Fetch top rooms data (tính tổng số lần đặt cho từng phòng)
    const fetchTopRooms = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://spring-api.azurewebsites.net/api/bookings/check');
            // Filter out bookings that are not 'Pending' or 'Cancelled'
            const confirmedBookings = response.data.data.filter(booking => booking.status !== 'Pending' && booking.status !== 'Cancelled');
            const roomCount = calculateBookingCounts(confirmedBookings);
            const topRoomsData = Object.entries(roomCount)
                .map(([roomId, count]) => ({ roomId, count }))
                .sort((a, b) => b.count - a.count)
                .slice(0, 5); // Get top 5 rooms

            setTopRooms(topRoomsData);
        } catch (error) {
            console.error('Error fetching top rooms:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBookings();
        fetchRevenueData();
        fetchCustomers();
        fetchTopRooms();
    }, []);

    useEffect(() => {
        if (revenueChartRef.current) {
            const revenueChart = new Chart(revenueChartRef.current, {
                type: 'line',
                data: {
                    labels: Array.from({ length: confirmedTotalAmountsData.length }, (_, i) => `Booked ${i + 1}`),
                    datasets: [{
                        label: 'Revenue Bookings',
                        data: confirmedTotalAmountsData,
                        fill: true,
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        tension: 0.4
                    }]
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true,
                            suggestedMax: Math.max(...confirmedTotalAmountsData, 1) * 1.1
                        }
                    }
                }
            });

            return () => revenueChart.destroy();
        }
    }, [confirmedTotalAmountsData]);

    useEffect(() => {
        if (customerBookingChartRef.current) {
            const customerBookingChart = new Chart(customerBookingChartRef.current, {
                type: 'pie',
                data: {
                    labels: customerBookingCounts.map(item => `Guest ${item.customerId}`),
                    datasets: [
                        {
                            data: customerBookingCounts.map(item => item.count),
                            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#FF9F40'],
                        },
                    ],
                },
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                    const customerId = tooltipItem.label.split(' ')[1];
                                    return `Guest ${customerId}: ${tooltipItem.raw} bookings`;
                                },
                            },
                        },
                    },
                },
            });

            return () => customerBookingChart.destroy();
        }
    }, [customerBookingCounts]);

    useEffect(() => {
        const cumulativeAmounts = confirmedTotalAmountsData.reduce((acc, value, index) => {
            const cumulativeTotal = (acc[index - 1] || 0) + value;
            acc.push(cumulativeTotal);
            return acc;
        }, []);

        if (cumulativeRevenueChartRef.current) {
            const cumulativeRevenueChart = new Chart(cumulativeRevenueChartRef.current, {
                type: 'line',
                data: {
                    labels: Array.from({ length: cumulativeAmounts.length }, (_, i) => `Total ${i + 1}`),
                    datasets: [{
                        label: 'Total Revenue Bookings',
                        data: cumulativeAmounts,
                        fill: true,
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        borderColor: 'rgba(255, 99, 132, 1)',
                        tension: 0.4
                    }]
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true,
                            suggestedMax: Math.max(...cumulativeAmounts, 1) * 1.1
                        }
                    }
                }
            });

            return () => cumulativeRevenueChart.destroy();
        }
    }, [confirmedTotalAmountsData]);

    useEffect(() => {
        if (topRoomsChartRef.current) {
            const topRoomsChart = new Chart(topRoomsChartRef.current, {
                type: 'bar',
                data: {
                    labels: topRooms.map(room => `Room ${room.roomId}`),
                    datasets: [{
                        label: 'Bookings',
                        data: topRooms.map(room => room.count),
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        borderColor: 'blue',
                        borderWidth: 2
                    }]
                },
                options: {
                    indexAxis: 'y',
                    scales: {
                        x: {
                            beginAtZero: true
                        }
                    }
                }
            });

            return () => topRoomsChart.destroy();
        }
    }, [topRooms]);

    return (
        <div className={styles.container}>
            {loading && ( // Show loading spinner
                <div className="text-center">
                    <Spinner animation="border" />
                </div>
            )}
            <div className="row mb-4 text-center">
                <div className="col-md-3">
                    <div className={`${styles.card} p-3`}>
                        <div className={styles.iconWrapper}>
                            <FaClipboardList style={{fontSize: 35, color: '#f39c12'}} className={styles.icon} />
                        </div>
                        <h5 className={styles.cardTitle}>Total Bookings</h5>
                        <p className="fs-4">
                            <strong>{animatedBookings} Bookings</strong>
                        </p>
                        <small className={styles.textDanger}>
                            Pending: {bookings.filter(booking => booking.status === "Pending").length}
                        </small>
                    </div>
                </div>

                <div className="col-md-3">
                    <div className={`${styles.card} p-3`}>
                        <div className={styles.iconWrapper}>
                            <FaMoneyBillWave style={{fontSize: 35, color: '#2ecc71'}} className={styles.icon} />
                        </div>
                        <h5 className={styles.cardTitle}>Total Revenue</h5>
                        <p className="fs-4">
                            <strong>{animatedRevenue.toLocaleString()} VNĐ</strong>
                        </p>
                    </div>
                </div>

                <div className="col-md-3">
                    <div className={`${styles.card} p-3`}>
                        <div className={styles.iconWrapper}>
                            <FaUsers style={{fontSize: 35, color: '#3498db'}} className={styles.icon} />
                        </div>
                        <h5 className={styles.cardTitle}>Total Guests</h5>
                        <p className="fs-4">
                            <strong>{animatedGuests} Guests</strong>
                        </p>
                    </div>
                </div>

                <div className="col-md-3">
                    <div className={`${styles.card} p-3`}>
                        <div className={styles.iconWrapper}>
                            <FaUserFriends style={{fontSize: 35, color: '#e74c3c'}} className={styles.icon} />
                        </div>
                        <h5 className={styles.cardTitle}>Total Customers</h5>
                        <p className="fs-4">
                            <strong>{animatedCustomers} Customers</strong>
                        </p>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <canvas ref={revenueChartRef}></canvas>
                </div>
                <div className="col-md-6">
                    <canvas ref={cumulativeRevenueChartRef}></canvas>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <canvas className={styles.topRoom} ref={topRoomsChartRef}></canvas>
                </div>
                <div className="col-md-4 ">
                    <canvas style={{marginLeft:90, marginTop:20}}  ref={customerBookingChartRef}></canvas> {/* Biểu đồ tròn */}
                </div>

            </div>
        </div>
    );
};


export default Dashboard;
