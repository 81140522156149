import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

function PaymentSuccess() {
    return (
        <h1>
            Payment Successfully.!
        </h1>
    )
}

export default PaymentSuccess;
