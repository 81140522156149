import React, { useEffect, useState } from 'react';
import { Modal, Button, Table, Pagination, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './EmployeeCSS/Amenity.module.css'; // Your custom CSS file
import axios from 'axios';
import Swal from 'sweetalert2';
import { FaEdit, FaTrash } from 'react-icons/fa';

const Amenity = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedAmenity, setSelectedAmenity] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [amenities, setAmenities] = useState([]);
    const [loading, setLoading] = useState(false); // State for loading
    const [errors, setErrors] = useState({}); // State for validation errors
    const categories = ['Entertainment', 'Food and Drink', 'Internet', 'Swimming Pool', 'Bar', 'Fitness Center', 'Spa', 'Electric Product'];

    useEffect(() => {
        fetchAmenities();
    }, [currentPage]);

    const fetchAmenities = async () => {
        setLoading(true); // Set loading to true
        try {
            const response = await axios.get(`https://spring-api.azurewebsites.net/api/amenities?page=${currentPage}`);
            setAmenities(response.data.data);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('Error fetching amenities:', error);
            Swal.fire('Error', 'Failed to fetch amenities.', 'error');
        } finally {
            setLoading(false); // Set loading to false
        }
    };

    const handleClose = () => {
        setShowModal(false);
        setSelectedAmenity(null);
        setErrors({}); // Reset errors when closing modal
    };

    const handleShow = (amenity) => {
        setSelectedAmenity(amenity);
        setShowModal(true);
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`https://spring-api.azurewebsites.net/api/amenities/${id}`);
                    fetchAmenities();
                    Swal.fire('Deleted!', 'Amenity deleted successfully!', 'success');
                } catch (error) {
                    console.error('Error deleting amenity:', error);
                    Swal.fire('Error', 'Failed to delete amenity.', 'error');
                }
            }
        });
    };

    const validateForm = (amenityData) => {
        const newErrors = {};
        if (!amenityData.amenityName) {
            newErrors.amenityName = "Amenity Name is required.";
        }
        if (!amenityData.description) {
            newErrors.description = "Description is required.";
        }
        if (!amenityData.price) {
            newErrors.price = "Price is required.";
        } else if (isNaN(amenityData.price)) { // Kiểm tra nếu giá không phải là số
            newErrors.price = "Price must be a valid number.";
        } else if (amenityData.price <= 0) { // Kiểm tra nếu giá là số âm hoặc bằng 0
            newErrors.price = "Price must be a positive number.";
        }
        return newErrors;

    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        const amenityData = {
            id: selectedAmenity?.amenityId,
            amenityName: formData.get('amenityName'),
            description: formData.get('description'),
            price: parseFloat(formData.get('price')),
            category: formData.get('category'),
            free: formData.get('free') === 'yes',
        };

        // Validate form data
        const formErrors = validateForm(amenityData);
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return; // Prevent submission if there are errors
        }

        try {
            if (selectedAmenity) {
                const response = await axios.put(`https://spring-api.azurewebsites.net/api/amenities/${amenityData.id}`, amenityData);
                console.log('Amenity updated successfully:', response.data);
                Swal.fire('Updated!', 'Amenity updated successfully!', 'success');
            } else {
                const response = await axios.post('https://spring-api.azurewebsites.net/api/amenities', amenityData);
                console.log('Amenity created successfully:', response.data);
                Swal.fire('Created!', 'Amenity created successfully!', 'success');
            }

            fetchAmenities();
            handleClose();
        } catch (error) {
            console.error('Error saving amenity:', error.response ? error.response.data : error.message);
            Swal.fire('Error', 'Failed to save amenity.', 'error');
        }
    };

    const handlePaginationClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className={styles.container}>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div style={{ marginTop: 13 }} className="input-group w-50">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search amenity..."
                    />
                    <Button variant="primary">Search</Button>
                </div>
                <Button className={styles.addAmenityButton} onClick={() => handleShow(null)}>
                    + Add New Amenity
                </Button>
            </div>

            {loading ? ( // Show loading spinner
                <div className="text-center">
                    <Spinner animation="border" />
                </div>
            ) : (
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>No.</th>
                        <th>Amenity Name</th>
                        <th>Description</th>
                        <th>Free</th>
                        <th>Price</th>
                        <th>Category</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {amenities.length === 0 ? (
                        <tr>
                            <td colSpan="7" className="text-center">No amenities found.</td>
                        </tr>
                    ) : (
                        amenities.map((amenity) => (
                            <tr key={amenity.amenityId}>
                                <td>{amenity.amenityId}</td>
                                <td>{amenity.amenityName}</td>
                                <td>{amenity.description}</td>
                                <td>{amenity.free ? 'Yes' : 'No'}</td>
                                <td>{amenity.price}</td>
                                <td>{amenity.category}</td>
                                <td>
                                    <Button variant="success" onClick={() => handleShow(amenity)} className={styles.iconButton}>
                                        <FaEdit />
                                    </Button>
                                    <Button variant="danger" onClick={() => handleDelete(amenity.amenityId)} className={styles.iconButton}>
                                        <FaTrash />
                                    </Button>
                                </td>
                            </tr>
                        ))
                    )}
                    </tbody>
                </Table>
            )}

            <div className={`${styles.actionContainer} d-flex justify-content-between mb-3`}>
                <div>
                    <Button variant="success" className={styles.buttonSpacing}>
                        Excel
                    </Button>
                    <Button variant="warning" className={styles.buttonSpacing}>
                        Import
                    </Button>
                    <Button variant="danger">PDF</Button>
                </div>

                <div>
                    <Pagination>
                        <Pagination.First onClick={() => setCurrentPage(1)} />
                        <Pagination.Prev onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)} />
                        {[...Array(totalPages)].map((_, index) => (
                            <Pagination.Item
                                key={index + 1}
                                active={index + 1 === currentPage}
                                onClick={() => handlePaginationClick(index + 1)}
                            >
                                {index + 1}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next onClick={() => currentPage < totalPages && setCurrentPage(currentPage + 1)} />
                        <Pagination.Last onClick={() => setCurrentPage(totalPages)} />
                    </Pagination>
                </div>
            </div>

            <Modal size="lg" show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedAmenity ? 'Edit Amenity' : 'Add Amenity'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className={styles.userForm} onSubmit={handleSubmit}>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <label htmlFor="name" className="form-label">Amenity Name:</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.amenityName ? 'is-invalid' : ''}`}
                                    id="amenityName"
                                    name="amenityName"
                                    defaultValue={selectedAmenity?.amenityName || ''}
                                />
                                {errors.amenityName && <div className="invalid-feedback">{errors.amenityName}</div>}
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="description" className="form-label">Description:</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                                    id="description"
                                    name="description"
                                    defaultValue={selectedAmenity?.description || ''}
                                />
                                {errors.description && <div className="invalid-feedback">{errors.description}</div>}
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-6">
                                <label htmlFor="price" className="form-label">Price:</label>
                                <input
                                    type="number"
                                    className={`form-control ${errors.price ? 'is-invalid' : ''}`}
                                    id="price"
                                    name="price"
                                    defaultValue={selectedAmenity?.price || 0}
                                />
                                {errors.price && <div className="invalid-feedback">{errors.price}</div>}
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="category" className="form-label">Category:</label>
                                <select
                                    className="form-select"
                                    id="category"
                                    name="category"
                                    defaultValue={selectedAmenity?.category || categories[0]}
                                >
                                    {categories.map((category, index) => (
                                        <option key={index} value={category}>{category}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Free:</label>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="free"
                                    id="freeYes"
                                    value="yes"
                                    defaultChecked={selectedAmenity?.free === true}
                                />
                                <label className="form-check-label" htmlFor="freeYes">Yes</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="free"
                                    id="freeNo"
                                    value="no"
                                    defaultChecked={selectedAmenity?.free === false}
                                />
                                <label className="form-check-label" htmlFor="freeNo">No</label>
                            </div>
                        </div>

                        <Button type="submit" variant="primary">
                            {selectedAmenity ? 'Update' : 'Add'}
                        </Button>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Amenity;
