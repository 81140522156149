import React, { useState } from 'react'; 
import { Form, Input, Button, message } from 'antd';
import axios from 'axios';

const AddRoomType = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true); 
    try {
      const response = await axios.post('https://spring-api.azurewebsites.net/api/roomType', values); 
      const { status, message: responseMessage } = response.data;

      if (status === 0) {
        message.success(responseMessage);
        form.resetFields();
      } else {
        message.error(responseMessage || 'Failed to add Room Type');
      }
    } catch (error) {
      message.error('Failed to add Room Type');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Add Room Type</h2>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Room Type Name"
          name="typeName"
          rules={[{ required: true, message: 'Please enter the room type name' }]}
        >
          <Input placeholder="Enter Room Type Name" />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: 'Please enter the description' }]}
        >
          <Input.TextArea placeholder="Enter Description" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Add Room Type
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddRoomType;
