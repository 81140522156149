import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './CustomerComponentCSS/NewPassword.module.css';
import {useTranslation} from "react-i18next";

function NewPassword() {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const { t, i18n } = useTranslation();

    const [newPassword, setNewPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        setNewPassword('');
    }, []);

    const handleConfirm = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `https://spring-api.azurewebsites.net/api/change-pass/reset-password?token=${token}`,
                new URLSearchParams({ newPassword })
            );

            if (response.status === 200) {
                toast.success('Password has been reset successfully!');
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            } else {
                toast.error('Error resetting password. Please try again.');
            }
        } catch (error) {
            console.error(error);
            toast.error('Error resetting password. Please try again.');
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className={styles.containerf}>
            <ToastContainer />
            <div className={`${styles['row']} justify-content-center ${styles['login-box']}`}>
                <div className={`${styles['col-lg-7']} ${styles['left-section']}`}>
                    <h2 className={styles.hotelTitle}>W E L C O M E&nbsp;&nbsp;T O <br /> R I V I E R A&nbsp;&nbsp;H O T E L</h2>
                    <div className={styles['login-form']}>
                        <h3>Reset Password</h3>
                        <form onSubmit={handleConfirm}>
                            <div className="mb-3 position-relative">
                                <label htmlFor="newPassword" className="form-label">New Password</label>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    className={`${styles['form-control']}`}
                                    id="newPassword"
                                    placeholder="Enter new password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                                <i
                                    className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} ${styles['toggle-password']}`}
                                    onClick={togglePasswordVisibility}
                                    style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '40%' }}
                                    title={showPassword ? 'Hide password' : 'Show password'}
                                ></i>
                            </div>
                            <button
                                type="submit"
                                className={styles.loginBtn}
                                style={{ backgroundColor: '#b29a89', color: 'white' }}
                                onMouseEnter={(e) => e.target.style.backgroundColor = 'tomato'}
                                onMouseLeave={(e) => e.target.style.backgroundColor = '#b29a89'}>
                                Reset Password
                            </button>
                        </form>
                    </div>
                </div>
                <div className={`${styles['col-lg-5']} ${styles['right-section']}`}>
                    <img src="/Images/hotel.png" alt="Riviera Hotel" className={styles['hotel-image']} />
                    <div className={styles['hotel-info']}>
                        <h2>RIVIERA</h2>
                        <p>C O A S T A L&nbsp;&nbsp;E L E G A N C E</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewPassword;
