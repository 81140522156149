import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaUserAlt, FaBed, FaList, FaCog, FaKey, FaUserCircle } from 'react-icons/fa';
import styles from './EmployeeCSS/Slidebar.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Sidebar = () => (
    <div className={styles.sidebarContainer}>
        <div className={styles.logoContainer}>
            <img
                src="/hotelw.png"
                alt="Hotel Logo"
                className={styles.logoImage}
            />
            <h3>R I V I E R A</h3>
            <p className={styles.pcap}>C O A S T A L&nbsp;&nbsp; E L E G A N C E</p>
        </div>

        <div className={styles.menu}>
            <div className={styles.menuItem}>
                <Link to="/" className={styles.link}>
                    <FaHome className={styles.icon} /> Dashboard
                </Link>
            </div>
            <div className={styles.menuItem}>
                <Link to="/customer-list" className={styles.link}>
                    <FaUserCircle className={styles.icon} /> Customer List
                </Link>
            </div>
            <div className={styles.menuItem}>
                <Link to="/room-list" className={styles.link}>
                    <FaBed className={styles.icon} /> Room Check
                </Link>
            </div>
            <div className={styles.menuItem}>
                <Link to="/room-list-offline" className={styles.link}>
                    <FaList className={styles.icon} /> Room List
                </Link>
            </div>
            <div className={styles.menuItem}>
                <Link to="/service-list" className={styles.link}>
                    <FaCog className={styles.icon} /> Service List
                </Link>
            </div>
            <div className={styles.menuItem}>
                <Link to="/amenity-list" className={styles.link}>
                    <FaList className={styles.icon} /> Amenity List
                </Link>
            </div>
            <div className={styles.menuItem}>
                <Link to="/changePass" className={styles.link}>
                    <FaKey className={styles.icon} /> Change Password
                </Link>
            </div>
            <div className={styles.menuItem}>
                <Link to="/myProfile" className={styles.link}>
                    <FaUserAlt className={styles.icon} /> My Profile
                </Link>
            </div>
        </div>
    </div>
);

export default Sidebar;
