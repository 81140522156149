import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './EmployeeCSS/BookingOffline.module.css';
import Swal from 'sweetalert2'; // Import SweetAlert2
import { Spinner } from 'react-bootstrap'; // Import Spinner

const RoomBookingForm = () => {
    const location = useLocation();
    const navigate = useNavigate(); // Sử dụng useNavigate để chuyển hướng
    const [roomDetails, setRoomDetails] = useState(null);
    const [formData, setFormData] = useState({
        bookingId: '',
        roomId: '',
        roomName: '',
        customerId: null,
        bookingDate: new Date().toISOString().slice(0, 16),
        checkInDate: '',
        checkOutDate: '',
        status: 'booked',
        discount: 0,
        numberOfGuests: 1,
        totalRoomPrice: 0,
        totalAmenityPrice: 0,
        totalAmount: 0,
        extraGuestFee: 0,
        identityCard: { cardNumber: '' },
    });

    const [loading, setLoading] = useState(false); // State for loading

    // Thêm useEffect để fetch chi tiết phòng
    useEffect(() => {
        console.log('Location state:', location.state);
        if (location.state?.roomId) {
            const fetchRoomDetails = async () => {
                setLoading(true); // Set loading to true
                try {
                    const response = await axios.get(`https://spring-api.azurewebsites.net/api/rooms/${location.state.roomId}`);
                    setRoomDetails(response.data.data);
                    setFormData((prev) => ({
                        ...prev,
                        roomId: response.data.data.roomID,
                        roomName: response.data.data.roomNumber.toString(),
                    }));
                } catch (error) {
                    console.error('Error fetching room details:', error);
                } finally {
                    setLoading(false); // Set loading to false
                }
            };

            fetchRoomDetails();
        }
    }, [location.state]);

    const [frontImage, setFrontImage] = useState(null);
    const [backImage, setBackImage] = useState(null);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        if (id === 'cardNumber') {
            setFormData((prev) => ({
                ...prev,
                identityCard: { ...prev.identityCard, cardNumber: value },
            }));
        } else {
            setFormData((prev) => ({ ...prev, [id]: value }));
        }
    };

    const handleFileChange = (e) => {
        const { id, files } = e.target;
        if (id === 'frontImage') setFrontImage(files[0]);
        if (id === 'backImage') setBackImage(files[0]);
    };

    const handleBooking = async () => {
        const bookingDTO = {
            bookingId: formData.bookingId,
            roomId: formData.roomId,
            customerId: formData.customerId,
            bookingDate: formData.bookingDate,
            checkInDate: formData.checkInDate,
            checkOutDate: formData.checkOutDate,
            status: formData.status,
            discount: formData.discount,
            numberOfGuests: formData.numberOfGuests,
            identityCard: {
                cardNumber: formData.identityCard?.cardNumber || '', // Tránh lỗi undefined
            },
        };

        const bookingData = new FormData();
        bookingData.append('frontImage', frontImage);
        bookingData.append('backImage', backImage);
        bookingData.append(
            'bookingDTO',
            new Blob([JSON.stringify(bookingDTO)], { type: 'application/json' })
        );

        setLoading(true); // Set loading to true
        try {
            const response = await axios.post(
                'https://spring-api.azurewebsites.net/api/bookings/offline',
                bookingData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            console.log(response);

            const bookingId = response.data.data.bookingId;

            if (!bookingId) {
                throw new Error('bookingId is missing in the response');
            }

            // Hiển thị SweetAlert và chờ phản hồi người dùng
            const result = await Swal.fire({
                title: 'Do you want to add services and amenities?',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            });

            if (result.isConfirmed) {
                console.log('Navigate with bookingId:', bookingId); // Debug giá trị truyền đi
                navigate('/bookingSA', {
                    state: { bookingId }, // Truyền bookingId vào state
                });
            } else {
                Swal.fire({
                    title: 'Booking Successfully',
                    text: 'Your booking has been successfully completed.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                }).then(() => {
                    // Chuyển hướng đến trang /room-list-offline
                    window.location.href = '/room-list-offline';
                });
            }
        } catch (err) {
            setError('Booking failed. Please check your inputs.');
            setSuccess('');
            console.error(err);

            // Hiển thị thông báo lỗi SweetAlert
            Swal.fire({
                icon: 'error',
                title: 'Booking Failed!',
                text: 'Please check your inputs and try again.',
                confirmButtonText: 'OK',
            });
        } finally {
            setLoading(false); // Set loading to false
        }
    };

    return (
        <div className={styles.container}>
            {loading && ( // Show loading spinner
                <div className="text-center">
                    <Spinner animation="border" />
                </div>
            )}
            <div className={styles.card}>
                <div className={styles.cardBody}>
                    <h3 className="mb-4">Offline Booking</h3>
                    <form>
                        <div className="row mt-3">
                            <div className="col-md-4">
                                <label htmlFor="roomId" className="form-label">Room Number</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="roomId"
                                    value={formData.roomName}
                                    onChange={handleInputChange}
                                    readOnly
                                />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="bookingDate" className="form-label">Booking Date</label>
                                <input
                                    type="datetime-local"
                                    className="form-control"
                                    id="bookingDate"
                                    value={formData.bookingDate}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-4">
                                <label htmlFor="checkInDate" className="form-label">Check-in Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="checkInDate"
                                    value={formData.checkInDate}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="checkOutDate" className="form-label">Check-out Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="checkOutDate"
                                    value={formData.checkOutDate}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="numberOfGuests" className="form-label">Number of Guests</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="numberOfGuests"
                                    min="1"
                                    value={formData.numberOfGuests}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-4">
                                <label htmlFor="discount" className="form-label">Discount (%)</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="discount"
                                    min="0"
                                    value={formData.discount}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="extraGuestFee" className="form-label">Extra Guest Fee</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="extraGuestFee"
                                    min="0"
                                    value={formData.extraGuestFee}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="cardNumber" className="form-label">Identity Card Number</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="cardNumber"
                                    value={formData.identityCard.cardNumber}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-6">
                                <label htmlFor="frontImage" className="form-label">Front Image</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    id="frontImage"
                                    onChange={handleFileChange}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="backImage" className="form-label">Back Image</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    id="backImage"
                                    onChange={handleFileChange}
                                />
                            </div>
                        </div>

                        <div className="mt-4">
                            <button type="button" className="btn btn-primary" onClick={handleBooking}>
                                Confirm Booking
                            </button>
                        </div>
                    </form>

                    {success && (
                        <div className="alert alert-success mt-4">
                            <strong>Success!</strong> {success}
                        </div>
                    )}

                    {error && (
                        <div className="alert alert-danger mt-4">
                            <strong>Error!</strong> {error}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RoomBookingForm;
