import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Table, Modal } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';

const AddRoomStatusForm = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [roomStatuses, setRoomStatuses] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingStatus, setEditingStatus] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchRoomStatuses();
  }, []);

  // Fetch room statuses from API
  const fetchRoomStatuses = async () => {
    try {
      const response = await axios.get('https://spring-api.azurewebsites.net/api/roomStatus');
      setRoomStatuses(response.data.data || []);
    } catch (error) {
      message.error('Failed to fetch room statuses.');
    }
  };

  // Handle form submission (add or update)
  const onFinish = async (values) => {
    setLoading(true);
    try {
      if (isEditing) {
        await axios.put(`https://spring-api.azurewebsites.net/api/roomStatus/${editingStatus.roomStatusId}`, {
          roomStatusId: editingStatus.roomStatusId,
          statusName: values.statusName,
        });
        message.success('Room Status updated successfully!');
      } else {
        await axios.post('https://spring-api.azurewebsites.net/api/roomStatus', {
          statusName: values.statusName,
        });
        message.success('Room Status added successfully!');
      }
      form.resetFields();
      fetchRoomStatuses();
    } catch (error) {
      message.error('Failed to submit Room Status. Please try again.');
    } finally {
      setLoading(false);
      setIsModalVisible(false);
      setIsEditing(false);
    }
  };

  // Handle edit action
  const handleEdit = (record) => {
    setEditingStatus(record);
    setIsEditing(true);
    setIsModalVisible(true);
    form.setFieldsValue({
      roomStatusId: record.roomStatusId,
      statusName: record.statusName,
    });
  };

  // Handle delete action
  const handleDelete = async (record) => {
    try {
      await axios.delete(`https://spring-api.azurewebsites.net/api/roomStatus/${record.roomStatusId}`);
      message.success(`Room Status ID: ${record.roomStatusId} deleted successfully!`);
      fetchRoomStatuses();
    } catch (error) {
      message.error('Failed to delete Room Status. Please try again.');
    }
  };

  const columns = [
    {
      title: 'Room Status ID',
      dataIndex: 'roomStatusId',
      key: 'roomStatusId',
    },
    {
      title: 'Status Name',
      dataIndex: 'statusName',
      key: 'statusName',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <EditOutlined onClick={() => handleEdit(record)} style={{ marginRight: 16, color: 'blue' }} />
          <DeleteOutlined onClick={() => handleDelete(record)} style={{ color: 'red' }} />
        </span>
      ),
    },
  ];

  return (
    <div style={{ maxWidth: '800px', margin: '0 auto' }}>
      <h2>{isEditing ? 'Edit Room Status' : 'Add Room Status'}</h2>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Room Status ID"
          name="roomStatusId"
        >
          <Input placeholder="Room Status ID" disabled={isEditing} />
        </Form.Item>

        <Form.Item
          label="Status Name"
          name="statusName"
          rules={[{ required: true, message: 'Please enter the Status Name' }]}
        >
          <Input placeholder="Enter Status Name" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {isEditing ? 'Update Room Status' : 'Add Room Status'}
          </Button>
        </Form.Item>
      </Form>

      <h2>Room Statuses</h2>
      <Table
        columns={columns}
        dataSource={roomStatuses}
        pagination={{ pageSize: 5 }}
        rowKey="roomStatusId"
      />

      <Modal
        title="Edit Room Status"
        visible={isModalVisible}
        onOk={form.submit}
        onCancel={() => {
          setIsModalVisible(false);
          setIsEditing(false);
          form.resetFields();
        }}
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item label="Room Status ID">
            <Input value={editingStatus?.roomStatusId} disabled />
          </Form.Item>
          <Form.Item label="Status Name" name="statusName">
            <Input
              value={editingStatus?.statusName}
              onChange={(e) =>
                setEditingStatus({ ...editingStatus, statusName: e.target.value })
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddRoomStatusForm;
