import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, message } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';

const PaymentMethodManage = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingPaymentMethod, setEditingPaymentMethod] = useState(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  // Fetch payment methods from API
  const fetchPaymentMethods = async () => {
    try {
      const response = await axios.get('https://spring-api.azurewebsites.net/api/paymentMethod');
      setPaymentMethods(response.data.data || []);
    } catch (error) {
      message.error('Failed to fetch payment methods.');
    }
  };

  // Handle form submission for adding or updating payment methods
  const handleFinish = async (values) => {
    setLoading(true);
    try {
      if (editingPaymentMethod) {
        // Update existing payment method
        await axios.put(`https://spring-api.azurewebsites.net/api/paymentMethod/${editingPaymentMethod.paymentMethodId}`, values);
        message.success('Payment method updated successfully!');
      } else {
        // Add new payment method
        await axios.post('https://spring-api.azurewebsites.net/api/paymentMethod', values);
        message.success('Payment method added successfully!');
      }
      fetchPaymentMethods(); // Refresh the list
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      message.error('Failed to submit payment method.');
    } finally {
      setLoading(false);
      setEditingPaymentMethod(null);
    }
  };

  // Handle edit
  const handleEdit = (record) => {
    setEditingPaymentMethod(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  // Handle delete
  const handleDelete = async (paymentMethodId) => {
    try {
      await axios.delete(`https://spring-api.azurewebsites.net/api/paymentMethod/${paymentMethodId}`);
      message.success('Payment method deleted successfully!');
      fetchPaymentMethods(); // Refresh the list
    } catch (error) {
      message.error('Failed to delete payment method.');
    }
  };

  // Open modal for adding new payment method
  const handleAdd = () => {
    setIsModalVisible(true);
    form.resetFields();
    setEditingPaymentMethod(null);
  };

  // Columns for the table
  const columns = [
    {
      title: 'No.',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Payment Method ID',
      dataIndex: 'paymentMethodId',
      key: 'paymentMethodId',
    },
    {
      title: 'Payment Type',
      dataIndex: 'paymentType',
      key: 'paymentType',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <>
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            style={{ marginRight: 8 }}
          />
          <Button
            type="danger"
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.paymentMethodId)}
          />
        </>
      ),
    },
  ];

  return (
    <div>
      <h2>Payment Method Management</h2>

      {/* Add Button */}
      <Button type="primary" onClick={handleAdd} style={{ marginBottom: 20 }}>
        Add Payment Method
      </Button>

      {/* Table for managing payment methods */}
      <Table
        columns={columns}
        dataSource={paymentMethods}
        rowKey="paymentMethodId"
        pagination={{ pageSize: 5 }}
      />

      {/* Modal for adding/editing payment method */}
      <Modal
        title={editingPaymentMethod ? 'Edit Payment Method' : 'Add Payment Method'}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={form.submit}
        confirmLoading={loading}
      >
        <Form form={form} onFinish={handleFinish} layout="vertical">
          <Form.Item
            label="Payment Type"
            name="paymentType"
            rules={[{ required: true, message: 'Please enter the payment type!' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default PaymentMethodManage;
