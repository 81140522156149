import React, { useState, useEffect } from 'react';
import { Menu, Dropdown, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();
  const [currentTime, setCurrentTime] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      setCurrentTime(now.toLocaleTimeString());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleMenuClick = (key) => {
    switch (key) {
      case '1':
        navigate('/profile'); // Chuyển hướng đến trang Profile
        break;
      case '2':
        console.log('Settings clicked');
        break;
      case '3':
        navigate('/change-password'); // Chuyển hướng đến giao diện đổi mật khẩu
        break;
      case '4':
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('userId'); // Xóa thông tin người dùng
        console.log('Logout clicked');
        navigate('/login'); // Chuyển hướng về trang login
        break;
      default:
        break;
    }
  };

  const userMenu = (
      <Menu onClick={(e) => handleMenuClick(e.key)}>
        <Menu.Item key="1">Profile</Menu.Item>
        <Menu.Item key="2">Setting</Menu.Item>
        <Menu.Item key="3">Change Password</Menu.Item>
        <Menu.Item key="4" danger>Logout</Menu.Item>
      </Menu>
  );

  return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px', backgroundColor: '#f0f2f5', alignItems: 'center' }}>
        <Dropdown overlay={userMenu} trigger={['click']}>
          <Avatar size="large" icon={<UserOutlined />} style={{ cursor: 'pointer', marginRight: '10px' }} />
        </Dropdown>
        <div style={{ backgroundColor: '#e0e0e0', padding: '5px 15px', borderRadius: '5px' }}>
          {currentTime}
        </div>
      </div>
  );
};

export default Header;
