import React, { useEffect, useState } from 'react';
import { Table, Button, message, Modal, Form, Input } from 'antd';
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import axios from 'axios';

const RoomTypeManage = () => {
  const [roomTypes, setRoomTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [currentRoomType, setCurrentRoomType] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [form] = Form.useForm();

  const pageSize = 5; // Số lượng bản ghi trên mỗi trang

  useEffect(() => {
    fetchRoomTypes();
  }, [searchText]);

  const fetchRoomTypes = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://spring-api.azurewebsites.net/api/roomType');
      const { status, data, message: responseMessage } = response.data;

      if (status === 0) {
        const filteredData = data.filter((item) =>
          item.typeName.toLowerCase().includes(searchText.toLowerCase())
        );
        setRoomTypes(filteredData);
        setTotalItems(filteredData.length); // Tổng số bản ghi
      } else {
        message.error(responseMessage || 'Failed to fetch Room Types');
      }
    } catch (error) {
      message.error('Failed to fetch Room Types');
    } finally {
      setLoading(false);
    }
  };

  const deleteRoomType = async (roomTypeId) => {
    try {
      const response = await axios.delete(`https://spring-api.azurewebsites.net/api/roomType/${roomTypeId}`);
      const { status, message: responseMessage } = response.data;

      if (status === 0) {
        message.success(responseMessage);
        fetchRoomTypes(); // Làm mới danh sách sau khi xóa
      } else {
        message.error(responseMessage || 'Failed to delete Room Type');
      }
    } catch (error) {
      message.error('Failed to delete Room Type');
    }
  };

  const showEditModal = (roomType) => {
    setCurrentRoomType(roomType);
    form.setFieldsValue(roomType);
    setIsEditModalVisible(true);
  };

  const handleEdit = async (values) => {
    try {
      const response = await axios.put(`https://spring-api.azurewebsites.net/api/roomType/${currentRoomType.roomTypeId}`, values);
      const { status, message: responseMessage } = response.data;

      if (status === 0) {
        message.success(responseMessage);
        setIsEditModalVisible(false);
        fetchRoomTypes(); // Làm mới danh sách sau khi cập nhật
      } else {
        message.error(responseMessage || 'Failed to edit Room Type');
      }
    } catch (error) {
      message.error('Failed to edit Room Type');
    }
  };

  const columns = [
    {
      title: 'No.',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: 'Room Type Name',
      dataIndex: 'typeName',
      key: 'typeName',
      sorter: (a, b) => a.typeName.localeCompare(b.typeName),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text) => (text.length > 100 ? `${text.substring(0, 100)}...` : text),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <>
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => showEditModal(record)}
            style={{ marginRight: 8 }}
          />
          <Button
            type="danger"
            icon={<DeleteOutlined />}
            onClick={() => deleteRoomType(record.roomTypeId)}
          />
        </>
      ),
    },
  ];

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  return (
    <div>
      <h2>Room Type Manage</h2>
      <Input
        placeholder="Search Room Type"
        prefix={<SearchOutlined />}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        style={{ marginBottom: 16, width: 300 }}
      />
      <p>Total Room Types: {totalItems}</p>
      <p>Total Pages: {Math.ceil(totalItems / pageSize)}</p>

      <Table
        columns={columns}
        dataSource={roomTypes.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
        rowKey="roomTypeId"
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalItems,
          showSizeChanger: false,
        }}
        onChange={handleTableChange}
      />

      <Modal
        title="Edit Room Type"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        onOk={() => form.submit()}
      >
        <Form form={form} layout="vertical" onFinish={handleEdit}>
          <Form.Item
            label="Room Type Name"
            name="typeName"
            rules={[{ required: true, message: 'Please enter the room type name' }]}
          >
            <Input placeholder="Enter Room Type Name" />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: 'Please enter the description' }]}
          >
            <Input.TextArea placeholder="Enter Description" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default RoomTypeManage;
