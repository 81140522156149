
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Modal, Button, Table, Pagination, Form } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import axios from 'axios';
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './EmployeeCSS/RoomList.module.css';
import { toast } from "react-toastify";

const RoomList = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [bookings, setBookings] = useState([]);
    const [filteredBookings, setFilteredBookings] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [bookingsPerPage] = useState(8);
    const [loading, setLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [showAddForm, setShowAddForm] = useState(false);
    const [selectedAmenityId, setSelectedAmenityId] = useState('');
    const [selectedBookingId, setSelectedBookingId] = useState('');
    const statuses = ['booked', 'checked-in', 'checked-out', 'Pending', 'cancelled'];

    const navigate = useNavigate();

    const handleExportInvoice = () => {
        if (selectedBookingId) {
            fetchBookingDetails(selectedBookingId)
                .then(() => {
                    navigate('/invoice', { state: { data: selectedBooking } });
                });
        }
    };
    const handleAddAmenity = () => {
        if (selectedBookingId) {
            navigate('/bookingSA', { state: { bookingId: selectedBookingId } });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please select a booking to add amenities.',
            });
        }
    };

    const fetchBookingDetails = async (bookingId) => {
        try {
            const response = await axios.get(`https://spring-api.azurewebsites.net/api/booking-details/${bookingId}`);

            // Thay đổi thành response.data.data.bookingDetails
            setSelectedBooking(response.data.data.bookingDetails);
        } catch (error) {
            console.error('Error fetching booking details:', error);
            Swal.fire('Error', 'Could not load booking details', 'error');
        }
    };


    useEffect(() => {
        fetchBookings();
        fetchBookingCheckStatus();
    }, []);
    const fetchBookings = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://spring-api.azurewebsites.net/api/bookings/check');
            setBookings(response.data.data);
            setFilteredBookings(response.data.data);
        } catch (error) {
            console.error('Error fetching bookings:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchBookingCheckStatus = async () => {
        try {
            const response = await axios.get('https://spring-api.azurewebsites.net/api/bookings/bookingCheckStatus');
            setBookings(response.data.data);
        } catch (error) {
            console.error('Error fetching booking check status:', error);
        }
    };


    const handleBookingChange = (e) => {
        const bookingId = e.target.value;
        setSelectedBookingId(bookingId);
        if (bookingId) {
            fetchBookingDetails(bookingId);
        } else {
            setSelectedBooking(null);
            setAmenities([]);
        }
    };
    const handleAmenityChange = (e) => {
        const amenityId = e.target.value; // Save the selected amenityId
        setSelectedAmenityId(amenityId); // Update selectedAmenityId in state
        if (selectedBookingId) {  // Use selectedBookingId instead of bookingId
            fetchBookingDetails(selectedBookingId);  // Only fetch details if bookingId is selected
        } else {
            setSelectedAmenityId(null);  // Reset amenityId if no booking is selected
            setAmenities([]);  // Clear amenities
        }
    };
    const updateBooking = async (updatedBooking) => {
        try {
            await axios.put(`https://spring-api.azurewebsites.net/api/bookings/${updatedBooking.bookingId}`, updatedBooking);
            setBookings(prevBookings =>
                prevBookings.map(booking =>
                    booking.bookingId === updatedBooking.bookingId ? updatedBooking : booking
                )
            );
            Swal.fire('Success', 'Booking updated successfully!', 'success');
            handleClose();
        } catch (error) {
            const errorMessage = error.response?.data?.message || JSON.stringify(error.response?.data) || error.message;
            Swal.fire('Error', `Error updating booking: ${errorMessage}`, 'error');
        }
    };

    const handleClose = () => {
        setShowModal(false);
        setSelectedBooking(null);
    };

    const handleShow = (booking) => {
        setSelectedBooking(booking);
        setShowModal(true);
    };

    const handleSave = async (e) => {
        e.preventDefault();

        if (!selectedBooking) {
            toast.error('No booking selected for update.');
            return;
        }

        const formData = new FormData(e.target);
        const formatDateTime = (date) => (date ? `${date}T00:00:00` : null); // Thêm thời gian mặc định

        const updatedBooking = {
            bookingId: selectedBooking.bookingId,
            customerId: Number(formData.get('customerId')),
            roomId: Number(formData.get('roomId')),
            bookingDate: formatDateTime(formData.get('bookingDate')),
            checkInDate: formatDateTime(formData.get('checkInDate')),
            checkOutDate: formatDateTime(formData.get('checkOutDate')),
            status: formData.get('status'),
            discount: Number(formData.get('discount')) || 0,
            numberOfGuests: Number(formData.get('numberOfGuests')) || 1,
            totalRoomPrice: Number(formData.get('totalRoomPrice')) || 0,
            totalAmenityPrice: Number(formData.get('totalAmenityPrice')) || 0,
            totalAmount: Number(formData.get('totalAmount')) || 0,
            extraGuestFee: Number(formData.get('extraGuestFee')) || 0,
        };

        try {
            await updateBooking(updatedBooking);
            toast.success('Booking updated successfully!');
            await fetchBookings();
            handleClose();
        } catch (error) {
            const errorMessage = error.response?.data?.message || JSON.stringify(error.response?.data) || error.message;
            toast.error(`Error saving booking: ${errorMessage}`);
        }
    };


    const indexOfLastBooking = currentPage * bookingsPerPage;
    const indexOfFirstBooking = indexOfLastBooking - bookingsPerPage;
    const currentBookings = filteredBookings.slice(indexOfFirstBooking, indexOfLastBooking);
    const totalPages = Math.ceil(filteredBookings.length / bookingsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
        }).format(amount);
    };

    const handleSearch = () => {
        const filtered = bookings.filter(booking => booking.status === selectedStatus);
        setFilteredBookings(filtered);
        setCurrentPage(1);
    };

    const handleAddClick = () => {
        setShowAddForm(true);
    };

    const handleSubmitAddAmenity = async (e) => {
        e.preventDefault();
        if (!selectedBookingId || !selectedAmenityId) {
            Swal.fire('Error', 'Please select both Booking and Amenity', 'error');
            return;
        }

        try {
            const response = await axios.post('https://spring-api.azurewebsites.net/api/bookingAmenities', {
                bookingId: selectedBookingId,
                amenityId: selectedAmenityId
            });

            if (response.status === 200) {
                Swal.fire('Success', 'Booking Amenity created successfully', 'success');
                // Reset form
                setSelectedBookingId('');
                setSelectedAmenityId('');
            }
        } catch (error) {
            console.error('Error creating booking amenity:', error);
            Swal.fire('Error', 'Could not create booking amenity', 'error');
        }
    };
    return (
        <div className={styles.container}>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="input-group" style={{ width: '300px' }}>
                    <select
                        className="form-select"
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value)}
                    >
                        <option value="">Select Status</option>
                        {statuses.map((status, index) => (
                            <option key={index} value={status}>{status}</option>
                        ))}
                    </select>
                    <Button variant="primary" onClick={handleSearch}>Search</Button>
                </div>
                <Button variant="success" onClick={handleAddClick}>Room Check</Button>
            </div>

            {loading ? (
                <div className="text-center">Loading bookings...</div>
            ) : (
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>No.</th>
                        <th>Customer ID</th>
                        <th>Room ID</th>
                        <th>Booking Date</th>
                        <th>Check-in Date</th>
                        <th>Check-out Date</th>
                        <th>Status</th>
                        <th>Discount</th>
                        <th>Number of Guests</th>
                        <th>Total Room Price</th>
                        <th>Total Amount</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {currentBookings.length === 0 ? (
                        <tr>
                            <td colSpan="12" className="text-center">No bookings found.</td>
                        </tr>
                    ) : (
                        currentBookings.map((booking, index) => (
                            <tr key={booking.bookingId}>
                                <td>{index + 1 + indexOfFirstBooking}</td>
                                <td>{booking.customerId}</td>
                                <td>{booking.roomId}</td>
                                <td>{booking.bookingDate}</td>
                                <td>{booking.checkInDate}</td>
                                <td>{booking.checkOutDate}</td>
                                <td>{booking.status}</td>
                                <td>{booking.discount}</td>
                                <td>{booking.numberOfGuests}</td>
                                <td>{formatCurrency(booking.totalRoomPrice)}</td>
                                <td>{formatCurrency(booking.totalAmount)}</td>
                                <td>
                                    <Button variant="success" onClick={() => handleShow(booking)}>
                                        <FaEdit />
                                    </Button>
                                </td>
                            </tr>
                        ))
                    )}
                    </tbody>
                </Table>
            )}

            <div className="d-flex justify-content-end">
                <Pagination>
                    <Pagination.First onClick={() => paginate(1)} />
                    <Pagination.Prev onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)} />
                    {[...Array(totalPages)].map((_, index) => (
                        <Pagination.Item
                            key={index + 1}
                            active={index + 1 === currentPage}
                            onClick={() => paginate(index + 1)}
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={() => paginate(currentPage < totalPages ? currentPage + 1 : totalPages)} />
                    <Pagination.Last onClick={() => paginate(totalPages)} />
                </Pagination>
            </div>

            {showAddForm && (
                <Modal show={showAddForm} onHide={() => setShowAddForm(false)} size="xl">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            {/* Booking Selection */}
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <Form.Group controlId="bookingId">
                                        <Form.Label>Select Booking</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={selectedBookingId}
                                            onChange={handleBookingChange}
                                        >
                                            <option value="">Select Booking</option>
                                            {bookings
                                                .filter((booking) => booking.status === 'checked-in') // Lọc bookingId có trạng thái CHECK-IN
                                                .map((booking) => (
                                                    <option key={booking.bookingId} value={booking.bookingId}>
                                                        {booking.roomName}
                                                    </option>
                                                ))}
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                            </div>

                            {/* Các trường thông tin booking */}
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <Form.Group controlId="bookingDate">
                                        <Form.Label>Booking Date</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={selectedBooking ? selectedBooking.bookingDate : ''}
                                            readOnly
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group controlId="checkInDate">
                                        <Form.Label>Check-In Date</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={selectedBooking ? selectedBooking.checkInDate : ''}
                                            readOnly
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group controlId="checkOutDate">
                                        <Form.Label>Check-Out Date</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={selectedBooking ? selectedBooking.checkOutDate : ''}
                                            readOnly
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            {/* Các trường thông tin khác */}
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <Form.Group controlId="fullName">
                                        <Form.Label>Full Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={selectedBooking ? selectedBooking.customerName : ''}
                                            readOnly
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group controlId="discount">
                                        <Form.Label>Discount</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={selectedBooking ? selectedBooking.discount : ''}
                                            readOnly
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            {/* Các trường thông tin khác */}
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <Form.Group controlId="extraGuestFee">
                                        <Form.Label>Extra Guest Fee</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={selectedBooking ? selectedBooking.extraGuestFee : ''}
                                            readOnly
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group controlId="numberOfGuests">
                                        <Form.Label>Number of Guests</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={selectedBooking ? selectedBooking.numberOfGuests : ''}
                                            readOnly
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group controlId="roomNumber">
                                        <Form.Label>Room Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={selectedBooking ? selectedBooking.roomName : ''}
                                            readOnly
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <Form.Group controlId="totalRoomPrice">
                                        <Form.Label>Total Room Price</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={selectedBooking ? selectedBooking.totalRoomPrice : ''}
                                            readOnly
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group controlId="totalAmount">
                                        <Form.Label>Total Amount</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={selectedBooking ? selectedBooking.totalAmount : ''}
                                            readOnly
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group controlId="totalAmenityPrice">
                                        <Form.Label>Total Amenity Price</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={selectedBooking ? selectedBooking.totalAmenityPrice : ''}
                                            readOnly
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <Button
                                        variant="primary"
                                        onClick={handleExportInvoice}
                                        disabled={!selectedBookingId} // Disable button if no booking is selected
                                    >
                                        Export Invoice
                                    </Button>
                                </div>
                                <div className="col-md-4 ">
                                    <Button variant="primary" onClick={handleAddAmenity}>
                                        Add New Amenity
                                    </Button>
                                </div>
                            </div>

                        </Form>
                    </Modal.Body>
                </Modal>
            )}
            <Modal size="xl" show={showModal} onHide={handleClose} dialogClassName={styles.modalCustom}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedBooking ? 'Edit Booking' : 'Add Booking'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className={styles.bookingForm} onSubmit={handleSave}>
                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label htmlFor="customerId" className="form-label">Customer ID</label>
                                <input type="number" className="form-control" id="customerId" name="customerId" defaultValue={selectedBooking?.customerId || ''} />
                            </div>
                            <div className="col-md-4 mb-3">
                                <label htmlFor="roomId" className="form-label">Room ID</label>
                                <input type="number" className="form-control" id="roomId" name="roomId" defaultValue={selectedBooking?.roomId || ''} />
                            </div>
                            <div className="col-md-4 mb-3">
                                <label htmlFor="bookingDate" className="form-label">Booking Date</label>
                                <input type="date" className="form-control" id="bookingDate" name="bookingDate" defaultValue={selectedBooking?.bookingDate || ''} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label htmlFor="checkInDate" className="form-label">Check-in Date</label>
                                <input type="date" className="form-control" id="checkInDate" name="checkInDate" defaultValue={selectedBooking?.checkInDate || ''} />
                            </div>
                            <div className="col-md-4 mb-3">
                                <label htmlFor="checkOutDate" className="form-label">Check-out Date</label>
                                <input type="date" className="form-control" id="checkOutDate" name="checkOutDate" defaultValue={selectedBooking?.checkOutDate || ''} />
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label htmlFor="discount" className="form-label">Discount</label>
                                <input type="number" className="form-control" id="discount" name="discount" defaultValue={selectedBooking?.discount || ''} />
                            </div>
                            <div className="col-md-4 mb-3">
                                <label htmlFor="numberOfGuests" className="form-label">Number of Guests</label>
                                <input type="number" className="form-control" id="numberOfGuests" name="numberOfGuests" defaultValue={selectedBooking?.numberOfGuests || ''} />
                            </div>
                            <div className="col-md-4 mb-3">
                                <label htmlFor="totalRoomPrice" className="form-label">Total Room Price</label>
                                <input type="number" className="form-control" id="totalRoomPrice" name="totalRoomPrice" defaultValue={selectedBooking?.totalRoomPrice || ''} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label htmlFor="totalAmenityPrice" className="form-label">Total Amenity Price</label>
                                <input type="number" className="form-control" id="totalAmenityPrice" name="totalAmenityPrice" defaultValue={selectedBooking?.totalAmenityPrice || ''} />
                            </div>
                            <div className="col-md-4 mb-3">
                                <label htmlFor="totalAmount" className="form-label">Total Amount</label>
                                <input type="number" className="form-control" id="totalAmount" name="totalAmount" defaultValue={selectedBooking?.totalAmount || ''} />
                            </div>
                            <div className="col-md-4 mb-3">
                                <label htmlFor="extraGuestFee" className="form-label">Extra Guest Fee</label>
                                <input type="number" className="form-control" id="extraGuestFee" name="extraGuestFee" defaultValue={selectedBooking?.extraGuestFee || ''} />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label className="form-label">Status:</label>
                            <div className={`${styles.radioGroup} d-flex flex-wrap`}>
                                <div className="form-check me-3">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        id="statusBooked"
                                        name="status"
                                        value="booked"
                                        defaultChecked={selectedBooking?.status === 'booked'}
                                    />
                                    <label className="form-check-label" htmlFor="statusBooked">Booked</label>
                                </div>
                                <div className="form-check me-3">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        id="statusCheckedIn"
                                        name="status"
                                        value="checked-in"
                                        defaultChecked={selectedBooking?.status === 'checked-in'}
                                    />
                                    <label className="form-check-label" htmlFor="statusCheckedIn">Checked in</label>
                                </div>
                                <div className="form-check me-3">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        id="statusCheckedOut"
                                        name="status"
                                        value="checked-out"
                                        defaultChecked={selectedBooking?.status === 'checked-out'}
                                    />
                                    <label className="form-check-label" htmlFor="statusCheckedOut">Checked out</label>
                                </div>
                                <div className="form-check me-3">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        id="statusCancelled"
                                        name="status"
                                        value="cancelled"
                                        defaultChecked={selectedBooking?.status === 'cancelled'}
                                    />
                                    <label className="form-check-label" htmlFor="statusCancelled">Cancelled</label>
                                </div>
                            </div>
                        </div>

                        <Button variant="primary" type="submit">Save</Button>
                    </form>
                </Modal.Body>
            </Modal>

        </div>
    );
};

export default RoomList;
