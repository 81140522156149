import React from 'react';
import styles from './CustomerComponentCSS/contact.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useTranslation } from "react-i18next";  // Import useTranslation hook

function Contact() {
  const { t, i18n } = useTranslation();

  return (
      <div className="container">
        <div className={styles['search-section']} style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
          <form className="row g-3 align-items-center">
            <div className="col-md-3">
              <label htmlFor="destination" className="form-label">{t('contact.Destination')}</label>
              <select className="form-select" id="destination">
                <option>{t('contact.Select a destination')}</option>
              </select>
            </div>
            <div className="col-md-2">
              <label htmlFor="checkin" className="form-label">{t('contact.Check In')}</label>
              <input type="date" className="form-control" id="checkin" />
            </div>
            <div className="col-md-2">
              <label htmlFor="checkout" className="form-label">{t('contact.Check Out')}</label>
              <input type="date" className="form-control" id="checkout" />
            </div>
            <div className="col-md-2">
              <label htmlFor="adults" className="form-label">{t('contact.Adults')}</label>
              <select className="form-select" id="adults">
                <option>{t('contact.Select number')}</option>
              </select>
            </div>
            <div className="col-md-2">
              <label htmlFor="children" className="form-label">{t('contact.Children')}</label>
              <select className="form-select" id="children">
                <option>{t('contact.Select number')}</option>
              </select>
            </div>
            <div className="col-md-1 d-flex align-items-end">
              <button style={{ marginTop: 27 }} type="submit" className="btn btn-primary">{t('contact.Search')}</button>
            </div>
          </form>
        </div>

        <div className={styles['contact-form']} style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
          <h4>{t('contact.Contact Us')}</h4>
          <form className={styles['contact-form-details']}>
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="fullName" className="form-label">{t('contact.Full Name')} *</label>
                <input type="text" className="form-control" id="fullName" placeholder={t('contact.Enter your full name')} />
              </div>
              <div className="col-md-6">
                <label htmlFor="tel" className="form-label">{t('contact.Tel')} *</label>
                <input type="tel" className="form-control" id="tel" placeholder={t('contact.Enter your phone number')} />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="email" className="form-label">{t('contact.Email')} *</label>
                <input type="email" className="form-control" id="email" placeholder={t('contact.Enter your email')} />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12">
                <label htmlFor="additionalRequest" className="form-label">{t('contact.Additional Request')}</label>
                <textarea className="form-control" id="additionalRequest" rows="4" placeholder={t('contact.Enter additional requests')}></textarea>
              </div>
            </div>
            <button type="submit" className="btn btn-primary">{t('contact.Submit')}</button>
          </form>
        </div>
       </div>
  );
}

export default Contact;
