import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import styles from './EmployeeCSS/Invoice.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast } from 'react-toastify';

function Invoice() {
    const location = useLocation();
    const data = location.state?.data; // Data from React Router state
    const navigate = useNavigate();
    const [bookingData, setBookingData] = useState(null);

    useEffect(() => {
        const fetchBookingDetails = async () => {
            try {
                const bookingId = location.state?.bookingDetails?.bookingId || location.state?.data?.bookingId;

                if (!bookingId) {
                    toast.error('Booking information not found');
                    navigate('/room-list');
                    return;
                }

                const response = await axios.get(`https://spring-api.azurewebsites.net/api/booking-details/${bookingId}`);

                if (response.data.status === 0) {
                    setBookingData(response.data.data);
                } else {
                    toast.error('Unable to load booking details');
                    navigate('/room-list');
                }
            } catch (error) {
                console.error('Error fetching booking details:', error);
                toast.error('An error occurred');
                navigate('/room-list');
            }
        };

        fetchBookingDetails();
    }, [location, navigate]);

    if (!bookingData) {
        return <div>Loading information...</div>;
    }

    if (!data) {
        navigate('/room-list');
        return null;
    }

    const formatCurrency = (value) =>
        new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: 'VND',
        }).format(value || 0);

// Ví dụ sử dụng
    console.log(formatCurrency(1000000)); // Kết quả: "1.000.000 ₫"

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = dateString.includes('T') ? dateString.split('T')[0] : dateString;
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).format(new Date(date));
    };

    const handlePrintInvoice = () => {
        Swal.fire({
            title: 'Print Invoice',
            text: 'The invoice will be printed, and the booking status will be updated to "Checked-Out".',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Proceed',
            cancelButtonText: 'Cancel',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const updatedBooking = { ...data, status: 'checked-out' };
                    await axios.post(`https://spring-api.azurewebsites.net/api/bookings/print/booking?bookingId=${updatedBooking.bookingId}`, updatedBooking);
                    toast.success('Booking status updated successfully!');
                    window.print();
                    navigate('/room-list', { state: { updatedData: updatedBooking } });
                } catch (error) {
                    console.error('Error updating booking status:', error);
                    toast.error('Failed to update booking status.');
                }
            }
        });
    };


    const handleOnlinePayment = async (bookingId, amount) => {
        try {
            // Gọi API để tạo booking amenities và lấy URL thanh toán
            const response = await axios.get(
                `https://spring-api.azurewebsites.net/api/bookingAmenities/create`,
                {
                    params: { bookingId, amount }, // Truyền thêm amount nếu cần thiết
                    headers: {
                        Accept: 'application/json',
                    },
                }
            );

            if (response.status === 200 && response.data.url) {
                const paymentUrl = response.data.url; // URL thanh toán từ API

                Swal.fire({
                    title: 'Proceed to Payment',
                    text: 'Redirecting to the payment portal. Please confirm.',
                    icon: 'info',
                    confirmButtonText: 'Proceed',
                }).then(() => {
                    // Chuyển hướng đến trang thanh toán VNPAY
                    window.location.href = paymentUrl;

                    // Lắng nghe sự kiện thành công nếu cần chuyển hướng trở lại
                    window.addEventListener('payment-success', () => {
                        window.location.href = '/room-list'; // Điều hướng sau thanh toán thành công
                    });
                });
            } else {
                toast.error('Failed to retrieve payment URL. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred. Please try again.');
        }
    };







    console.log('Received Data:', data);

    return (
        <div className={styles.container}>
            <div className={styles['invoice-card']}>
                <div className={styles['card-header']}>
                    <h3>Invoice - Booking #{bookingData?.bookingDetails?.roomName || 'N/A'}</h3>
                    <button className={`${styles['btn-print']} btn`} onClick={handlePrintInvoice}>
                        Print Invoice
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() =>
                            handleOnlinePayment(
                                bookingData?.bookingDetails?.bookingId,
                                bookingData?.totalAmount
                            )
                        }
                    >
                        Proceed to Payment
                    </button>
                </div>
                <div className={styles['card-body']}>
                    <h5 className={styles['section-title']}>Customer Information</h5>
                    <div className="row">
                        <div className="col-md-6">
                            <p>
                                <strong>Full Name:</strong>{' '}
                                {bookingData?.bookingDetails?.customerName || 'N/A'}
                            </p>
                            <p>
                                <strong>Room Number:</strong>{' '}
                                {bookingData?.bookingDetails?.roomName || 'N/A'}
                            </p>
                        </div>
                        <div className="col-md-6">
                            <p>
                                <strong>Booking Date:</strong>{' '}
                                {formatDate(bookingData?.bookingDetails?.bookingDate) || 'N/A'}
                            </p>
                            <p>
                                 <strong>Check-In Date:</strong>{' '}
                                {formatDate(bookingData?.bookingDetails?.checkInDate) || 'N/A'}
                            </p>
                            <p>
                                <strong>Check-Out Date:</strong>{' '}
                                {formatDate(bookingData?.bookingDetails?.checkOutDate) || 'N/A'}
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        {bookingData?.bookingServices?.length > 0 ? (
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>Service Name</th>
                                    <th>Quantity</th>
                                    <th>Unit Price</th>
                                    <th>Total Price</th>
                                </tr>
                                </thead>
                                <tbody>
                                {bookingData.bookingServices.map((service) => (
                                    <tr key={service.id}>
                                        <td>{service.serviceName}</td>
                                        <td>{service.quantity}</td>
                                        <td>{formatCurrency(service.unitPrice)}</td>
                                        <td>{formatCurrency(service.totalPrice)}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        ) : (
                            <p>No services used</p>
                        )}
                    </div>

                    <h5 className={styles['section-title']}>Used Amenities</h5>
                    <div className="row">
                        {bookingData?.bookingAmenities?.length > 0 ? (
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>Amenity Name</th>
                                    <th>Description</th>
                                    <th>Price</th>
                                </tr>
                                </thead>
                                <tbody>
                                {bookingData.bookingAmenities.map((amenity) => (
                                    <tr key={amenity.bookingAmenityId}>
                                        <td>{amenity.amenityName}</td>
                                        <td>{amenity.description}</td>
                                        <td>{formatCurrency(amenity.price)}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        ) : (
                            <p>No amenities used</p>
                        )}
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <p>
                                <strong>Total Room Price:</strong>{' '}
                                {formatCurrency(bookingData?.bookingDetails?.totalRoomPrice)}
                            </p>
                            <p>
                                <strong>Total Service Price:</strong>{' '}
                                {formatCurrency(bookingData?.totalServicePrice)}
                            </p>
                            <p>
                                <strong>Total Amenity Price:</strong>{' '}
                                {formatCurrency(bookingData?.totalAmenityPrice)}
                            </p>
                        </div>
                        <div className="col-md-6">
                            <p>
                                <strong>Discount:</strong>{' '}
                                {bookingData?.bookingDetails?.discount || 0}%
                            </p>
                            <p>
                                <strong>Extra Guest Fee:</strong>{' '}
                                {formatCurrency(bookingData?.bookingDetails?.extraGuestFee)}
                            </p>
                            <p>
                                <strong>Total Amount:</strong>{' '}
                                {formatCurrency(bookingData?.totalAmount)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Invoice;
