import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, Table, Row, Col, Pagination } from 'react-bootstrap';

const AdminRoomAmenityManager = () => {
    const [rooms, setRooms] = useState([]);
    const [amenities, setAmenities] = useState([]);
    const [roomAmenities, setRoomAmenities] = useState({});
    const [selectedRoom, setSelectedRoom] = useState(''); 
    const [selectedAmenities, setSelectedAmenities] = useState([]); 
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const recordsPerPage = 4; // Max records per page

    // Fetch rooms and amenities
    useEffect(() => {
        axios.get('https://spring-api.azurewebsites.net/api/rooms')
            .then(response => setRooms(response.data.data))
            .catch(error => console.error('Error fetching rooms', error));

        axios.get('https://spring-api.azurewebsites.net/api/amenities')
            .then(response => setAmenities(response.data.data))
            .catch(error => console.error('Error fetching amenities', error));

        // Fetch room amenities for all rooms
        fetchRoomAmenitiesForAllRooms();
    }, []);

    const fetchRoomAmenitiesForAllRooms = () => {
        axios.get('https://spring-api.azurewebsites.net/api/roomAmenities')
            .then(response => {
                const roomAmenitiesMap = {};
                response.data.data.forEach(item => {
                    if (!roomAmenitiesMap[item.roomId]) {
                        roomAmenitiesMap[item.roomId] = [];
                    }
                    roomAmenitiesMap[item.roomId].push(item);
                });
                setRoomAmenities(roomAmenitiesMap);
            })
            .catch(error => console.error('Error fetching room amenities', error));
    };

    const handleAmenityChange = (amenityId) => {
        if (selectedAmenities.includes(amenityId)) {
            setSelectedAmenities(selectedAmenities.filter(id => id !== amenityId));
        } else {
            setSelectedAmenities([...selectedAmenities, amenityId]);
        }
    };

    const handleAddOrUpdateAmenities = () => {
        if (!selectedRoom || selectedAmenities.length === 0) {
            alert('Please select a room and at least one amenity.');
            return;
        }

        // Gửi yêu cầu thêm tất cả các tiện ích đã chọn vào phòng
        const requests = selectedAmenities.map(amenityId =>
            axios.post('https://spring-api.azurewebsites.net/api/roomAmenities', {
                roomId: selectedRoom,
                amenityId: amenityId
            })
        );

        Promise.all(requests)
            .then(() => {
                fetchRoomAmenitiesForAllRooms(); // Làm mới danh sách tiện ích phòng sau khi thêm
                setSelectedAmenities([]); // Reset lại tiện ích đã chọn
            })
            .catch(error => console.error('Error adding amenities to room', error));
    };

    const handleDeleteAmenity = (roomId, roomAmenityId) => {
        axios.delete(`https://spring-api.azurewebsites.net/api/roomAmenities/${roomAmenityId}`)
            .then(() => fetchRoomAmenitiesForAllRooms()) // Làm mới bảng sau khi xóa
            .catch(error => console.error('Error deleting amenity from room', error));
    };

    const handleRoomClick = (roomId) => {
        setSelectedRoom(roomId);
        const amenitiesInRoom = roomAmenities[roomId] ? roomAmenities[roomId].map(ra => ra.amenityId) : [];
        setSelectedAmenities(amenitiesInRoom);
    };

    // Tính toán số lượng trang
    const totalPages = Math.ceil(rooms.length / recordsPerPage);

    // Lấy danh sách phòng hiển thị theo trang hiện tại
    const displayedRooms = rooms.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className="container">
            <h1>Manage Room Amenities</h1>

            {/* Chọn phòng và tiện ích */}
            <div className="row">
                <div className="col-md-4">
                    <Form.Group controlId="selectRoom">
                        <Form.Label>Selected Room:</Form.Label>
                        <Form.Control
                            as="select"
                            value={selectedRoom}
                            onChange={e => setSelectedRoom(e.target.value)}
                        >
                            <option value="">-- Select a room --</option>
                            {rooms.map(room => (
                                <option key={room.roomID} value={room.roomID}>
                                    Room {room.roomNumber} - {room.roomView}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </div>

                <div className="col-md-8">
                    <Form.Group controlId="selectAmenities">
                        <Form.Label>Select Amenities</Form.Label>
                        <Row>
                            {amenities.map((amenity, index) => (
                                <Col key={amenity.amenityId} md={4}>
                                    <Form.Check
                                        type="checkbox"
                                        label={`${amenity.amenityName} (${amenity.price} VND)`}
                                        onChange={() => handleAmenityChange(amenity.amenityId)}
                                        checked={selectedAmenities.includes(amenity.amenityId)}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </Form.Group>
                </div>
            </div>

            {/* Nút Add or Update */}
            <Button variant="primary" onClick={handleAddOrUpdateAmenities} style={{ marginTop: '20px' }}>
                {selectedRoom ? 'Update' : 'Add Amenities to Room'}
            </Button>

            {/* Bảng quản lý tiện ích phòng */}
            <h2 className="mt-5">Room Amenities Management</h2>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Room Number</th>
                        <th>Room View</th>
                        <th>Amenities</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {displayedRooms.map(room => (
                        <tr key={room.roomID}>
                            <td>
                                <a href="#!" onClick={() => handleRoomClick(room.roomID)}>
                                    Room {room.roomNumber}
                                </a>
                            </td>
                            <td>{room.roomView}</td>
                            <td>
                                {roomAmenities[room.roomID] && roomAmenities[room.roomID].length > 0 ? (
                                    roomAmenities[room.roomID].map(amenity => {
                                        const amenityDetail = amenities.find(a => a.amenityId === amenity.amenityId);
                                        return (
                                            <span key={amenity.roomAmenityId}>
                                                {amenityDetail?.amenityName} ({amenityDetail?.price} VND)
                                            </span>
                                        );
                                    })
                                ) : (
                                    <span>No amenities</span>
                                )}
                            </td>
                            <td>
                                {roomAmenities[room.roomID] && roomAmenities[room.roomID].length > 0 &&
                                    roomAmenities[room.roomID].map(amenity => (
                                        <Button
                                            key={amenity.roomAmenityId}
                                            variant="danger"
                                            size="sm"
                                            onClick={() => handleDeleteAmenity(room.roomID, amenity.roomAmenityId)}
                                            style={{ marginLeft: '10px' }}
                                        >
                                            Remove {amenities.find(a => a.amenityId === amenity.amenityId)?.amenityName}
                                        </Button>
                                    ))}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Phân trang */}
            <Pagination>
                {Array.from({ length: totalPages }, (_, index) => (
                    <Pagination.Item
                        key={index + 1}
                        active={index + 1 === currentPage}
                        onClick={() => handlePageChange(index + 1)}
                    >
                        {index + 1}
                    </Pagination.Item>
                ))}
            </Pagination>
        </div>
    );
};

export default AdminRoomAmenityManager;
