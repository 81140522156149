import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate
import styles from './EmployeeCSS/BookingSA.module.css';
import { Spinner } from 'react-bootstrap'; // Import Spinner

const BookingSA = () => {
    const location = useLocation();
    const navigate = useNavigate(); // Khởi tạo useNavigate
    const [services, setServices] = useState([]);
    const [amenities, setAmenities] = useState([]);
    const [bookingId, setBookingId] = useState('');
    const [loading, setLoading] = useState(false); // State for loading

    useEffect(() => {
        if (location.state?.bookingId) {
            setBookingId(location.state.bookingId);
        } else {
            Swal.fire('Error', 'Booking ID is missing!', 'error');
        }
        fetchServices();
        fetchAmenities();
    }, [location.state]);

    const fetchServices = async () => {
        setLoading(true); // Set loading to true
        try {
            const response = await axios.get('https://spring-api.azurewebsites.net/api/services');
            setServices(response.data.data.map(service => ({
                id: service.serviceId,
                name: service.serviceName,
                description: service.description,
                price: service.price,
                duration: service.serviceDuration,
                quantity: 1,
                checked: false
            })));
        } catch (error) {
            console.error('Error fetching services:', error);
            toast.error('Failed to fetch services!');
        } finally {
            setLoading(false); // Set loading to false
        }
    };

    const fetchAmenities = async () => {
        setLoading(true); // Set loading to true
        try {
            const response = await axios.get('https://spring-api.azurewebsites.net/api/amenities');
            setAmenities(response.data.data.map(amenity => ({
                id: amenity.amenityId,
                name: amenity.amenityName,
                description: amenity.description,
                free: amenity.isFree ? 'Yes' : 'No',
                price: amenity.price,
                category: amenity.category,
                checked: false
            })));
        } catch (error) {
            console.error('Error fetching amenities:', error);
            Swal.fire('Error', 'Failed to fetch amenities.', 'error');
        } finally {
            setLoading(false); // Set loading to false
        }
    };

    const handleSubmit = async () => {
        if (!bookingId) {
            Swal.fire('Error', 'Booking ID is missing!', 'error');
            return;
        }

        const selectedServices = services
            .filter(service => service.checked)
            .map(service => ({
                bookingId: parseInt(bookingId, 10),
                serviceId: service.id,
                quantity: service.quantity
            }));

        const selectedAmenities = amenities
            .filter(amenity => amenity.checked)
            .map(amenity => ({
                bookingAmenityId: 0,
                bookingId: parseInt(bookingId, 10),
                amenityId: amenity.id
            }));

        try {
            const servicePromise = selectedServices.length > 0
                ? axios.post(
                    'https://spring-api.azurewebsites.net/api/bookingServices/multiple',
                    selectedServices
                )
                : Promise.resolve(); // Nếu không có dịch vụ, trả về Promise.resolve()

            const amenityPromise = selectedAmenities.length > 0
                ? axios.post(
                    'https://spring-api.azurewebsites.net/api/bookingAmenities/multiple',
                    selectedAmenities
                )
                : Promise.resolve(); // Nếu không có tiện ích, trả về Promise.resolve()

            // Chờ cả hai Promise hoàn thành
            await Promise.all([servicePromise, amenityPromise]);

            // Hiển thị thông báo thành công
            Swal.fire({
                title: 'Success',
                text: 'Services and amenities submitted successfully!',
                icon: 'success',
                confirmButtonText: 'OK'
            }).then(() => {
                navigate('/room-list-offline'); // Chuyển hướng sau khi nhấn OK
            });
        } catch (error) {
            console.error('Error submitting data:', error);
            Swal.fire('Error', 'Failed to submit data.', 'error');
        }
    };


    return (
        <div className={`container ${styles.container}`}>
            {loading && ( // Show loading spinner
                <div className="text-center">
                    <Spinner animation="border" />
                </div>
            )}
            <div className="row">
                <div className="col-12 mb-4">
                    <h4>Services</h4>
                    <table className="table table-hover table-striped">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Service Name</th>
                            <th>Description</th>
                            <th>Price</th>
                            <th>Duration (min)</th>
                            <th>Quantity</th>
                            <th>Checked</th>
                        </tr>
                        </thead>
                        <tbody>
                        {services.map((service) => (
                            <tr key={service.id}>
                                <td>{service.id}</td>
                                <td>{service.name}</td>
                                <td>{service.description}</td>
                                <td>{service.price}</td>
                                <td>{service.duration}</td>
                                <td>
                                    <input
                                        type="number"
                                        min="1"
                                        value={service.quantity}
                                        className="form-control"
                                        style={{ width: '50px' }}
                                        onChange={(e) => {
                                            const value = parseInt(e.target.value, 10);
                                            setServices((prev) =>
                                                prev.map((s) =>
                                                    s.id === service.id
                                                        ? { ...s, quantity: value }
                                                        : s
                                                )
                                            );
                                        }}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={service.checked}
                                        onChange={() => {
                                            setServices((prev) =>
                                                prev.map((s) =>
                                                    s.id === service.id
                                                        ? { ...s, checked: !s.checked }
                                                        : s
                                                )
                                            );
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                <div className="col-12">
                    <h4>Amenities</h4>
                    <table className="table table-hover table-striped">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Amenity Name</th>
                            <th>Description</th>
                            <th>Free</th>
                            <th>Price</th>
                            <th>Category</th>
                            <th>Checked</th>
                        </tr>
                        </thead>
                        <tbody>
                        {amenities.map((amenity) => (
                            <tr key={amenity.id}>
                                <td>{amenity.id}</td>
                                <td>{amenity.name}</td>
                                <td>{amenity.description}</td>
                                <td>{amenity.free}</td>
                                <td>{amenity.price}</td>
                                <td>{amenity.category}</td>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={amenity.checked}
                                        onChange={() => {
                                            setAmenities((prev) =>
                                                prev.map((a) =>
                                                    a.id === amenity.id
                                                        ? { ...a, checked: !a.checked }
                                                        : a
                                                )
                                            );
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="mt-3">
                <button className="btn btn-primary" onClick={handleSubmit}>
                    Submit
                </button>
            </div>
        </div>
    );
};

export default BookingSA;
