import React, { useEffect, useState } from 'react';
import { Modal, Button, Table, Pagination } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './EmployeeCSS/Service.module.css';
import axios from 'axios';
import { FaEdit, FaTrash } from 'react-icons/fa';

const Service = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedService, setSelectedService] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [services, setServices] = useState([]);
    const [errors, setErrors] = useState({}); // State for validation errors
    const statuses = ['Available', 'Unavailable'];

    useEffect(() => {
        fetchServices();
    }, [currentPage]);

    const fetchServices = async () => {
        try {
            const response = await axios.get(`https://spring-api.azurewebsites.net/api/services?page=${currentPage}&limit=5`);
            setServices(response.data.data);
            setTotalPages(response.data.totalPages);  // Đảm bảo `totalPages` phản ánh đúng số trang với giới hạn mới
        } catch (error) {
            console.error('Error fetching services:', error);
            toast.error('Failed to fetch services!');
        }
    };

    const handleClose = () => {
        setShowModal(false);
        setSelectedService({});
        setErrors({}); // Reset errors when closing modal
    };

    const handleShow = (service = {}) => {
        setSelectedService({
            serviceId: service.serviceId || '',
            serviceName: service.serviceName || '',
            description: service.description || '',
            price: service.price || 0,
            status: service.status || 'Available',
            serviceDuration: service.serviceDuration || 0,
        });
        setShowModal(true);
    };

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this service?")) {
            try {
                await axios.delete(`https://spring-api.azurewebsites.net/api/services/${id}`);
                fetchServices();
                toast.success('Service deleted successfully!');
            } catch (error) {
                console.error('Error deleting service:', error);
                toast.error('Failed to delete service!');
            }
        }
    };

    const validateForm = (serviceData) => {
        const newErrors = {};
        if (!serviceData.serviceName) {
            newErrors.serviceName = "Service Name is required.";
        }
        if (!serviceData.description) {
            newErrors.description = "Description is required.";
        }
        if (!serviceData.price) {
            newErrors.price = "Price is required.";
        } else if (isNaN(serviceData.price)) { // Kiểm tra nếu giá không phải là số
            newErrors.price = "Price must be a valid number.";
        } else if (serviceData.price <= 0) { // Kiểm tra nếu giá là số âm hoặc bằng 0
            newErrors.price = "Price must be a positive number.";
        }

        if (!serviceData.serviceDuration) {
            newErrors.serviceDuration = "Service Duration is required.";
        } else if (isNaN(serviceData.serviceDuration)) { // Kiểm tra nếu thời gian dịch vụ không phải là số
            newErrors.serviceDuration = "Service Duration must be a valid number.";
        } else if (serviceData.serviceDuration <= 0) { // Kiểm tra nếu thời gian dịch vụ là số âm hoặc bằng 0
            newErrors.serviceDuration = "Service Duration must be a positive number.";
        }
        return newErrors;

    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        const serviceData = {
            serviceName: formData.get('serviceName'),
            description: formData.get('description'),
            price: parseFloat(formData.get('price')),
            status: formData.get('status'),
            serviceDuration: parseInt(formData.get('serviceDuration'), 10),
        };

        // Add serviceId only if updating an existing service
        if (selectedService.serviceId) {
            serviceData.serviceId = selectedService.serviceId;
        }

        // Validate form data
        const formErrors = validateForm(serviceData);
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return; // Prevent submission if there are errors
        }

        try {
            if (selectedService.serviceId) {
                await axios.put(`https://spring-api.azurewebsites.net/api/services/${serviceData.serviceId}`, serviceData);
                toast.success('Service updated successfully!');
            } else {
                await axios.post('https://spring-api.azurewebsites.net/api/services', serviceData);
                toast.success('Service added successfully!');
            }
            fetchServices();
            handleClose();
        } catch (error) {
            console.error('Error saving service:', error.response ? error.response.data : error.message);
            toast.error('Failed to save service!');
        }
    };

    const handlePaginationClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className={styles.container}>
            <ToastContainer />
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div style={{ marginTop: 13 }} className="input-group w-50">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search service..."
                    />
                    <Button variant="primary">Search</Button>
                </div>
                <Button className={styles.addServiceButton} onClick={() => handleShow()}>
                    + Add New Service
                </Button>
            </div>

            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>No.</th>
                    <th>Service Name</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th>Price</th>
                    <th>Duration (min)</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {services.length === 0 ? (
                    <tr>
                        <td colSpan="7" className="text-center">No services found.</td>
                    </tr>
                ) : (
                    services.map((service) => (
                        <tr key={service.serviceId}>
                            <td>{service.serviceId}</td>
                            <td>{service.serviceName}</td>
                            <td>{service.description}</td>
                            <td>{service.status}</td>
                            <td>{service.price}</td>
                            <td>{service.serviceDuration}</td>
                            <td>
                                <Button variant="success" onClick={() => handleShow(service)} className={styles.iconButton}>
                                    <FaEdit />
                                </Button>
                                <Button variant="danger" onClick={() => handleDelete(service.serviceId)} className={styles.iconButton}>
                                    <FaTrash />
                                </Button>
                            </td>
                        </tr>
                    ))
                )}
                </tbody>
            </Table>

            <div className="d-flex justify-content-end">
                <Pagination>
                    <Pagination.First onClick={() => setCurrentPage(1)} />
                    <Pagination.Prev onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)} />
                    {[...Array(totalPages)].map((_, index) => (
                        <Pagination.Item
                            key={index + 1}
                            active={index + 1 === currentPage}
                            onClick={() => handlePaginationClick(index + 1)}
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={() => currentPage < totalPages && setCurrentPage(currentPage + 1)} />
                    <Pagination.Last onClick={() => setCurrentPage(totalPages)} />
                </Pagination>
            </div>

            <Modal size="lg" show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedService.serviceId ? 'Edit Service' : 'Add Service'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className={styles.userForm} onSubmit={handleSubmit}>
                        {selectedService.serviceId && (
                            <div className="mb-3">
                                <label htmlFor="id" className="form-label">ID:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="serviceId"
                                    name="serviceId"
                                    value={selectedService.serviceId}
                                    readOnly
                                />
                            </div>
                        )}
                        <div className="mb-3">
                            <label htmlFor="serviceName" className="form-label">Service Name:</label>
                            <input
                                type="text"
                                className={`form-control ${errors.serviceName ? 'is-invalid' : ''}`}
                                id="serviceName"
                                name="serviceName"
                                value={selectedService.serviceName}
                                onChange={(e) => setSelectedService({ ...selectedService, serviceName: e.target.value })}
                            />
                            {errors.serviceName && <div className="invalid-feedback">{errors.serviceName}</div>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="description" className="form-label">Description:</label>
                            <input
                                type="text"
                                className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                                id="description"
                                name="description"
                                value={selectedService.description}
                                onChange={(e) => setSelectedService({ ...selectedService, description: e.target.value })}
                            />
                            {errors.description && <div className="invalid-feedback">{errors.description}</div>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="price" className="form-label">Price:</label>
                            <input
                                type="number"
                                className={`form-control ${errors.price ? 'is-invalid' : ''}`}
                                id="price"
                                name="price"
                                value={selectedService.price}
                                onChange={(e) => setSelectedService({ ...selectedService, price: parseFloat(e.target.value) })}
                            />
                            {errors.price && <div className="invalid-feedback">{errors.price}</div>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="status" className="form-label">Status:</label>
                            <div className="d-flex">
                                {statuses.map((status, index) => (
                                    <div key={index} className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="status"
                                            id={`status-${index}`}
                                            value={status}
                                            checked={selectedService?.status === status}
                                            onChange={(e) => setSelectedService({ ...selectedService, status: e.target.value })}
                                        />
                                        <label className="form-check-label" htmlFor={`status-${index}`}>
                                            {status}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="serviceDuration" className="form-label">Service Duration (minutes):</label>
                            <input
                                type="number"
                                className={`form-control ${errors.serviceDuration ? 'is-invalid' : ''}`}
                                id="serviceDuration"
                                name="serviceDuration"
                                value={selectedService.serviceDuration}
                                onChange={(e) => setSelectedService({ ...selectedService, serviceDuration: parseInt(e.target.value, 10) })}
                            />
                            {errors.serviceDuration && <div className="invalid-feedback">{errors.serviceDuration}</div>}
                        </div>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>Close</Button>
                            <Button variant="primary" type="submit">Save</Button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Service;
