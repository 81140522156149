import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import {
  UserOutlined,
  SettingOutlined,
  HomeOutlined,
  UsergroupAddOutlined,
  AppstoreOutlined,
  ToolOutlined,
  CreditCardOutlined,
} from '@ant-design/icons';

const Sidebar = () => (
    <div style={{ minHeight: '100vh', backgroundColor: '#001529', color: '#fff' }}>
      <div style={{ padding: '16px', textAlign: 'center' }}>
        <img src="/hotelw.png" alt="Hotel Logo" style={{ width: '100%', maxWidth: '150px', height: 'auto' }} />
      </div>

      <Menu
          mode="inline"
          theme="dark"
          style={{ height: 'auto' }}
          items={[
            {
              key: 'dashboard',
              icon: <HomeOutlined />,
              label: <Link to="/">Dashboard</Link>, // Trùng với 'home' path trong Admin
            },
            {
              key: 'customer',
              icon: <UserOutlined />,
              label: 'Customer',
              children: [
                {
                  key: 'customer-list',
                  label: <Link to="/customer-list">Customer List</Link>,
                },
                {
                  key: 'guest-list',
                  label: <Link to="/guest-list">Guest List</Link>,
                },
              ],
            },
            {
              key: 'human-resource',
              icon: <UsergroupAddOutlined />,
              label: 'Human Resource',
              children: [
                {
                  key: 'add-employee',
                  label: <Link to="/add-employee">Add Employee</Link>,
                },
                {
                  key: 'manage-employee',
                  label: <Link to="/manage-employee">Manage Employee</Link>,
                },
              ],
            },
            {
              key: 'amenity',
              icon: <AppstoreOutlined />,
              label: 'Amenity',
              children: [
                {
                  key: 'add-amenity',
                  label: <Link to="/add-amenity">Add Amenity</Link>,
                },
                {
                  key: 'manage-amenity',
                  label: <Link to="/manage-amenity">Amenity Manage</Link>,
                },
                {
                  key: 'manage-room-amenity',
                  label: <Link to="/manage-room-amenity">Manage Room Amenities</Link>,
                },
              ],
            },
            {
              key: 'room-setting',
              icon: <SettingOutlined />,
              label: 'Room Setting',
              children: [
                {
                  key: 'room-status',
                  label: 'Room Status',
                  children: [
                    {
                      key: 'add-room-status',
                      label: <Link to="/add-room-status">Add Room Status</Link>,
                    },
                  ],
                },
                {
                  key: 'room-type',
                  label: 'Room Type',
                  children: [
                    {
                      key: 'add-room-type',
                      label: <Link to="/add-room-type">Add Room Type</Link>,
                    },
                    {
                      key: 'room-type-manage',
                      label: <Link to="/room-type-manage">Room Type Manage</Link>,
                    },
                  ],
                },
                {
                  key: 'room-image',
                  label: <Link to="/room-image">Room Image</Link>,
                },
                {
                  key: 'room',
                  label: 'Room',
                  children: [
                    {
                      key: 'add-room',
                      label: <Link to="/add-room">Add Room</Link>,
                    },
                    {
                      key: 'manage-room',
                      label: <Link to="/manage-room">Room Manage</Link>,
                    },
                  ],
                },
              ],
            },
            {
              key: 'service-setting',
              icon: <ToolOutlined />,
              label: 'Service Setting',
              children: [
                {
                  key: 'add-service',
                  label: <Link to="/add-service">Add Service</Link>,
                },
                {
                  key: 'service-manage',
                  label: <Link to="/service-manage">Service Manage</Link>,
                },
              ],
            },
            {
              key: 'payment-setting',
              icon: <CreditCardOutlined />,
              label: <Link to="/payment-setting">Payment Setting</Link>,
            },
            {
              key: 'report',
              label: <Link to="/report">Report</Link>,
            },
            {
              key: 'setting',
              icon: <SettingOutlined />,
              label: <Link to="/setting">Setting</Link>,
            },
          ]}
      />
    </div>
);

export default Sidebar;
