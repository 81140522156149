import React from 'react';
import styles from './CustomerComponentCSS/special_offers.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function Special() {
  return (
    <div className="container">
      <div className={`${styles['header-text']} text-center py-4`}>
        <h2>Uncover special offers created for your enjoyment & plan your getaway!</h2>
      </div>

      <div className={styles['image-section']}>
        <div className={`row ${styles['row']}`}>
          <div className="col-md-6">
            <img src="serv3.jpg" alt="Cloud Afternoon Tea" className={`img-fluid ${styles['img-fluid']}`} />
            <div className={`${styles['offer-details']} text-left`}>
              <h5>CLOUD AFTERNOON TEA</h5>
              <p className={styles['price']}>PRICE: 1,888,000 VND</p>
              <p>Thưởng thức trà chiều sang trọng tại phòng trà.</p>
            </div>
          </div>
          <div className="col-md-6">
            <img src="serv4.jpg" alt="Cloud Afternoon Tea" className={`img-fluid ${styles['img-fluid']}`} />
            <div className={`${styles['offer-details']} text-left`}>
              <h5>CLOUD AFTERNOON TEA</h5>
              <p className={styles['price']}>PRICE: 1,888,000 VND</p>
              <p>Thưởng thức trà chiều sang trọng tại phòng trà.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Special;
