import React, { useState, useEffect } from 'react';
import axios from 'axios';
// Import module CSS
import styles from './AdminCSS/Registration.module.css';

const ChangePassUI = () => {
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [userId, setUserId] = useState(null); // To store userId from localStorage

    // Fetch userId from localStorage
    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            setUserId(storedUserId); // Set the userId
        }
    }, []);

    const validateForm = () => {
        const newErrors = {};

        if (!password.trim()) {
            newErrors.password = "Old password is required.";
        } else if (password.length < 6) {
            newErrors.password = "Old password must be at least 8 characters long.";
        } else if (password.length > 30) {
            newErrors.password = "Old password must not exceed 30 characters.";
        }

        if (!newPassword.trim()) {
            newErrors.newPassword = "New password is required.";
        } else if (newPassword.length < 8) {
            newErrors.newPassword = "New password must be at least 8 characters long.";
        } else if (newPassword.length > 30) {
            newErrors.newPassword = "New password must not exceed 30 characters.";
        }

        if (!confirmPassword.trim()) {
            newErrors.confirmPassword = "Confirm password is required.";
        } else if (confirmPassword !== newPassword) {
            newErrors.confirmPassword = "Passwords do not match.";
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validate form data
        if (validateForm()) {
            try {
                const response = await axios.post(
                    'https://spring-api.azurewebsites.net/api/users/change-password',
                    {
                        userId: userId, // Pass the userId from localStorage
                        oldPassword: password,
                        newPassword: newPassword,
                        confirmNewPassword: confirmPassword,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                );

                if (response.status === 200) {
                    alert('Password successfully updated!');
                } else {
                    alert('Failed to change password!');
                }
            } catch (error) {
                alert('Password change failed: ' + (error.response?.data?.message || error.message));
            }
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <div className={styles.appContainer}>
            <div className={styles.passwordCard}>
                <h3>Change Password</h3>
                <form onSubmit={handleSubmit}>
                    <div className={`${styles.formGroup} position-relative`}>
                        <label htmlFor="password">Old Password</label>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            className={styles.formControl}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <i
                            className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} ${styles['toggle-password']}`}
                            onClick={togglePasswordVisibility}
                            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '40%' }}
                            title={showPassword ? 'Hide password' : 'Show password'}
                        ></i>
                        {errors.password && <small className={styles.textDanger}>{errors.password}</small>}
                    </div>

                    <div className={`${styles.formGroup} position-relative`}>
                        <label htmlFor="newPassword">New Password</label>
                        <input
                            type={showNewPassword ? 'text' : 'password'}
                            id="newPassword"
                            className={styles.formControl}
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <i
                            className={`fas ${showNewPassword ? 'fa-eye-slash' : 'fa-eye'} ${styles['toggle-password']}`}
                            onClick={toggleNewPasswordVisibility}
                            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '40%' }}
                            title={showNewPassword ? 'Hide password' : 'Show password'}
                        ></i>
                        {errors.newPassword && <small className={styles.textDanger}>{errors.newPassword}</small>}
                    </div>

                    <div className={`${styles.formGroup} position-relative`}>
                        <label htmlFor="confirmPassword">Confirm Password</label>
                        <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            id="confirmPassword"
                            className={styles.formControl}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <i
                            className={`fas ${showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'} ${styles['toggle-password']}`}
                            onClick={toggleConfirmPasswordVisibility}
                            style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '40%' }}
                            title={showConfirmPassword ? 'Hide password' : 'Show password'}
                        ></i>
                        {errors.confirmPassword && <small className={styles.textDanger}>{errors.confirmPassword}</small>}
                    </div>

                    <button
                        style={{ backgroundColor: '#b29a89', color: 'white' }}
                        type="submit"
                        className={styles.btnLogin}
                    >
                        Change Password
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ChangePassUI;
