import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import RoleBasedHome from './RoleBasedHome';
import Unauthorized from './Unauthorized';
import Login from './CustomerComponent/LoginComponents';
import NewPassword from "./CustomerComponent/NewPassword";
import SignUp from "./CustomerComponent/SignUp";

const App = () => (
    <Router>
        <Routes>
            {/* Trang đăng nhập */}
            <Route path="/login" element={<Login />} />

            {/* Trang không có quyền truy cập */}
            <Route path="/unauthorized" element={<Unauthorized />} />

            {/* Trang khôi phục mật khẩu */}
            <Route path="/newpassword" element={<NewPassword />} />

            {/* Trang đăng ký */}
            <Route path="/signup" element={<SignUp />} />



            {/* Các route chung dựa trên vai trò */}
            <Route
                path="/*"
                element={
                    <ProtectedRoute allowedRoles={['admin', 'employee', 'customer']}>
                        <RoleBasedHome />
                    </ProtectedRoute>
                }
            />

            {/* 404 Not Found */}
            <Route path="*" element={<div>404 - Page Not Found</div>} />
        </Routes>
    </Router>
);

export default App;
