import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Unauthorized = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Xóa token và thông tin liên quan
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        sessionStorage.clear();

        // Điều hướng về trang đăng nhập
        navigate('/login', { replace: true });

        // Làm mới giao diện nếu cần
        window.location.reload();
    };


    return (
        <div>
            <h1>403 - Unauthorized</h1>
            <p>You do not have permission to access this page.</p>
            <Link to="/">Go to Homepage</Link>
            <br />
            <button onClick={handleLogout}>Logout</button>
        </div>
    );
};

export default Unauthorized;
