import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, InputNumber, Select, message } from 'antd';
import { EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Option } = Select;

const RoomManage = () => {
  const [rooms, setRooms] = useState([]);
  const [filteredRooms, setFilteredRooms] = useState([]); 
  const [roomTypes, setRoomTypes] = useState([]);
  const [roomStatuses, setRoomStatuses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingRoom, setEditingRoom] = useState(null);
  const [form] = Form.useForm();
  const [pageSize, setPageSize] = useState(5); 
  const [currentPage, setCurrentPage] = useState(1); 

  // Lấy dữ liệu từ API
  const fetchRooms = async () => {
    setLoading(true);
    try {
      const [roomResponse, roomTypeResponse, roomStatusResponse] = await Promise.all([
        axios.get('https://spring-api.azurewebsites.net/api/rooms'),
        axios.get('https://spring-api.azurewebsites.net/api/roomType'),
        axios.get('https://spring-api.azurewebsites.net/api/roomStatus'),
      ]);

      setRooms(roomResponse.data.data);
      setFilteredRooms(roomResponse.data.data); 
      setRoomTypes(roomTypeResponse.data.data);
      setRoomStatuses(roomStatusResponse.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('Failed to fetch data.');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRooms();
  }, []);

  // Lấy tên Room Type theo ID
  const getRoomTypeName = (roomTypeID) => {
    const roomType = roomTypes.find((type) => type.roomTypeId === roomTypeID);
    return roomType ? roomType.typeName : roomTypeID;
  };

  // Lấy tên Room Status theo ID
  const getRoomStatusName = (roomStatusID) => {
    const roomStatus = roomStatuses.find((status) => status.roomStatusId === roomStatusID);
    return roomStatus ? roomStatus.statusName : roomStatusID;
  };

  // Lọc danh sách phòng dựa trên từ khóa tìm kiếm
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();

    const filtered = rooms.filter((room) => {
      const roomTypeName = getRoomTypeName(room.roomTypeID).toLowerCase();
      const roomStatusName = getRoomStatusName(room.roomStatusID).toLowerCase();

      return (
        room.roomNumber.toString().includes(value) || 
        roomTypeName.includes(value) || 
        roomStatusName.includes(value) || 
        room.floor.toString().includes(value) || 
        room.price.toString().includes(value)
      );
    });
    setFilteredRooms(filtered);
  };

  // Mở modal để chỉnh sửa phòng
  const handleEdit = (record) => {
    setEditingRoom(record);
    form.setFieldsValue({
      roomNumber: record.roomNumber,
      roomTypeID: record.roomTypeID,
      roomStatusID: record.roomStatusID,
      price: record.price,
      capacity: record.capacity,
      floor: record.floor,
      roomView: record.roomView,
      description: record.description,
    });
    setIsModalVisible(true);
  };

  // Xử lý lưu thông tin cập nhật phòng
  const handleSave = async (values) => {
    try {
      const updatedRoom = { ...editingRoom, ...values };
      await axios.put(`https://spring-api.azurewebsites.net/api/rooms/${editingRoom.roomID}`, updatedRoom); 
      message.success('Room updated successfully!');
      fetchRooms(); 
      setIsModalVisible(false);
    } catch (error) {
      console.error('Failed to update room:', error);
      message.error('Failed to update room.');
    }
  };

  const handleDelete = async (roomID) => {
    try {
      await axios.delete(`https://spring-api.azurewebsites.net/api/rooms/${roomID}`);
      message.success('Room deleted successfully!');
      fetchRooms(); 
    } catch (error) {
      console.error('Failed to delete room:', error);
      message.error('Failed to delete room.');
    }
  };

  // Cấu hình cột cho bảng
  const columns = [
    {
      title: 'No.',
      key: 'index',
      render: (text, record, index) => index + 1 + (currentPage - 1) * pageSize, 
    },
    {
      title: 'Room ID',
      dataIndex: 'roomID',
      key: 'roomID',
    },
    {
      title: 'Room Type',
      dataIndex: 'roomTypeID',
      key: 'roomTypeID',
      render: (roomTypeID) => getRoomTypeName(roomTypeID), 
      sorter: (a, b) => getRoomTypeName(a.roomTypeID).localeCompare(getRoomTypeName(b.roomTypeID)),
    },
    {
      title: 'Room Status',
      dataIndex: 'roomStatusID',
      key: 'roomStatusID',
      render: (roomStatusID) => getRoomStatusName(roomStatusID), 
    },
    {
      title: 'Room Number',
      dataIndex: 'roomNumber',
      key: 'roomNumber',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price) => `$${price.toFixed(2)}`,
      sorter: (a, b) => a.price - b.price, 
    },
    {
      title: 'Capacity',
      dataIndex: 'capacity',
      key: 'capacity',
    },
    {
      title: 'Floor',
      dataIndex: 'floor',
      key: 'floor',
    },
    {
      title: 'Room View',
      dataIndex: 'roomView',
      key: 'roomView',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <>
          <Button type="link" icon={<EditOutlined />} onClick={() => handleEdit(record)} />
          <Button type="link" icon={<DeleteOutlined />} onClick={() => handleDelete(record.roomID)} />
        </>
      ),
    },
  ];

  return (
    <div>
      <h1>Manage Rooms</h1>

      {/* Thanh tìm kiếm */}
      <Input
        placeholder="Search by room number, type, status, floor, price"
        prefix={<SearchOutlined />}
        onChange={handleSearch}
        style={{ marginBottom: '20px', width: '300px' }}
      />

      {filteredRooms.length === 0 ? (
        <p>No rooms found.</p>
      ) : (
        <Table
          columns={columns}
          dataSource={filteredRooms}
          rowKey="roomID"
          loading={loading}
          pagination={{
            pageSize: pageSize,
            current: currentPage,
            total: filteredRooms.length,
            onChange: (page) => setCurrentPage(page),
          }}
        />
      )}

      {/* Modal Form để cập nhật dữ liệu */}
      <Modal
        title="Edit Room"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => form.submit()} 
      >
        <Form form={form} layout="vertical" onFinish={handleSave}>
          <Form.Item label="Room Number" name="roomNumber" rules={[{ required: true, message: 'Please input room number!' }]}>
            <InputNumber min={1} />
          </Form.Item>

          <Form.Item label="Room Type" name="roomTypeID" rules={[{ required: true, message: 'Please select room type!' }]}>
            <Select placeholder="Select room type">
              {roomTypes.map((roomType) => (
                <Option key={roomType.roomTypeId} value={roomType.roomTypeId}>
                  {roomType.typeName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Room Status" name="roomStatusID" rules={[{ required: true, message: 'Please select room status!' }]}>
            <Select placeholder="Select room status">
              {roomStatuses.map((roomStatus) => (
                <Option key={roomStatus.roomStatusId} value={roomStatus.roomStatusId}>
                  {roomStatus.statusName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Price" name="price" rules={[{ required: true, message: 'Please input room price!' }]}>
            <InputNumber min={0} step={0.01} />
          </Form.Item>

          <Form.Item label="Capacity" name="capacity" rules={[{ required: true, message: 'Please input room capacity!' }]}>
            <InputNumber min={1} />
          </Form.Item>

          <Form.Item label="Floor" name="floor" rules={[{ required: true, message: 'Please input floor number!' }]}>
            <InputNumber min={1} />
          </Form.Item>

          <Form.Item label="Room View" name="roomView" rules={[{ required: true, message: 'Please input room view!' }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Please input description!' }]}>
            <Input.TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default RoomManage;
