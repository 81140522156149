import React, {useEffect, useState} from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import icons mắt
import styles from './EmployeeCSS/MyProfile.module.css';
import {Link} from "react-router-dom";


const MyProfileStaff = () => {
    const [showModal, setShowModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [customerData, setCustomerData] = useState({});
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch('https://spring-api.azurewebsites.net/api/customers/myProfile', {
                    method: 'GET',
                    headers: {
                        'Authorization': token ? `Bearer ${token}` : '',
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }

                const data = await response.json();

                if (data && data.data) {
                    setCustomerData({
                        userId: data.data.userId || '',
                        firstName: data.data.firstName || '',
                        lastName: data.data.lastName || '',
                        address: data.data.address || '',
                        email: data.data.email || '',
                        phone: data.data.phone || '',
                        password: data.data.password || '',
                    });
                } else {
                    throw new Error('Invalid response data');
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
    }, [token]);

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setCustomerData({...customerData, [name]: value});
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const validatePhoneNumber = (phone) => {
        const phonePattern = /^\d{10}$/;
        return phonePattern.test(phone);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!customerData.firstName || !customerData.lastName || !customerData.email || !customerData.phone || !customerData.password) {
                alert('Please fill in all fields.');
                return;
            }

            if (!validateEmail(customerData.email)) {
                alert('Please enter a valid email address.');
                return;
            }

            if (!validatePhoneNumber(customerData.phone)) {
                alert('Please enter a valid phone number.');
                return;
            }

            const response = await fetch(`https://spring-api.azurewebsites.net/api/customers/${customerData.userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token ? `Bearer ${token}` : '',
                },
                body: JSON.stringify({
                    userId: customerData.userId,
                    firstName: customerData.firstName,
                    lastName: customerData.lastName,
                    address: customerData.address,
                    email: customerData.email,
                    phone: customerData.phone,
                    password: customerData.password
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update user information');
            }

            const data = await response.json();
            if (data.code === 200) {
                alert('Customer updated successfully');
                setCustomerData(data.data);
            } else {
                throw new Error(data.message || 'Failed to update user information');
            }
            handleCloseModal();
        } catch (error) {
            alert(error.message);
        }
    };

    return (
        <div className={styles.container}>
            <div className="logo">
                <Link to="/" className="navbar-brand">
                    <img className={styles.logoImg} src="/logoo.png" alt="Logo"/>
                </Link>
            </div>
            <h2 className={styles.profileTitle}>MyProfile</h2>
            <Form className={styles.profileForm}>
                <div className="row mb-3"></div>

                <div className="row mb-3">
                    <div className="col-md-6">
                        <Form.Group controlId="firstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={customerData.firstName}
                                onChange={handleChange}
                                name="firstName"
                                readOnly
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group controlId="lastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={customerData.lastName}
                                onChange={handleChange}
                                name="lastName"
                                readOnly
                            />
                        </Form.Group>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-6">
                        <Form.Group controlId="fullName">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={`${customerData.firstName} ${customerData.lastName}`}
                                readOnly
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group controlId="address">
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type="text"
                                value={customerData.address}
                                onChange={handleChange}
                                name="address"
                                readOnly
                            />
                        </Form.Group>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-6">
                        <Form.Group controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                value={customerData.email}
                                onChange={handleChange}
                                name="email"
                                readOnly
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group controlId="phoneNumber">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                type="tel"
                                value={customerData.phone}
                                onChange={handleChange}
                                name="phone"
                                readOnly
                            />
                        </Form.Group>
                    </div>
                </div>

                <Button variant="primary" onClick={handleShowModal}>
                    Update
                </Button>
            </Form>

            <Modal
                style={{fontFamily: "'Josefin Sans', sans-serif"}}
                show={showModal}
                onHide={handleCloseModal}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <Form.Group controlId="modalFirstName" className="mb-3">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={customerData.firstName}
                                        onChange={handleChange}
                                        name="firstName"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group controlId="modalLastName" className="mb-3">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={customerData.lastName}
                                        onChange={handleChange}
                                        name="lastName"
                                    />
                                </Form.Group>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-6">
                                <Form.Group controlId="modalAddress" className="mb-3">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={customerData.address}
                                        onChange={handleChange}
                                        name="address"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group controlId="modalEmail" className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        value={customerData.email}
                                        onChange={handleChange}
                                        name="email"
                                    />
                                </Form.Group>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-6">
                                <Form.Group controlId="modalPhone" className="mb-3">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        value={customerData.phone}
                                        onChange={handleChange}
                                        name="phone"
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group controlId="modalPassword" className="mb-3">
                                    <Form.Label>Password</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type={showPassword ? 'text' : 'password'}
                                            value={customerData.password}
                                            onChange={handleChange}
                                            name="password"
                                        />
                                        <Button
                                            variant="outline-secondary"
                                            onClick={toggleShowPassword}
                                        >
                                            {showPassword ? <FaEyeSlash/> : <FaEye/>}
                                        </Button>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>

                        <Button variant="primary" type="submit">
                            Save Changes
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};
export default MyProfileStaff;
