import React from "react";
import { useTranslation } from "react-i18next";  // Import useTranslation hook
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./CustomerComponentCSS/Gallery.module.css";

const HotelGallery = () => {
    const { t, i18n } = useTranslation();

    // Đưa dữ liệu ảnh vào mảng mà không dịch ở đây
    const images = [
        { src: "1.jpg", altKey: 'gallerys.roomView', captionKey: 'gallerys.roomView' },
        { src: "2.jpeg", altKey: 'gallerys.swimmingPool', captionKey: 'gallerys.swimmingPool' },
        { src: "3.webp", altKey: 'gallerys.restaurant', captionKey: 'gallerys.restaurant' },
        { src: "4.jfif", altKey: 'gallerys.spa', captionKey: 'gallerys.spa' },
        { src: "5.jpg", altKey: 'gallerys.lobby', captionKey: 'gallerys.lobby' },
        { src: "6.avif", altKey: 'gallerys.gym', captionKey: 'gallerys.gym' },
        { src: "7.jpg", altKey: 'gallerys.scenicView', captionKey: 'gallerys.scenicView' },
        { src: "8.jpg", altKey: 'gallerys.suiteRoom', captionKey: 'gallerys.suiteRoom' },
        { src: "9.webp", altKey: 'gallerys.eventHall', captionKey: 'gallerys.eventHall' },
    ];

    return (
        <div className="container">
            {/* Title */}
            <h1 className={styles.galleryTitle}>{t('gallerys.title')}</h1>

            {/* Image Gallery */}
             <div className={`row ${styles.gallery}`}>
                {images.map((image, index) => (
                    <div key={index} className={`col-md-4 mb-4 ${styles.galleryItem}`}>
                        {/* Dùng t() để dịch alt và caption */}
                        <img src={image.src} className="img-fluid" alt={t(image.altKey)} />
                        <div className={styles.overlay}>{t(image.captionKey)}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HotelGallery;
